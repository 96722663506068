import {
  ClientAccounts,
  ClientAccountsAccountGroup,
  ClientAccountsSubAccounts
} from '@app/shared/models/client-accounts.model';
import { CurrentClientAccountState } from '@app/_state/accounts.state';

export namespace AccountsActions {
  export class Fetch {
    static readonly type = '[Accounts] Fetch Client Accounts Data';
    constructor() {}
  }

  export class SetClientAccountsData {
    static readonly type = '[Accounts] Set Client Accounts Data';
    constructor(public data: ClientAccounts) {}
  }

  export class SetCurrentClientAccountState {
    static readonly type = '[Accounts] Set Current Account State';
    constructor(public selected: Partial<CurrentClientAccountState>) {}
  }

  export class ImpInit {
    static readonly type = '[Accounts] Do Imp. init logic';
    constructor() {}
  }

  export class SetAccountIDsAfterFilter {
    static readonly type = '[Accounts] Set Account IDs After Filter';
    constructor(public accountIDsAfterFilter: string[]) {}
  }
}
