import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { LoggerService } from '@app/logger.service';
import { UserAgentState, UserAgentStateModel } from '@app/_state/userAgent.state';
import { Environment } from 'env/ienvironment';
import { ENV } from 'env/environment.provider';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Notifications } from './_actions/notification.actions';
import { CODES } from './_state/notifications.config';

export interface NotificationDTO {
  id: number;
  title: string;
  message: string;
  datetime: string;
  status: string;
  services: string[];
}

@Injectable()
export class NotificationTriggersService {
  @Select(UserAgentState) userAgentState$: Observable<UserAgentStateModel>;

  constructor(
    private store: Store,
    private logger: LoggerService,
    private http: HttpClient,
    @Inject(ENV) private env: Environment) {

    //add debug method to window if not in prod
    if (!this.env.production) {
      window['_debug_forceshow'] = this._debug_forceshow.bind(this);
    }
  }

  fetchEmergencyNotifications(): void {
    this.http
      .get<NotificationDTO[]>(`${this.env.pcfUrl}/notifications`)
      .pipe(
        tap((notes) => {
          this.store.dispatch(
            new Notifications.AddWithOverrides(
              notes.map((note) => `${CODES.PUSH_WARNING_EMERGENCY}_${note.id}`),
              notes.map((note) => {
                return {
                  body: [note.message],
                  title: note.title,
                  serviceTargets: note.services,
                  id: `${CODES.PUSH_WARNING_EMERGENCY}_${note.id}`
                };
              })
            )
          );
        })
      )
      .subscribe();
  }

  private _debug_forceshow(notificationCode: string) {
    this.logger.info(`[DEBUG NotificationTriggersService]  _debug_forceshow code ${notificationCode}`);
    this.store.dispatch(new Notifications.Add([notificationCode]));
  }
}
