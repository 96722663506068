<ng-container *ngIf="message">
  <div (click)="onNotificationClick()"
    class="notification notification--{{ message.classification }} u-border-radius--small">
    <div class="notification__icon">
      <img *ngIf="message?.icon !== ''"
        [src]="message.icon === undefined ? 'assets/images/icons/' + message.classification + '-notification.svg' : message.icon"
        loading="lazy" [alt]="message.classification" width="24" height="24" />
      <span *ngIf="message.id === 'E-001001-0'" class="c-icon--medium">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
          <title>Alert Error</title>
          <path fill="#DD012C" fill-rule="nonzero"
            d="M12.982 15.374a.991.991 0 0 1-.984.984.996.996 0 0 1-.985-.984c0-.539.454-.985.985-.985.538 0 .984.446.984.985zm-1.546-8.437h1.123l-.138 6.191h-.846l-.139-6.19zm7.314 10.69l.93-1.215-6.883-12.29h-1.545l-6.914 12.29.915 1.215H18.75zM13.405 3l7.598 13.496-1.692 2.254H4.684L3 16.496 10.59 3h2.815z" />
        </svg>
      </span>
    </div>
    <div class="notification__content">
      <ng-container *ngIf="message.title">
        <div class="notification__header">
          <button class="notification__expand-button" attr.aria-expanded="{{openNotificationDetails}}" aria-label="Notification Details" appKeyboardA11y (click)="onNotificationClick()">
            <div *ngIf="message.title" class="notification__title">
              <strong id="{{message.id}}-NotificationTitle"
                [ngClass]="{ 'overflow-clip': !openNotificationDetails && message.body?.length > 0 }">{{ message.title
              }}</strong>
            <img [ngClass]="{'startChevron' : openNotificationDetails}" id="{{message.id}}-title-arrow" class="notification__body-arrow"
                  src="assets/images/icons/dropdown-arrow_2.svg" alt="Details" loading="lazy" />
            </div>
          </button>
        </div>
      </ng-container>
      <div class="notification__body">
        <div class="notification__body-install"
          *ngIf="osName === 'iOS' && message.id === 'I-001003-2'; else genericNotificationMessage">
          <p class="notification__body-text u-type--primary-dark"
            [ngStyle]="{ display: openNotificationDetails ? 'block' : 'none' }">
            Tap the <img src="assets/images/icons/share.svg" loading="lazy" alt="share" width="24" height="24" /> icon
            then select "Add to Home Screen"
          </p>
          <ng-container *ngIf="message.action.action">
            <div class="notification__action-button"
              [ngStyle]="{ display: openNotificationDetails ? 'block' : 'none' }">
              <button (click)="onActionClick()" class="c-button c-button--primary" [title]="message.action.title">
                <span class="c-button-focus">{{ message.action.title }}</span>
              </button><img [ngClass]="{'startChevron' : openNotificationDetails}" id="{{message.id}}-body-arrow"
                class="notification__body-arrow" src="assets/images/icons/dropdown-arrow_2.svg" alt="Details"
                loading="lazy" />
            </div>
          </ng-container>
        </div>
        <ng-template #genericNotificationMessage>
          <div *ngIf="message.body?.length > 0" id="{{message.id}}-NotificationBody" class="notification__body-content">
            <p *ngFor="let paragraph of message.body"
              [ngStyle]="{ display: openNotificationDetails ? 'block' : 'none' }" id="{{message.id}}-NotificationBodyText"
              class="notification__body-text u-type--primary-dark">
              {{ paragraph }}
            </p>
          </div>
        </ng-template>
      </div>
    </div>
    <div class="notification__buttons">
      <div class="notification__close">
        <button type="button" (click)="closeNotificationMessage(message.id)" aria-label="close"
          class="notification__close-button">
          <svg class="c-icon--medium" role="img" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"
            aria-label="Close">
            <title>Close</title>
            <path
              d="M20 4.86L12.86 12 20 19.14l-.86.86L12 12.86 4.86 20 4 19.14 11.14 12 4 4.86 4.86 4 12 11.14 19.14 4z"
              fill="#425968"></path>
          </svg>
        </button>
      </div>
      <div *ngIf="message.action" class="notification__actions">
        <ng-container *ngIf="message.action.url && !message.action.action">
          <a [href]="message.action.url" class="notification__action-link" rel="noopener noreferrer"
            [attr.target]="message.action.isExternal ? '_blank' : null"
            [attr.aria-label]="message.action?.label ? null : message.action.label" [title]="message.action.title">
            {{ message.action?.label }}
            <ng-container *ngIf="message.action.icon">
              <img [src]="message.action.icon" loading="lazy" width="24" height="24" [alt]="message.action.title" />
            </ng-container>
          </a>
        </ng-container>
      </div>
    </div>
    <footer class="notification__footer"></footer>
  </div>
</ng-container>