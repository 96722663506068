import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CookieService {
  constructor(@Inject(DOCUMENT) private document: Document) {}

  public setCookie(name: string, value: string, domain?: string, expirationInMs: number = 365 * 24 * 36e5) {
    let date = new Date();
    date.setTime(date.getTime() + expirationInMs);
    const cookieValue = `${name}=${value}; ${domain ? `domain=${domain};` : ''}; expires=${date.toUTCString()}; path=/`;
    this.document.cookie = cookieValue;
  }

  public getCookie(name: string): string {
    const nameEq = `${name}=`;
    const cookies = this.document.cookie.split(';');
    for (const cookie of cookies) {
      const cookieString = cookie.trim();
      if (cookieString.startsWith(nameEq)) {
        return cookieString.substring(nameEq.length, cookieString.length);
      }
    }
    return null;
  }
}
