import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NotificationContainerComponent } from './notification-container/notification-container.component';
import { NotificationMessageComponent } from './notification-message/notification-message.component';
import { NotificationTriggersService } from './notification-triggers.service';




@NgModule({
    imports: [
    CommonModule,
    NotificationContainerComponent, NotificationMessageComponent
],
    exports: [NotificationContainerComponent, NotificationMessageComponent],
    providers: [NotificationTriggersService]
})
export class NotificationsModule { }
