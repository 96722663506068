import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { LegalDisclosure } from '@app/shared/models/legal-disclosures.model';
import { LoggerService } from '@app/logger.service';
import { YDL_FASTLINK_DISCLOSURE_ID } from '@app/shared/services/globalConstants';
import { ModalBaseClass } from '@app/shared/services/modal-mk2.service';
import { LegalActions } from '@app/_actions/legal.actions';
import { LegalDisclosureState } from '@app/_state/legal.state';
import { clientSupportServicesPhoneNumber } from '@locale/messages.global.en-US';
import { Store } from '@ngxs/store';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { YdlActions } from '../_actions/ydl.actions';
import { NgClass } from '@angular/common';
import { A11yModule } from '@angular/cdk/a11y';

@Component({
    selector: 'app-ydl-disclosure',
    templateUrl: './ydl-disclosure.component.html',
    styleUrls: ['./ydl-disclosure.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [A11yModule, NgClass]
})
export class YdlDisclosureComponent extends ModalBaseClass implements OnInit {
  clientSupportServicesPhoneNumber = clientSupportServicesPhoneNumber;
  private ydlDisclosure$: Observable<LegalDisclosure>;

  constructor(private store: Store, private logger: LoggerService, private cd: ChangeDetectorRef) {
    super();
  }

  ngOnInit(): void {
    this.ydlDisclosure$ = this.store.selectOnce(LegalDisclosureState.disclosureById(YDL_FASTLINK_DISCLOSURE_ID));
  }

  onInputChange() {
    this.cd.detectChanges();
  }

  handleAccept() {
    this.ydlDisclosure$
      .pipe(
        switchMap((ydlDisclosure) => {
          //if there is an active ydlDisclosure still when they accept, switch to a dispatch to update it, else switch to empty to signal no need of action
          return ydlDisclosure
            ? this.store.dispatch(new LegalActions.UpdateDisclosures([ydlDisclosure]))
            : of(ydlDisclosure);
        }),
        switchMap(() => this.ydlDisclosure$)
      )
      .subscribe(
        (ydlDisclosure) => {
          //if the agreement was signed, it should no longer be on the state, so we can forward to fastlink
          if (!ydlDisclosure) {
            this.store.dispatch(new YdlActions.OpenFastlinkModal());
          }
        },
        (e) => {
          this.logger.error(e);
        }
      );
  }
}
