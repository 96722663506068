import { Component, Input } from '@angular/core';
import { ModalBaseClass } from '@app/shared/services/modal-mk2.service';
import { NgIf } from '@angular/common';

@Component({
  selector: 'app-loading-spinner',
  templateUrl: './loading-spinner.component.html',
  styleUrls: ['./loading-spinner.component.scss'],
  standalone: true,
  imports: [NgIf]
})
export class LoadingSpinnerComponent extends ModalBaseClass {
  @Input() serviceCode: string = null;
  public readonly serviceCodeToCopy: { [key: string]: string } = {
    TRUST: 'online portfolio',
    STMTVIEW: 'online statements',
    MPS: 'eRoom',
    MBMS: 'Document Custodian',
    MTBROKE: 'Web Trading',
    MTOLB: 'M&T Online Banking',
    SBL: 'M&T Online Banking',
    MTSB: 'M&T Online Banking for Business',
    DMG: 'Electronic Vault'
  };

  constructor() {
    super();
  }
}
