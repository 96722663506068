import { enableProdMode, importProvidersFrom, isDevMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { environment } from '@env/local/environment';
import { ENV, getEnv } from 'env/environment.provider';
import { AppComponent } from './app/app.component';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsStoragePluginModule, StorageOption } from '@ngxs/storage-plugin';
import { NotificationState } from './app/notifications/_state/notifications.state';
import { MetaState } from './app/_state/meta.state';
import { LegalDisclosureState } from './app/_state/legal.state';
import { RelationshipTeamState } from './app/_state/relationshipTeam.state';
import { ProfileState } from './app/_state/profile.state';
import { HoldingsState } from './app/_state/holdings.state';
import { AccountsState } from './app/_state/accounts.state';
import { UserAgentState } from './app/_state/userAgent.state';
import { AuthState } from './app/_state/auth.state';
import { AppState } from './app/_state/app.state';
import { NgxsModule } from '@ngxs/store';
import { NotificationsModule } from './app/notifications/notifications.module';
import { OverlayModule } from '@angular/cdk/overlay';
import { A11yModule } from '@angular/cdk/a11y';
import { TimeoutModule } from './app/timeout/timeout.module';
import { AppRoutingModule } from './app/app-routing.module';
import { CommonModule } from '@angular/common';
import { HeaderModule } from './app/header/header.module';
import { BrowserModule, bootstrapApplication } from '@angular/platform-browser';
import { TokenInterceptor } from './app/shared/interceptors/token.interceptor';
import { HTTP_INTERCEPTORS, withInterceptorsFromDi, provideHttpClient } from '@angular/common/http';
import { TealiumUtagService } from '@shared/analytics/utag.service';
import { UserPreferencesState } from '@app/_state/userPreferences.state';
import { NgxsRouterPluginModule } from '@ngxs/router-plugin';
import { StoreModule, provideStore } from '@ngrx/store';
import { EffectsModule, provideEffects } from '@ngrx/effects';
import { provideStoreDevtools } from '@ngrx/store-devtools';

if (environment.production) {
  enableProdMode();
}

bootstrapApplication(AppComponent, {
  providers: [
    importProvidersFrom(
      BrowserModule,
      HeaderModule,
      CommonModule,
      AppRoutingModule,
      TimeoutModule,
      A11yModule,
      OverlayModule,
      NotificationsModule,
      NgxsModule.forRoot(
        [
          AppState,
          AuthState,
          UserAgentState,
          AccountsState,
          HoldingsState,
          ProfileState,
          RelationshipTeamState,
          LegalDisclosureState,
          MetaState,
          NotificationState,
          UserPreferencesState
        ],
        {
          developmentMode: !environment.production,
          selectorOptions: {
            injectContainerState: false,
            suppressErrors: false
          }
        }
      ),
      NgxsStoragePluginModule.forRoot({
        key: environment.impersonation
          ? ['app']
          : ['app', 'auth', 'aggregation', '_meta', 'profile', 'legal', 'accounts'],
        storage: StorageOption.SessionStorage
      }),
      NgxsReduxDevtoolsPluginModule.forRoot({ disabled: !environment.isDebugMode }),
      NgxsRouterPluginModule.forRoot(),
      EffectsModule.forRoot([]),
      StoreModule.forRoot({})
    ),
    TealiumUtagService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
    { provide: ENV, useFactory: getEnv },
    provideHttpClient(withInterceptorsFromDi()),
    provideStore(),
    provideEffects(),
    provideStoreDevtools({ maxAge: 25, logOnly: !isDevMode() })
  ]
}).catch((err) => console.error(err));
