import { ERROR_OBJECT } from "@app/shared/monitoring/monitoring.service";

export const NO_ACCT_IDS_TO_FETCH_HOLDINGS : ERROR_OBJECT = {
    code: 'E-000219-0',
    name: 'NO_ACCT_IDS_TO_FETCH_HOLDINGS',
    message: '[HoldingsState] No acctIds to fetch holdings'
};

export const NO_ACCT_IDS_TO_FETCH_HOLDINGS_BY_TIME_PERIOD : ERROR_OBJECT = {
    code: 'E-000220-0',
    name: ' NO_ACCT_IDS_TO_FETCH_HOLDINGS_BY_TIME_PERIOD',
    message: '[HoldingsState] No acctIds to fetch holdings by time period'
};