import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Store } from '@ngxs/store';
import { Notifications } from '../_actions/notification.actions';
import { NotificationConfig } from '../_state/notifications.config';
import { KeyboardA11yDirective } from '../../shared/directives/keyboard-a11y.directive';
import { NgIf, NgClass, NgStyle, NgFor } from '@angular/common';

@Component({
    selector: 'app-notification-message',
    templateUrl: './notification-message.component.html',
    styleUrls: ['./notification-message.component.scss'],
    standalone: true,
    imports: [NgIf, KeyboardA11yDirective, NgClass, NgStyle, NgFor]
})
export class NotificationMessageComponent {
  @Input( { required: true } ) message: NotificationConfig;
  @Input( { required: true } ) osName: string;
  openNotificationDetails = false;

  @Output() actionEvent = new EventEmitter<new (...args: any[]) => any>();
  constructor(private store: Store) { }

  closeNotificationMessage(messageId: string) {
    this.store.dispatch(new Notifications.Remove([messageId], [!!this.message.restrictOnUserClose]));
  }

  onActionClick(): void {
    this.actionEvent.emit(this.message.action.action);
  }

  onNotificationClick() {
    this.openNotificationDetails = !this.openNotificationDetails;
  }
}
