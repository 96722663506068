import { Injectable } from '@angular/core';
import { interval, fromEvent, merge } from 'rxjs';
import { environment } from '@env/local/environment';
import { throttle, takeUntil, switchMap, tap, skipUntil, map, finalize, first, take } from 'rxjs/operators';
import { LoggerService } from '@app/logger.service';
import { Actions, ofActionDispatched, Store } from '@ngxs/store';
import { AuthState } from '@app/_state/auth.state';
import { AuthActions } from '@app/_actions/auth.actions';

export function tokenMaintenanceServiceFactory(store: Store, logger: LoggerService, actions$: Actions) {
  if (environment.impersonation) {
    return {      
      initTimers: () => {logger.info('timers disabled by env') }
    };
  } else {
    return new TokenMaintenanceService(store, logger, actions$);
  }
}

@Injectable({
  providedIn: 'root',
  useFactory: tokenMaintenanceServiceFactory,
  deps: [Store, LoggerService, Actions]
})
export class TokenMaintenanceService {

  constructor(
    private store: Store,
    private logger: LoggerService,
    private actions$: Actions) {}

  public initTimers() {


    const signoffEvent$ = this.actions$.pipe(ofActionDispatched(AuthActions.SignOff), take(1));
    const userActivity$ = merge(
      fromEvent(document, 'keydown'),
      fromEvent(document, 'click'),
      fromEvent(document, 'mousemove').pipe(throttle((val) => interval(500))),
      fromEvent(document, 'scroll').pipe(throttle((val) => interval(500))),
      fromEvent(document, 'touchend'),
      fromEvent(document, 'touchmove').pipe(throttle((val) => interval(500))),
    ).pipe(
      map(()=>true),
      takeUntil(signoffEvent$),
      skipUntil(this.store.select(AuthState.isAuthenticated).pipe(first((_)=>!!_))),
      finalize(() => {
        this.logger.info('userActivity$ finalized');
      })
    );

    const timeoutTimer$ = userActivity$.pipe(
      switchMap((_) => interval(environment.timeout_time)),
      tap((tr) => {
        this.logger.info('timeoutTimer$ fired');
        this.store.dispatch(new AuthActions.StartSessionTimeout());
      }),
      takeUntil(signoffEvent$),
      finalize(() => {
        this.logger.info('timeoutTimer$ finalized');
      })
    );
    timeoutTimer$.subscribe();
  }
  
}
