import { Holdings } from '../models/client-holdings.model';

export function mapAssetClassToHoldings(holding: Holdings[]): Holdings[] {
  return holding.map((v) => {
    var assetClassObj = {};
    const assetClasses = v.assetClass.split('/');

    for (var i in assetClasses) {
      assetClassObj['schemaLevel' + (parseInt(i) + 1)] = assetClasses[i].trim();
    }

    return Object.assign({}, v, assetClassObj) as Holdings;
  });
}
