import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './header.component';
import { AppRoutingModule } from '../app-routing.module';
import { A11yModule } from '@angular/cdk/a11y';
import { NavTopComponent } from './nav-top/nav-top.component';

@NgModule({
    imports: [CommonModule, A11yModule, AppRoutingModule, HeaderComponent,
        NavTopComponent],
    exports: [
        HeaderComponent,
        NavTopComponent
    ]
})
export class HeaderModule { }
