import { Injectable } from '@angular/core';
import { LegalDisclosure } from '@app/shared/models/legal-disclosures.model';
import { LoggerService } from '@app/logger.service';
import { LegalService } from '@app/legal/legal.service';
import { LegalActions } from '@app/_actions/legal.actions';
import { Action, createSelector, Selector, State, StateContext } from '@ngxs/store';
import { switchMap, tap } from 'rxjs/operators';
import { AppActions } from '@app/_actions/app.actions';
import { of } from 'rxjs';

export interface LegalDisclosureStateModel {
  blocking: LegalDisclosure[];
  nonblocking: LegalDisclosure[];
  _loaded: boolean;
}

const defaultState: LegalDisclosureStateModel = {
  blocking: null,
  nonblocking: null,
  _loaded: false
};

@State<LegalDisclosureStateModel>({
  name: 'legal',
  defaults: defaultState
})
@Injectable()
export class LegalDisclosureState {
  constructor(private logger: LoggerService, private legalService: LegalService) {}

  //Legal Disclosures State

  @Selector([LegalDisclosureState])
  static blockingDisclosures(state: LegalDisclosureStateModel): LegalDisclosure[] | null {
    return state.blocking;
  }

  @Selector([LegalDisclosureState])
  static nonblockingDisclosures(state: LegalDisclosureStateModel): LegalDisclosure[] | null {
    return state.nonblocking;
  }

  static disclosureById(id: number) {
    return createSelector([LegalDisclosureState], (state: LegalDisclosureStateModel): LegalDisclosure => {
      return [...state.blocking, ...state.nonblocking].find((disclosure) => disclosure.id === id);
    });
  }

  //Action Handlers

  @Action([AppActions.ResetState])
  onResetState(ctx: StateContext<LegalDisclosureStateModel>, action: AppActions.ResetState) {
    ctx.setState(defaultState);
    return Promise.resolve();
  }

  @Action(LegalActions.FetchDisclosures, { cancelUncompleted: true })
  onFetchDisclosures(ctx: StateContext<LegalDisclosureStateModel>, action: LegalActions.FetchDisclosures) {
    return this.legalService.fetchLegalDisclosures().pipe(
      switchMap((v) => {
        ctx.patchState({
          blocking: v.filter((_) => _.block),
          nonblocking: v.filter((_) => !_.block),
          _loaded: true
        });
        return of(true);
      })
    );
  }

  @Action(LegalActions.UpdateDisclosures)
  onUpdateDisclosures(ctx: StateContext<LegalDisclosureStateModel>, action: LegalActions.UpdateDisclosures) {
    return this.legalService.acceptLegalDisclosures(action.disclosures).pipe(
      switchMap((responses) => {
        const anyRemainingActiveDisclosures = [...ctx.getState().blocking, ...ctx.getState().nonblocking].filter(
          (currentlyActiveDisc) => {
            return !responses.find((respObj) => {
              return respObj.disclosureId === currentlyActiveDisc.id && respObj.action === 'accepted';
            });
          }
        );
        ctx.patchState({
          blocking: anyRemainingActiveDisclosures.filter((_) => _.block),
          nonblocking: anyRemainingActiveDisclosures.filter((_) => !_.block),
          _loaded: true
        });
        return of(true);
      })
    );
  }
}
