import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { LoggerService } from '@app/logger.service';
import { RelationshipTeamActions } from '@app/_actions/relationshipTeam.actions';
import { RelationshipTeamState } from '@app/_state/relationshipTeam.state';
import { Store } from '@ngxs/store';
import { filter, map, Observable, of, switchMap, tap } from 'rxjs';
import { MetaActions } from '@app/_actions/meta.actions';
import { IntradayHoldingsActions } from '../account-holdings-intraday/_actions/intradayHoldings.actions';
import { IntradayHoldingsState } from '../account-holdings-intraday/_state/intradayHoldings.state';

@Injectable({
  providedIn: 'root'
})
export class AccountHoldingsOverviewResolverService  {
  constructor(private store: Store, private logger: LoggerService) {}

  resolve(): Observable<any> {
    return this.store
      .dispatch(new MetaActions.LazyLoadIntradayModuleThenDispatch(new IntradayHoldingsActions.FetchIntradayStatusAndThenPricing({fetchFor: 'allAccounts', state: 'initial'})))
      .pipe(
        map((res) => of(true))
      );
  }
}
