import { Inject, Injectable } from '@angular/core';
import { LoggerService } from '@app/logger.service';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { first, map, tap } from 'rxjs';
import { PreferencesService } from '@app/shared/services/preferences.service';
import { AppActions } from '@app/_actions/app.actions';
import { MetaState } from './meta.state';
import { ENV } from 'env/environment.provider';
import { Environment } from 'env/ienvironment';
import { UserPreferencesActions } from '@app/_actions/userPreferences.actions';

export interface UserPreferencesModel {
  acctIdsToOmitFromSnapshot: string[];
  showWealthTeam: boolean;
  showCallout: boolean;
  showDashboardAd: boolean;
  calloutDismissals: { [key: string]: boolean };
}

const defaultState: UserPreferencesModel = {
  acctIdsToOmitFromSnapshot: [],
  showWealthTeam: null,
  showCallout: null,
  showDashboardAd: null,
  calloutDismissals: {}
};

@State<UserPreferencesModel>({
  name: 'UserPreferences',
  defaults: defaultState
})
@Injectable()
export class UserPreferencesState {
  constructor(
    private logger: LoggerService,
    private preferencesService: PreferencesService,
    private store: Store,
    @Inject(ENV) private env: Environment
  ) {}

  private initialSetup = () => {
    this.store
      .select(MetaState.isInitialDataLoaded)
      .pipe(first((_) => _))
      .subscribe((_) => {
        this.store.dispatch(new UserPreferencesActions.FetchUserPreferences());
        if (this.env.enableFeature_dashboardAdvertisement) {
          this.store.dispatch(new UserPreferencesActions.FetchDashboardAdPrefernces());
        }
      });
  };

  ngxsOnInit(ctx: StateContext<UserPreferencesModel>) {
    this.initialSetup();
  }

  @Action(UserPreferencesActions.FetchUserPreferences)
  fetchUserPreferences(ctx: StateContext<UserPreferencesModel>, action: UserPreferencesActions.FetchUserPreferences) {
    return this.preferencesService.fetchPreferences().pipe(
      tap((userPref) => {
        this.logger.info('[FetchUserPreferences] Fetching User Preferences');
        if (userPref) {
          ctx.patchState({
            acctIdsToOmitFromSnapshot: userPref.unrenderAccounts ? userPref.unrenderAccounts : [],
            showCallout: userPref.callout,
            showWealthTeam: userPref.wealthTeam,
            calloutDismissals: userPref.calloutDismissals
          });
        } else {
          this.logger.info('[FetchUserPreferences] PatchState Default');
          ctx.patchState(defaultState);
        }
      })
    );
  }

  @Action([UserPreferencesActions.SetAdPreferncesToFalse])
  setCalloutPreferences(ctx: StateContext<UserPreferencesModel>) {
    ctx.patchState({
      showCallout: false
    });
    return this.preferencesService.setCalloutPreferenceToFalse();
  }

  @Action([UserPreferencesActions.DismissCallouts])
  dissmissCallouts(ctx: StateContext<UserPreferencesModel>, action: UserPreferencesActions.DismissCallouts) {
    return this.preferencesService.dismissCallouts(action.ids).pipe(
      map((response: { message: string }) => {
        if (response.message === 'SUCCESS') {
          return ctx.dispatch(new UserPreferencesActions.FetchUserPreferences());
        } else {
          this.logger.warn('Set dismiss callouts failure');
        }
      })
    );
  }

  @Action([UserPreferencesActions.FetchDashboardAdPrefernces])
  fetchDashboardAdPreferences(ctx: StateContext<UserPreferencesModel>) {
    this.logger.info('[UserPreferences] Fetching dashboard ad preferences');
    if (!this.env.enableFeature_dashboardAdvertisement) {
      return Promise.resolve();
    }
    if (ctx.getState().showDashboardAd == null) {
      return this.preferencesService.fetchDashboardAdPref().pipe(
        tap((flag) => {
          ctx.patchState({
            showDashboardAd: flag.showDepositAd
          });
        })
      );
    }
    return Promise.resolve();
  }

  @Action([AppActions.ResetState])
  onResetState(ctx: StateContext<UserPreferencesModel>, action: AppActions.ResetState) {
    ctx.setState(defaultState);
    return Promise.resolve();
  }

  @Selector([UserPreferencesState])
  static getAcctIdsToOmitFromSnapshot(state: UserPreferencesModel): string[] {
    return state.acctIdsToOmitFromSnapshot;
  }

  @Selector([UserPreferencesState])
  static getCalloutPreference(state: UserPreferencesModel): boolean {
    return state.showCallout;
  }

  @Selector([UserPreferencesState])
  static getWealthTeamPreference(state: UserPreferencesModel): boolean {
    return state.showWealthTeam;
  }

  @Selector([UserPreferencesState])
  static getAdPreference(state: UserPreferencesModel): boolean {
    return state.showDashboardAd;
  }

  @Selector([UserPreferencesState])
  static getCalloutDismissals(state: UserPreferencesModel): { [key: string]: boolean } {
    return state.calloutDismissals;
  }
}
