import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, of, forkJoin, map, catchError } from 'rxjs';
import { Environment } from 'env/ienvironment';
import { ENV } from 'env/environment.provider';
import { chunkArrayToSize } from '@app/shared/utils/sorting-utils';

export interface IntradayStatus {
  status: string;
  latestUpdateTimestamp?: string;
  error?: any;
  apiCompleted?: boolean;
  inScopeAssets?: boolean;
  latestUpdateTime?: string;
  lastPriceCheck?: string;
  updateRateMinutes?: number;
}

export interface IntradayPricingSymbols {
  type: string;
  value: string;
}

export interface IntradayPricingDetails {
  requestInstrument?: string;
  currentPrice: number;
  lastUpdatedTime?: string;
}

export interface IntradayPricing {
  [key: string]: IntradayPricingDetails;
}

export interface IntradayHoldingsData {
  latestUpdateTimestamp?: string;
  pricingData?: IntradayPricing;
  error?: any;
}

@Injectable({
  providedIn: 'root'
})
export class IntradayPricingService {
  private readonly INTRADAY_STATUS = '/intradaystatus';
  private readonly INTRADAY_PRICING = '/intradaypricing';

  constructor(
    private http: HttpClient,
    @Inject(ENV) private env: Environment) {}

  fetchIntradayStatus(): Observable<IntradayStatus> {
    let url = this.env.pcfUrl + this.INTRADAY_STATUS;

    return this.http.get<IntradayStatus>(url).pipe(
      map((res) => res),
      catchError((error) => of(error))
    );
  }

  #fetchIntradayPricingSimple(req: IntradayPricingSymbols[]): Observable<IntradayHoldingsData> {
    let url = this.env.pcfUrl + this.INTRADAY_PRICING;

    return this.http.post<IntradayHoldingsData>(url, req).pipe(
      map((res) => res),
      catchError((error) => of(error))
    );
  }

  fetchIntradayPricing(symbols: IntradayPricingSymbols[]): Observable<IntradayHoldingsData> {
    if (symbols?.length === 0) {
      return of({});
    }
    if (symbols?.length > 500) {
      let chunkedTickers = chunkArrayToSize(symbols, 500);
      const results = forkJoin(chunkedTickers.flatMap((chunk) => this.#fetchIntradayPricingSimple(chunk))).pipe(
        map((chunkedResults) => {
          const hasErrorOrNull = chunkedResults.some(
            (result) => result instanceof HttpErrorResponse || result === null
          );
          if (!hasErrorOrNull) {
            const pricingData = Object.assign({}, ...chunkedResults?.map((result) => result?.pricingData));
            const latestUpdateTimestamp = chunkedResults[chunkedResults.length - 1]?.latestUpdateTimestamp;
            return { latestUpdateTimestamp, pricingData };
          }
          return null;
        })
      );
      return results;
    } else {
      return this.#fetchIntradayPricingSimple(symbols);
    }
  }
}
