/**
 * Used when the monitoring OneAgent library script fails to load
 * @constant {Object}
 */
 export const E_004101_0 = {
    code: 'E-004101-0',
    name: 'MONITORING_ONEAGENT_LIBRARY_LOAD_ERROR',
    message: 'OneAgent script not loaded'
};


export const MONITORING_ERRORS = Object.freeze({
    E_004101_0
});

/**
 * Used when the monitoring scripts are intentionally disabled or turned off
 * @constant {Object}
 */
export const I_004101_0 = {
    code: 'I-004101-0',
    name: 'MONITORING_ONEAGENT_LIBRARY_ALREADY_LOADED',
    message: 'OneAgent script has already been loaded'
};

export const I_004102_0 = {
 code: 'I-004102-0',
 name: 'MONITORING_CONFIGURATION_NOT_FOUND',
 message: 'The monitoring environment or monitoring App ID are not set, this may have be done to toggle off monitoring'
};

export const MONITORING_INFORMATION = Object.freeze({
    I_004101_0,
    I_004102_0
});