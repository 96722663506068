import { Inject, Injectable } from '@angular/core';
import { UrlTree, Router, Route } from '@angular/router';
import { GlobalConstants } from '@app/shared/services/globalConstants';
import { AccountsState } from '@app/_state/accounts.state';
import { MetaState } from '@app/_state/meta.state';
import { ProfileState } from '@app/_state/profile.state';
import { Environment } from 'env/ienvironment';
import { ENV } from 'env/environment.provider';
import { Store } from '@ngxs/store';
import { Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { AuthState } from '@app/_state/auth.state';

@Injectable({
  providedIn: 'root'
})
export class RouteCanLoadGuard {
  private enableFeature_ydl: boolean = this.env.enableFeature_ydl;

  constructor(private store: Store, private _router: Router, @Inject(ENV) private env: Environment) {}

  canLoad(route: Route): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    return this.store.selectOnce(AuthState.isAuthenticated).pipe(
      switchMap((isAuthed) => {
        return isAuthed
          ? of(isAuthed).pipe(
              switchMap(() => {
                return this.store.select(MetaState.isInitialDataLoaded);
              }),
              switchMap(() => {
                switch (route.path) {
                  case 'total-picture':
                    return this.canTotalPicturePageLoad();
                  case 'reporting':
                    return this.canReportingPageLoad();
                  default:
                    return of(true);
                }
              })
            )
          : of(this._router.parseUrl('/login'));
      })
    );
  }

  canTotalPicturePageLoad() {
    return this.store.select(ProfileState).pipe(
      map((profile) => {
        return (this.enableFeature_ydl && profile?.ydlEligible) || this.redirectToDashboard();
      })
    );
  }

  canReportingPageLoad() {
    return this.store.select(AccountsState.currentEntitlments()).pipe(
      map((entitlements) => {
        if (Array.isArray(entitlements)) {
          return (
            entitlements.find((e) => e.serviceCode === GlobalConstants.servicesDictionary.wealth.code)
              ?.allowQuickLinks || this.redirectToDashboard()
          );
        }
        return this.redirectToDashboard();
      })
    );
  }

  redirectToDashboard() {
    return this._router.parseUrl('/dashboard');
  }
}
