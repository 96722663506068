import { NotificationDTO } from '../notification-triggers.service';
import { CODES, NotificationConfig } from '../_state/notifications.config';

export namespace Notifications {
  //forFeature modules should define their own CODES, but dispatch them with these actions
  //forRoot modules should define their codes in CODES

  export type NotificationCodes = Array<CODES | string>;

  export class Add {
    static readonly type = '[Notifications] Add';
    constructor(public notifications: NotificationCodes) {}
  }

  export class AddWithOverrides {
    static readonly type = '[Notifications] AddWithOverrides';
    constructor(public notifications: NotificationCodes, public overrides: Partial<NotificationConfig>[] = [{}]) {}
  }

  export class Remove {
    static readonly type = '[Notifications] Remove';
    constructor(public codesToRemove: NotificationCodes, public restrict?: boolean[]) {}
  }

  export class HidePushWarnings {
    static readonly type = '[Notifications] Hide Push Warnings';
    constructor() {}
  }

}
