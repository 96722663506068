import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { LoggerService } from '@app/logger.service';
import { Notifications } from '@app/notifications/_actions/notification.actions';
import { CODES } from '@app/notifications/_state/notifications.config';
import { AppActions } from '@app/_actions/app.actions';
import { UserAgentActions } from '@app/_actions/userAgent.actions';
import { Action, State, StateContext, Store, Selector } from '@ngxs/store';
import { patch } from '@ngxs/store/operators';
import * as Bowser from 'bowser';
import { concat, fromEvent, fromEventPattern, Observable, of, noop } from 'rxjs';
import { map } from 'rxjs/operators';
import { MonitoringService } from '@app/shared/monitoring/monitoring.service';
import { USER_AGENT_FAILED_PWA_INSTALL } from './userAgentErrorConstants';

export interface UserAgentStateModel {
  isUnsupportedBrowser?: boolean;
  pwaInstallPrompt?: Event | null;
  isPwaInstalled?: boolean;
  isPwaStandalone?: boolean;
  platform?: Bowser.Parser.ParsedResult;
  isMobileView?: boolean;
  showDocListDownload?: boolean;
  showDocListSpacer?: boolean;
}

const defaultState: UserAgentStateModel = {
  isUnsupportedBrowser: false,
  pwaInstallPrompt: null,
  isPwaInstalled: false,
  isPwaStandalone: false,
  isMobileView: false,
  showDocListDownload: false,
  showDocListSpacer: false
};

@State<UserAgentStateModel>({
  name: 'userAgent',
  defaults: defaultState
})
@Injectable()
export class UserAgentState {
  standaloneMediaQuery: MediaQueryList = window.matchMedia('(display-mode: standalone)');
  mobileView$: Observable<boolean>;
  showDocListDownload$: Observable<boolean>;
  showDocListSpacer$: Observable<boolean>;
  private get window() {
    return this._document.defaultView;
  }
  constructor(
    private logger: LoggerService,
    private monitoringService: MonitoringService,
    private store: Store,
    @Inject(DOCUMENT) private _document: Document
  ) {
    window.addEventListener(
      'beforeinstallprompt',
      (e) => {
        logger.info(`[UserAgentState]beforeinstallprompt listener: `, e);

        // Prevent the mini-infobar from appearing on mobile
        // e.preventDefault();
        // Stash the event so it can be triggered later.
        this.store.dispatch(new UserAgentActions.Update({ pwaInstallPrompt: e }));
      },
      { once: true }
    );
    // If user toggles between PWA and Web
    // Added to Support older browsers (Safari 12), https://developer.mozilla.org/en-US/docs/Web/API/MediaQueryList/addListener
    const isDisplayModeStandAlone$ = fromEventPattern<MediaQueryListEvent>(
      (handler) =>
        typeof this.standaloneMediaQuery.addEventListener === 'function'
          ? this.standaloneMediaQuery.addEventListener('change', handler)
          : this.standaloneMediaQuery.addListener(handler),
      (handler) =>
        typeof this.standaloneMediaQuery.removeEventListener === 'function'
          ? this.standaloneMediaQuery.removeEventListener('change', handler)
          : this.standaloneMediaQuery.removeListener(handler)
    )
      .pipe(map((v) => v.matches))
      .subscribe((isPwaStandalone) => this.store.dispatch(new UserAgentActions.Update({ isPwaStandalone })));

    let mediaMatchEvent = this.window.matchMedia('(max-width: 576px)');

    this.mobileView$ = concat(
      of(mediaMatchEvent.matches),
      fromEvent(mediaMatchEvent, 'change').pipe(map((v: MediaQueryListEvent) => v.matches))
    );

    let mediaMatchEventDocListDownload = this.window.matchMedia('(min-width: 768px)');

    this.showDocListDownload$ = concat(
      of(mediaMatchEventDocListDownload.matches),
      fromEvent(mediaMatchEventDocListDownload, 'change').pipe(map((v: MediaQueryListEvent) => v.matches))
    );

    let mediaMatchEventDocListSpacer = this.window.matchMedia('(min-width: 950px)');

    this.showDocListSpacer$ = concat(
      of(mediaMatchEventDocListSpacer.matches),
      fromEvent(mediaMatchEventDocListSpacer, 'change').pipe(map((v: MediaQueryListEvent) => v.matches))
    );
  }
  async ngxsOnInit(ctx: StateContext<UserAgentState>) {
    //if they are a PWA
    const BROWSER_DETECTION = Bowser.getParser(window.navigator.userAgent);

    // TODO: remove engine detection once Bowser.js Lib fixes https://github.com/lancedikson/bowser/issues/456, using below to support native iOS wrapper and iOS PWA
    const BROWSER_ENGINE = BROWSER_DETECTION.parseEngine();
    let statePatch: UserAgentStateModel = {
      isPwaStandalone: window.navigator?.['standalone'] ?? this.standaloneMediaQuery.matches,
      isUnsupportedBrowser:
        BROWSER_ENGINE.name === 'WebKit'
          ? false
          : !BROWSER_DETECTION.satisfies({
              chrome: '>=80',
              edge: '>=80',
              firefox: '>=80',
              safari: '>=11',
              mobile: {
                edge: '>=40',
                firefox: '>=30'
              }
            }),
      platform: BROWSER_DETECTION.getResult()
    };

    //push the userAgent meta data onto the state
    ctx.dispatch(new UserAgentActions.Update(statePatch));

    this.mobileView$.subscribe((isMobileView) => {
      this.store.dispatch(new UserAgentActions.Update({ isMobileView }));
    });

    this.showDocListDownload$.subscribe((showDocListDownload) => {
      this.store.dispatch(new UserAgentActions.Update({ showDocListDownload }));
    });

    this.showDocListSpacer$.subscribe((showDocListSpacer) => {
      this.store.dispatch(new UserAgentActions.Update({ showDocListSpacer }));
    });
  }

  @Selector()
  static selectUserAgentPlatform(state: UserAgentStateModel) {
    return state.platform;
  }

  @Selector()
  static isMobileView(state: UserAgentStateModel) {
    return state.isMobileView;
  }

  @Selector()
  static showDocListDownload(state: UserAgentStateModel) {
    return state.showDocListDownload;
  }

  @Selector()
  static showDocListSpacer(state: UserAgentStateModel) {
    return state.showDocListSpacer;
  }

  @Action([AppActions.ResetState])
  onResetState(ctx: StateContext<UserAgentStateModel>, action: AppActions.ResetState) {
    ctx.setState(defaultState);
    return Promise.resolve();
  }

  @Action(UserAgentActions.Update)
  onUpdate(ctx: StateContext<UserAgentStateModel>, action: UserAgentActions.Update) {
    ctx.setState(patch(action.patch));
    let notificationCodesToShow: CODES[] = [];
    ctx.getState().isUnsupportedBrowser ? notificationCodesToShow.push(CODES.USR_BROWSER_UNSUPPORTED) : noop();
    if (notificationCodesToShow.length > 0) {
      return this.store.dispatch(new Notifications.Add(notificationCodesToShow));
    }
    return Promise.resolve();
  }

  @Action(UserAgentActions.DoPWAInstall)
  onPWAInstall(ctx: StateContext<UserAgentStateModel>) {
    try {
      ctx.getState().pwaInstallPrompt['prompt']();
    } catch (error) {
      this.monitoringService.postAlertsToDynatrace(USER_AGENT_FAILED_PWA_INSTALL);
      this.logger.warn('[UserAgentState] onPWAInstall: failed to execute prompt()', error);
    }
  }
}
