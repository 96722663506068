import { Injectable } from '@angular/core';
import { Router, RoutesRecognized } from '@angular/router';
import { LoggerService } from '@app/logger.service';
import { Action, State, StateContext, Store } from '@ngxs/store';
import { AppActions } from '@app/_actions/app.actions';
import { patch } from '@ngxs/store/operators';
import { UserAgentState, UserAgentStateModel } from './userAgent.state';
import { filter, map, take } from 'rxjs/operators';

declare const APP_VERSION: string;
export interface AppStateModel {
  version: string;
  type: string;
}

const defaultState: AppStateModel = {
  version: APP_VERSION,
  type: 'WTC-Web'
};

@State<AppStateModel>({
  name: 'app',
  defaults: defaultState
})
@Injectable()
export class AppState {
  constructor(private logger: LoggerService, private store: Store, private _router: Router) {}

  private setupInitialSubscriptions = ()=>{
    // @see https://github.com/angular/angular/issues/12157 for why we can't subscribe to queryParam changes directly
    this._router.events
      .pipe(
        filter((event): event is RoutesRecognized => !!event && event instanceof RoutesRecognized),
        take(1),
        map((event) => event.state.root.queryParamMap)
      )
      .subscribe((queryParamMap) => {
        if (queryParamMap.has('appType')) {
          this.store.dispatch(new AppActions.Update({ type: queryParamMap.get('appType') }));
        }
      });

    this.store.selectOnce(UserAgentState).subscribe((user: UserAgentStateModel) => {
      if (user.isPwaStandalone) {
        this.store.dispatch(new AppActions.Update({ type: 'WTC-PWA' }));
      }
    });

    //dispatch an update to the appState based on the APP_VERSION determined at build time.
    //Need to do this to update the version, as the the NGSX Storage plugin is hydrating the previous version for analytics purposes
    this.store.dispatch(new AppActions.Update({ version: APP_VERSION }));
  }

  ngxsOnInit(ctx: StateContext<AppState>) {
    this.setupInitialSubscriptions()
    
  }

  @Action(AppActions.Update)
  onUpdate(ctx: StateContext<AppStateModel>, action: AppActions.Update) {
    ctx.setState(patch(action.patch));
    return Promise.resolve();
  }

  @Action([AppActions.ResetState])
  onResetState(ctx: StateContext<AppStateModel>, action: AppActions.ResetState) {
    ctx.setState(defaultState);
    this.setupInitialSubscriptions();
    return Promise.resolve();
  }
}
