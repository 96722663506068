import { PkceChallengeObject } from "../models/PkceChallengeObject";

export class PkceChallengeGenerator {
    private verifier: string;
    private random(length: number, mask: string): string {
      let result = "";
      let randomIndices = new Int8Array(length);
      window.crypto.getRandomValues(randomIndices);
      const byteLength = 256;
      const maskLength = Math.min(mask.length, byteLength);
      const scalingFactor = byteLength / maskLength;
  
      for (var i = 0; i < length; i++) {
        result += mask[Math.floor(Math.abs(randomIndices[i]) / scalingFactor)];
      }
      return result;
    }
  
    private base64UrlEncode(array: ArrayBuffer) : string {
      return btoa(String.fromCharCode.apply(null, new Uint8Array(array)))
        .replace(/\+/g, "-")
        .replace(/\//g, "_")
        .replace(/=+$/, "");
    }
  
    private generateVerifier(length: number) : string {
      const mask =
        "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789-._~";
      return this.random(length, mask);
    }
  
    public generateChallenge(length: number) : Promise<PkceChallengeObject> {
      this.verifier = this.generateVerifier(length);
      const encoder = new TextEncoder();
      const data = encoder.encode(this.verifier);
      return window.crypto.subtle.digest("SHA-256", data).then((array) => {
        return {
          code_challenge: this.base64UrlEncode(array),
          code_verifier: this.verifier,
        } as PkceChallengeObject;
      });
    }
  }

