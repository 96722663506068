import { Injectable } from '@angular/core';
import { ClientHoldingsService } from '@app/client-holdings.service';
import { LoggerService } from '@app/logger.service';
import {
  AccountHoldings,
  ConsolidatedHolding,
  Holdings,
  HoldingsTotals,
  TaxLots
} from '@app/shared/models/client-holdings.model';
import { GlobalFormatterService } from '@app/shared/services/global-formatter.service';
import { HoldingsTimePeriod } from '@app/shared/services/globalConstants';
import { AppActions } from '@app/_actions/app.actions';
import { HoldingsActions } from '@app/_actions/holdings.actions';
import { Action, createSelector, Selector, State, StateContext, Store } from '@ngxs/store';
import { EMPTY } from 'rxjs';
import { first, map, switchMap } from 'rxjs/operators';
import { AccountsState, CurrentClientAccountState } from './accounts.state';
import { MonitoringService } from '@app/shared/monitoring/monitoring.service';
import { NO_ACCT_IDS_TO_FETCH_HOLDINGS, NO_ACCT_IDS_TO_FETCH_HOLDINGS_BY_TIME_PERIOD } from './holdingStateErrorConstansts';

export interface HoldingsStateModel {
  allHoldings: AccountHoldings;
  holdingsByTimePeriod: [HoldingsTimePeriod, AccountHoldings][];
  currentSelectedTaxlots: TaxLots[];
  holdingIDsAfterFilter: string[];
}

const defaultState: HoldingsStateModel = {
  allHoldings: null,
  holdingsByTimePeriod: [],
  currentSelectedTaxlots: null,
  holdingIDsAfterFilter: []
};

@State<HoldingsStateModel>({
  name: 'holdings',
  defaults: defaultState
})
@Injectable()
export class HoldingsState {
  constructor(private logger: LoggerService, private store: Store, private holdingsService: ClientHoldingsService, private monitoringService: MonitoringService) {}

  //Action Hanlders
  @Action(HoldingsActions.Fetch)
  onFetch(ctx: StateContext<HoldingsStateModel>, action: HoldingsActions.Fetch) {
    return this.store.select(AccountsState.wealthAccountIds).pipe(
      first((acctIds) => !!acctIds && Array.isArray(acctIds)),
      switchMap((acctIds) => {
        if (acctIds) {
          return this.holdingsService.fetchHoldings(acctIds).pipe(map((holdings) => calculateHoldingsTotals(holdings)));
        } else {
          this.monitoringService.postAlertsToDynatrace(NO_ACCT_IDS_TO_FETCH_HOLDINGS);
          this.logger.warn('[HoldingsState] no acctIds to query holdings');
          return EMPTY;
        }
      }),
      switchMap((holdings) => {
        return ctx.dispatch(new HoldingsActions.SetHoldingssData(holdings));
      })
    );
  }

  @Action([AppActions.ResetState])
  onResetState(ctx: StateContext<HoldingsStateModel>, action: AppActions.ResetState) {
    ctx.setState(defaultState);
    return Promise.resolve();
  }

  @Action(HoldingsActions.FetchByAccountsForTimePeriod)
  onFetchByAccountsForTimePeriod(
    ctx: StateContext<HoldingsStateModel>,
    action: HoldingsActions.FetchByAccountsForTimePeriod
  ) {
    return this.store.selectOnce(AccountsState.accountIdsByCurrentAccountState).pipe(
      switchMap((acctIds) => {
        return this.holdingsService.fetchHoldings(acctIds, action.data).pipe(
          map((holdings) => calculateHoldingsTotals(holdings)),
          switchMap((holdings) => {
            return ctx.dispatch(new HoldingsActions.SetHoldingssDataForTimePeriod(action.data, holdings));
          })
        );
      })
    );
  }

  @Action(HoldingsActions.FetchAllHoldingsByTimePeriod)
  onFetchAllHoldingsByTimePeriod(
    ctx: StateContext<HoldingsStateModel>,
    action: HoldingsActions.FetchAllHoldingsByTimePeriod
  ) {
    return this.store.select(AccountsState.wealthAccountIds).pipe(
      first((acctIds) => !!acctIds && Array.isArray(acctIds)),
      switchMap((acctIds) => {
        if (acctIds) {
          return this.holdingsService
            .fetchHoldings(acctIds, action.data)
            .pipe(map((holdings) => calculateHoldingsTotals(combineDuplicateHoldings(holdings) as Holdings[])));
        } else {
          this.monitoringService.postAlertsToDynatrace(NO_ACCT_IDS_TO_FETCH_HOLDINGS_BY_TIME_PERIOD);
          this.logger.warn('[HoldingsState] no acctIds to query fetch all holdings');
          return EMPTY;
        }
      }),
      switchMap((holdings) => {
        return ctx.dispatch(new HoldingsActions.SetHoldingssDataForTimePeriod(action.data, holdings));
      })
    );
  }

  @Action(HoldingsActions.SetHoldingssData)
  onSetHoldingssData(ctx: StateContext<HoldingsStateModel>, action: HoldingsActions.SetHoldingssData) {
    ctx.patchState({
      allHoldings: action.data
    });

    return Promise.resolve();
  }

  @Action(HoldingsActions.SetHoldingssDataForTimePeriod)
  onSetHoldingssDataForTimePeriod(
    ctx: StateContext<HoldingsStateModel>,
    action: HoldingsActions.SetHoldingssDataForTimePeriod
  ) {
    let current = [...ctx.getState().holdingsByTimePeriod];
    if (current && current.length > 0) {
      let exisiting = current.findIndex(([timePeriod, holdings]) => {
        return timePeriod === action.timeperiod;
      });
      if (exisiting > -1) {
        current.splice(exisiting, 1);
        current.push([action.timeperiod, { ...action.accountHoldings }]);
      } else {
        current.push([action.timeperiod, action.accountHoldings]);
      }
    } else {
      current = [[action.timeperiod, action.accountHoldings]];
    }

    ctx.patchState({
      holdingsByTimePeriod: [...current]
    });
    return Promise.resolve();
  }

  @Action(HoldingsActions.ClearHoldingsByTimePeriod)
  onClearHoldingsByTimePeriod(
    ctx: StateContext<HoldingsStateModel>,
    action: HoldingsActions.ClearHoldingsByTimePeriod
  ) {
    ctx.patchState({
      holdingsByTimePeriod: []
    });
    return Promise.resolve();
  }

  //Selectors
  @Selector([HoldingsState, AccountsState.currentClientAccountState])
  static AccountHoldingsByCurrentAccountState(
    state: HoldingsStateModel,
    currentAcctState: CurrentClientAccountState
  ): AccountHoldings {
    if (currentAcctState?.account) {
      return {
        ...calculateHoldingsTotals(
          state.allHoldings.holdings.filter((v) => {
            return currentAcctState.account.acctId === v.acctId;
          })
        )
      };
    }
    if (currentAcctState?.group) {
      let acctIds: string[] = currentAcctState.group.acctList.map((act) => act.acctId);
      return {
        ...calculateHoldingsTotals(
          state?.allHoldings?.holdings.filter((v) => {
            return acctIds.includes(v.acctId);
          })
        )
      };
    }
  }

  static currentAccountHoldingsByTimePeriood(tp: HoldingsTimePeriod) {
    return createSelector(
      [HoldingsState, HoldingsState.AccountHoldingsByCurrentAccountState],
      (state: HoldingsStateModel, accountHoldings: AccountHoldings): [HoldingsTimePeriod, AccountHoldings] => {
        if (tp === 'CUR') {
          return ['CUR', accountHoldings];
        }
        return state?.holdingsByTimePeriod.find((hbtp) => hbtp[0] === tp);
      }
    );
  }

  static allHoldingsByTimePeriood(tp: HoldingsTimePeriod) {
    return createSelector([HoldingsState], (state: HoldingsStateModel): [HoldingsTimePeriod, AccountHoldings] => {
      if (tp === 'CUR') {
        return ['CUR', state.allHoldings];
      }
      return state?.holdingsByTimePeriod.find((hbtp) => hbtp[0] === tp);
    });
  }
}

export function calculateHoldingsTotals(holdings: Holdings[]): AccountHoldings {
  const totals: HoldingsTotals = holdings.reduce((acc, holding, i) => {
    //capture asset class summaries, if we recognize the asset class
    if (acc.asset[holding.schemaLevel1]) {
      acc.asset[holding.schemaLevel1].totalValue += holding.marketValue;
      if (holding.portfolioNum === 1) {
        acc.asset[holding.schemaLevel1].principalTotalValue += holding.marketValue;
      } else {
        acc.asset[holding.schemaLevel1].incomeTotalValue += holding.marketValue;
      }
    }

    //sum holdings level totals
    acc.holdings.cost += holding.taxCost;
    acc.holdings.unrealizedGainLoss += holding.unrealizedGainLoss;
    acc.holdings.estAnnualIncome += holding.estAnnualIncome;

    //sum portfolio level totals
    acc.portfolio.totalMarketValue += holding.marketValue;
    if (holding.portfolioNum === 1) {
      acc.portfolio.principalMarketValue += holding.marketValue;
    } else {
      acc.portfolio.incomeMarketValue += holding.marketValue;
    }
    return acc;
  }, new HoldingsTotals());

  const allocationHoldings = holdings.map((holding) => {
    const allocation = holding.marketValue / totals.portfolio.totalMarketValue;
    return Object.assign({}, holding, { allocation: allocation }) as Holdings;
  });
  
    //calculate allocation % per assetClass
  let positiveMarketValueSum = 0;
  Object.keys(totals.asset).forEach((assetClass) => {
    if (totals.asset[assetClass].totalValue > 0) {
      positiveMarketValueSum += totals.asset[assetClass].totalValue;
    }
  });
  Object.keys(totals.asset).forEach((assetClass) => {
    if (totals.asset[assetClass].totalValue <= 0) {
      totals.asset[assetClass].allocation = 0;
    } else {
      totals.asset[assetClass].allocation = totals.asset[assetClass].totalValue / positiveMarketValueSum;
    }
  });

  // Calculate Estimated Yield % (this is not a sumation of holding.estYield)
  if (totals.holdings.estAnnualIncome !== 0 && totals.portfolio.totalMarketValue !== 0) {
    totals.estimatedYield = GlobalFormatterService.percent(
      totals.holdings.estAnnualIncome / totals.portfolio.totalMarketValue
    );
  } else {
    totals.estimatedYield = '-';
  }

  return new AccountHoldings(totals, allocationHoldings);
}

export function combineDuplicateHoldings(holdings: Holdings[]): ConsolidatedHolding[] {
  return Object.values(
    holdings.reduce((acc, curr) => {
      if (!acc[curr.cusip]) acc[curr.cusip] = { ...curr, ids: [curr.id] } as ConsolidatedHolding;
      else {
        // Combine fields for duplicate holdings - keeping track of individual holding Ids
        acc[curr.cusip].ids.push(curr.id);
        acc[curr.cusip].qty += curr.qty;
        acc[curr.cusip].marketValue += curr.marketValue;
        acc[curr.cusip].taxCost += curr.taxCost;
        acc[curr.cusip].unrealizedGainLoss += curr.unrealizedGainLoss;
        acc[curr.cusip].accruedIncome += curr.accruedIncome;
        acc[curr.cusip].estAnnualIncome += curr.estAnnualIncome;
        acc[curr.cusip].allocation += curr.allocation;
        acc[curr.cusip].todaysChangeValue += curr.todaysChangeValue
      }
      return acc;
    }, [])
  ) as ConsolidatedHolding[];
}
