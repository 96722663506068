import { Injectable } from '@angular/core';
import { LoggerService } from '@app/logger.service';
import { ChartsWrapperService } from '@app/shared/services/charts-wrapper.service';
import { ErrorHandlingService } from '@app/shared/services/error-handling.service';
import { GlobalConstants, YDL_FASTLINK_DISCLOSURE_ID } from '@app/shared/services/globalConstants';
import { AccountsActions } from '@app/_actions/accounts.actions';
import { AppActions } from '@app/_actions/app.actions';
import { LegalActions } from '@app/_actions/legal.actions';
import { AccountsState } from '@app/_state/accounts.state';
import { LegalDisclosureState } from '@app/_state/legal.state';
import { environment } from '@env/local/environment';
import { Action, State, StateContext, Store } from '@ngxs/store';
import { EMPTY, from, of } from 'rxjs';
import { catchError, concatMap, filter, map, switchMap, tap } from 'rxjs/operators';
import { YdlService } from '../ydl.service';
import { YdlActions } from '../_actions/ydl.actions';
import { YdlAuthResp } from '../_models/YdlAuthResp';
import { RESP_STATUS_SUCCESS } from '../ydl-constants';

export interface YdlStateModel {
  ydlAuthResp?: YdlAuthResp;
  isFastlinkJsLoaded?: boolean;
}

const defaultState: YdlStateModel = {};

@State<YdlStateModel>({
  name: 'ydl',
  defaults: defaultState
})
@Injectable()
export class YdlState {
  constructor(
    private logger: LoggerService,
    private ydlService: YdlService,
    private store: Store,
    private errorService: ErrorHandlingService,
    private chartService: ChartsWrapperService
  ) {}

  ngxsOnInit(ctx: StateContext<YdlState>) {}

  @Action([AppActions.ResetState])
  onResetState(ctx: StateContext<YdlStateModel>, action: AppActions.ResetState) {
    ctx.setState(defaultState);
  }

  @Action(YdlActions.FetchYdlAuth)
  onFetchYdlAuth(ctx: StateContext<YdlStateModel>, action: YdlActions.FetchYdlAuth) {
    return this.ydlService.fetchFastlinkConnectionData().pipe(
      catchError((_) => {
        this.logger.error(_);
        ctx.patchState({ ydlAuthResp: null });
        return EMPTY;
      }),
      tap((ydlAuthResp) => {
        ctx.patchState({ ydlAuthResp: ydlAuthResp });
      })
    );
  }

  @Action(YdlActions.DeleteYdlAuth)
  onDeleteYdlAuth(ctx: StateContext<YdlStateModel>, action: YdlActions.DeleteYdlAuth) {
    const token = ctx.getState()?.ydlAuthResp?.accessToken;
    return this.ydlService.deleteFastlinkConnectionData(token).pipe(
      catchError((_) => {
        this.logger.error(_);
        ctx.patchState({ ydlAuthResp: null });
        return EMPTY;
      }),
      tap((ydlAuthResp) => {
        ctx.patchState({ ydlAuthResp: null });
      })
    );
  }

  @Action(YdlActions.OpenMarketingModal)
  onOpenMarketingModal(ctx: StateContext<YdlStateModel>, action: YdlActions.OpenMarketingModal) {
    this.ydlService.openMarketingModal();
  }

  @Action(YdlActions.LoadFastLinkJs)
  onLoadFastLinkJs(ctx: StateContext<YdlStateModel>, action: YdlActions.LoadFastLinkJs) {
    this.chartService.removeAllActiveElementsCharts();

    this.logger.info('[ydl.state] onLoadFastLinkJs start');
    //calls lazyloadFastlinkJs() if needed, and updates the state when complete
    return of(ctx.getState().isFastlinkJsLoaded || environment.impersonation).pipe(
      concatMap((shouldNotLoad) => {
        return shouldNotLoad ? of(!shouldNotLoad) : from(this.ydlService.lazyLoadFastlinkJs()).pipe();
      }),
      tap(() => {
        this.logger.info('[ydl.state] onLoadFastLinkJs result: ', !!window.fastlink);
        ctx.patchState({ isFastlinkJsLoaded: !!window.fastlink });
      })
    );
  }

  @Action(YdlActions.OpenFastlinkModal)
  onOpenFastlinkModal(ctx: StateContext<YdlStateModel>, action: YdlActions.OpenFastlinkModal) {
    this.logger.info('[ydl.state] onOpenFastlinkModal start');
    if (environment.impersonation) {
      this.errorService.pushNotificationErrorMessege(GlobalConstants.error451Message);
      const error = new Error('[WT2] Feature unavailable for legal reasons');
      this.logger.error(error);
      throw error;
    }

    return ctx.dispatch(new YdlActions.RegisterYdlService()).pipe(
      concatMap(() => ctx.dispatch(new YdlActions.LoadFastLinkJs())),
      concatMap(() =>
        this.store
          .selectOnce(LegalDisclosureState.disclosureById(YDL_FASTLINK_DISCLOSURE_ID))
          .pipe(map((disclosure) => !!disclosure?.active))
      ),
      concatMap((hasActiveDisclosure) => {
        this.logger.info('[ydl.state] onOpenFastlinkModal, hasActiveDisclosure: ', hasActiveDisclosure);
        return hasActiveDisclosure
          ? ctx.dispatch(new YdlActions.OpenDisclosureModal())
          : of(hasActiveDisclosure).pipe(
              tap(() => {
                this.logger.info('[ydl.state] onOpenFastlinkModal, opening modal...');
                this.ydlService.openFastLinkModal(action.flow, action.aggAccount);
              })
            );
      })
    );
  }

  @Action(YdlActions.OpenDisclosureModal)
  onOpenDisclosureModal(ctx: StateContext<YdlStateModel>, action: YdlActions.OpenDisclosureModal) {
    if (environment.impersonation) {
      this.errorService.pushNotificationErrorMessege(GlobalConstants.error451Message);
      const error = new Error('[WT2] Feature unavailable for legal reasons');
      this.logger.error(error);
      throw error;
    }
    this.ydlService.openDisclosureModal();
  }

  @Action(YdlActions.RegisterYdlService)
  onRegisterYdlService(ctx: StateContext<YdlStateModel>, action: YdlActions.RegisterYdlService) {
    if (environment.impersonation) {
      this.errorService.pushNotificationErrorMessege(GlobalConstants.error451Message);
      const error = new Error('[WT2] Feature unavailable for legal reasons');
      this.logger.error(error);
      throw error;
    }
    return this.store.selectOnce(AccountsState.currentEntitlementsCodes()).pipe(
      map((currentEntitlments) => {
        //check if they already have the YDL service
        return currentEntitlments.includes(GlobalConstants.servicesDictionary.YDL.code);
      }),
      switchMap((hasYdlService) => {
        //if they do, no-op, else call the register api and refresh the AccountsState and Disclosures
        this.logger.info('[YDL] hasYdlService:', hasYdlService);
        return hasYdlService
          ? of(hasYdlService)
          : this.ydlService.registerYdlService().pipe(
              tap((v) => {
                this.logger.info('[YDL] registerYdlService.tap:', v);
              }),
              filter((v) => v.status === RESP_STATUS_SUCCESS),
              concatMap(() => ctx.dispatch(new AccountsActions.Fetch())),
              concatMap(() => ctx.dispatch(new LegalActions.FetchDisclosures()))
            );
      })
    );
  }
}
