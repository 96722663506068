import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { LoggerService } from '@app/logger.service';
import { LazyLoadService } from '@app/shared/utils/lazy-load.service';
import { Environment } from 'env/ienvironment';
import { ENV } from 'env/environment.provider';

import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { AggFinSummary, AggProviderAccount } from './_models/AggFinSummary';

export interface AggregatedAccountsRequestBody {
  ids: Array<string> | null;
  source: string | null;
}

@Injectable({
  providedIn: 'root'
})
export class AggregationService {
  private readonly AGGREGATED_ACCOUNTS_URL = `${this.env.pcfUrl}/aggregatedaccounts/search`;
  private readonly PROVIDER_ACCOUNTS_URL = `${this.env.pcfUrl}/provideraccounts`;
  private readonly AGGREGATED_ACCOUNTS_DELETE_URL = `${this.env.pcfUrl}/ydldeleteacct`;
  private readonly PROVIDER_ACCOUNTS_REFRESH_URL = `${this.env.pcfUrl}/refreshydlaccounts?providerAccountIds=`;
  private readonly REFRESH_ALL_ACCOUNTS_URL = `${this.env.pcfUrl}/refreshydlaccounts`;
  private readonly PROVIDER_ACCOUNTS_DELETE_URL = `${this.env.pcfUrl}/ydldeleteprovideracct?providerAccountIds=`;

  constructor(
    private store: Store,
    private http: HttpClient,
    private _lazyLoadService: LazyLoadService,
    private l: LoggerService,
    @Inject(ENV) private env: Environment
  ) {}

  fetchAggregationData(searchIds: Array<string> = null, src: string = null): Observable<AggFinSummary> {
    return this.http.post<AggFinSummary>(this.AGGREGATED_ACCOUNTS_URL, { ids: searchIds, source: src });
  }

  fetchProviderAccountData(src: string = null): Observable<AggProviderAccount[]> {
    let url = src && src.length > 0 ? `${this.PROVIDER_ACCOUNTS_URL}?src=${src}` : this.PROVIDER_ACCOUNTS_URL;
    return this.http.get<AggProviderAccount[]>(url);
  }

  refreshProviderAccounts(providerAccountIds?: string[]) {
    if (providerAccountIds) {
      return this.http.get(this.PROVIDER_ACCOUNTS_REFRESH_URL + providerAccountIds.join(','));
    } else {
      return this.http.get(this.REFRESH_ALL_ACCOUNTS_URL);
    }
  }

  deleteProviderAccounts(providerAccountIds: string[]) {
    return this.http.post(this.PROVIDER_ACCOUNTS_DELETE_URL, { providerAcctId: providerAccountIds.join(',') });
  }

  deleteAggregationAccounts(accountIds: string[]) {
    return this.http.post(this.AGGREGATED_ACCOUNTS_DELETE_URL, { acctId: accountIds.join(',') });
  }

  lazyLoadYdlModule(): Promise<any> {
    return this._lazyLoadService.loadModule(import('../ydl/ydl.module').then((m) => m.YdlModule)).then(
      () => {
        this.l.info('Sucessfully lazy loaded YdlModule!');
      },
      (reason) => {
        this.l.error(new Error('ERROR: lazy load YDL Module failed'));
      }
    );
  }
}
