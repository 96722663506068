import { Injectable } from '@angular/core';
import { LoggerService } from '@app/logger.service';

declare global {
  interface Window {
    dtrum: any;
  }
}

export interface ERROR_OBJECT {
  code: string,
  name: string, 
  message: string
}

@Injectable({
  providedIn: 'root'
})
export class MonitoringService {

  constructor(private _loggerService: LoggerService) { }

  sendErrorReport(key: string, value: string, hint?: string, parentingInfo?: number | boolean): void {
    if (typeof window?.dtrum?.reportCustomError === 'function') {
      // see https://www.dynatrace.com/support/doc/javascriptapi/interfaces/dtrum_types.dtrumapi.html#reportcustomerror
      window.dtrum.reportCustomError(key, value, hint, parentingInfo);
    } else {
      this._loggerService.error(new Error(`${key}: ${value}`));
    }
  }

  postAlertsToDynatrace(error_object: ERROR_OBJECT) : void {
    this.sendErrorReport(error_object.code, error_object.name, error_object.message);
  }
}
