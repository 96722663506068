export namespace UserPreferencesActions {
  export class FetchUserPreferences {
    static readonly type = '[UserPreferences] Fetch User Preferences';
    constructor() {}
  }

  export class SetAdPreferncesToFalse {
    static readonly type = '[UserPreferences] Set User Ad Preference to False';
    constructor() {}
  }

  export class DismissCallouts {
    static readonly type = '[UserPreferences] Dissmiss Callouts';
    constructor(public ids: string[]) {}
  }

  export class FetchDashboardAdPrefernces {
    static readonly type = '[UserPreferences] Fetch User Dashboard Ad Preferences';
    constructor() {}
  }
}
