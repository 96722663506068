export type HoldingsTimePeriod = 'CUR' | 'PMN' | 'PQT' | 'PYR';
export const TimePeriods: [string, HoldingsTimePeriod][] = [
  ['Prior Business Day', 'CUR'],
  ['Prior Month End', 'PMN'],
  ['Prior Quarter End', 'PQT'],
  ['Prior Year End', 'PYR']
];

export const percentageFormat = '1.2';

export const MONEY_MOVE_DISCLOSURE_ID = 30;
export const YDL_FASTLINK_DISCLOSURE_ID = 31;

export const calloutIDs = {
  taxLotExport: { dashboard: 'taxlot_export_dashboard', gainLoss: 'taxlot_export_gl' }
};

export const GlobalConstants = {
  appTitle: 'WtClientExperience',
  dateFormat: 'longDate',
  percentageFormat: '1.2',
  fileExportPrepend: 'Wilmington_Trust_Export',
  locale: 'en-US',
  pieChartColorSchemeDomain: ['#3253A4', '#6C8C7C', '#989E36', '#6E295B', '#60517E', '#95A0A8', '#D85427'],
  orderedAssetClassLabels: [
    'Equities',
    'Fixed Income',
    'Real Assets',
    'Hedge Funds',
    'Private Markets',
    'Other',
    'Cash & Equivalents'
  ],
  documentTypesMap: new Map([
    ['ACT', 'Statements'],
    ['TAX', 'Tax Documents'],
    ['PER', 'Performance Documents']
  ]),
  servicesDictionary: {
    brokerage: {
      code: 'MTBROKE'
    },
    documentCustodian: {
      code: 'MBMS'
    },
    eRoom: {
      code: 'MPS'
    },
    personalBanking: {
      code: 'MTOLB'
    },
    wealth: {
      code: 'TRUST'
    },
    securitiesBasedLoans: {
      code: 'SBL'
    },
    businessBanking: {
      code: 'MTSB'
    },
    onboarding: {
      code: 'ONB'
    },
    EVault: {
      code: 'DMG'
    },
    transferMoney: {
      code: 'MOV'
    },
    statement: {
      code: 'STMTVIEW'
    },
    WT365: {
      code: 'WT365'
    },
    LPL: {
      code: 'LPL'
    },
    YDL: {
      code: 'YDL'
    }
  },
  pageTitleAppend: '| Wilmington Trust',
  error451Message: 'This feature is not allowed through impersonation',
  fastLinkError: 'fastLinkError'
};

export const assetClassUISchema: {
  label: string;
  backgroundColor: string;
  hoverBackgroundColor: string;
}[] = [
  {
    label: 'Equities',
    backgroundColor: '#3253A4',
    hoverBackgroundColor: '#A7B7DE'
  },
  {
    label: 'Fixed Income',
    backgroundColor: '#6C8C7C',
    hoverBackgroundColor: '#BCC8B2'
  },
  {
    label: 'Real Assets',
    backgroundColor: '#989E36',
    hoverBackgroundColor: '#D8D488'
  },
  {
    label: 'Hedge Funds',
    backgroundColor: '#6E295B',
    hoverBackgroundColor: '#BD9CB6'
  },
  {
    label: 'Private Markets',
    backgroundColor: '#60517E',
    hoverBackgroundColor: '#B3AED0'
  },
  {
    label: 'Other',
    backgroundColor: '#95A0A9',
    hoverBackgroundColor: '#ADB7C0'
  },
  {
    label: 'Cash & Equivalents',
    backgroundColor: '#F16521',
    hoverBackgroundColor: '#FFAE52'
  }
];

export const disableMiniSurveyRouteUrls = ['/legal/disclosures','/profile/firstlogin'];