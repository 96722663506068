import {
  Component,
  OnInit,
  ElementRef,
  ViewChild,
  HostListener,
  OnDestroy,
  ChangeDetectionStrategy,
  Inject
} from '@angular/core';
import { Profile } from '@app/shared/models/profile.model';
import { Observable, Subscription, combineLatest } from 'rxjs';
import { distinctUntilChanged, filter, map, shareReplay } from 'rxjs/operators';
import { Event, Router, NavigationStart, NavigationEnd, RouterEvent, RouterLink, RouterLinkActive } from '@angular/router';
import { Environment } from 'env/ienvironment';
import { ENV } from 'env/environment.provider';
import { Store } from '@ngxs/store';
import { AccountsState } from '@app/_state/accounts.state';
import { AuthState } from '@app/_state/auth.state';
import { GlobalConstants } from '@app/shared/services/globalConstants';
import { ProfileState } from '@app/_state/profile.state';
import { MetaState } from '@app/_state/meta.state';
import { NgStyle, NgIf, NgClass, AsyncPipe } from '@angular/common';
import { SignOutComponent } from '@app/authentication/sign-out/sign-out.component';

@Component({
    selector: 'app-nav-top',
    templateUrl: './nav-top.component.html',
    styleUrls: ['./nav-top.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [NgStyle, NgIf, RouterLink, RouterLinkActive, NgClass, AsyncPipe, SignOutComponent]
})
export class NavTopComponent implements OnInit, OnDestroy {
  @ViewChild('navToggleButton', { static: false }) navToggleButton: ElementRef;
  @ViewChild(SignOutComponent, {read: SignOutComponent, static: true}) signOutComponent: SignOutComponent;
  public authorized$: Observable<boolean>;
  public isBootstrapComplete$: Observable<boolean>;
  public showNavItems$: Observable<boolean>;
  public showReporting$: Observable<boolean>;
  public showTransfer$: Observable<boolean>;
  public profileData$: Observable<Profile>;
  public isImpersonation: boolean = this.env.impersonation;
  public envName: string = ['prod', 'dr'].includes(this.env.nameAbbreviation) ? null : this.env.nameAbbreviation;
  public envBGColorOverride: string = this.env.environmentBannerColor ? this.env.environmentBannerColor : null;
  public showTotalPicture$: Observable<boolean>;
  private enableFeature_ydl: boolean = this.env.enableFeature_ydl;
  public enableFeature_consolidatedHoldings: boolean = this.env.enableFeature_consolidatedHoldings;
  private isMenuExpanded = false;
  private subs: Subscription[] = [];
  private currentElement;
  private target;
  public showConsolidatedHoldings$: Observable<boolean>;

  constructor(
    private router: Router,
    private store: Store,
    @Inject(ENV) private env: Environment) {}

  @HostListener('window:keydown', ['$event']) onKeyDown(event: KeyboardEvent) {
    const key = event.key;
    const escapeCodes = new Set(['Escape', 'Esc']);
    if (escapeCodes.has(key) && this.isMenuExpanded) {
      this.isMenuExpanded = false;
      this.doToggleMenu(this.navToggleButton.nativeElement);
    } else {
      return;
    }
  }

  @HostListener('window:click', ['$event']) onClick(event: MouseEvent) {
    this.target = event.target as Element;
    const targetIsNotMenu = !(
      this.target.classList.contains('c-nav__menubaritem') ||
      this.target.classList.contains('c-nav__menuitem') ||
      this.target.classList.contains('c-nav__menuitem-text') ||
      this.target.classList.contains('c-nav__menu')
    );
    if (this.isMenuExpanded && targetIsNotMenu) {
      //stop the event so they dont trigger a link or button when clicking off nav menu
      event.stopPropagation();
      event.preventDefault();
      this.doToggleMenu(this.target);
    } else {
      return;
    }
  }

  ngOnInit() {
    this.profileData$ = this.store.select<Profile>(ProfileState).pipe(shareReplay(1));

    this.showReporting$ = this.store.select(AccountsState.currentEntitlments()).pipe(
      map((entitlements) => {
        if (Array.isArray(entitlements)) {
          return entitlements.find((e) => e.serviceCode === GlobalConstants.servicesDictionary.wealth.code)
            ?.allowQuickLinks;
        }
        return false;
      })
    );

    this.showTransfer$ = this.store.select(AccountsState.currentEntitlementsCodes()).pipe(
      map((entitlements) => {
        if (Array.isArray(entitlements)) {
          return entitlements.includes(GlobalConstants.servicesDictionary.transferMoney.code);
        }
        return false;
      })
    );

    this.authorized$ = this.store.select(AuthState.isAuthenticated).pipe(distinctUntilChanged());
    this.isBootstrapComplete$ = this.store.select(MetaState.isInitialDataLoaded).pipe(distinctUntilChanged());

    this.showNavItems$ = combineLatest([this.authorized$, this.isBootstrapComplete$]).pipe(
      map(([isAuth, isBootstrapped]) => isAuth && isBootstrapped)
    );

    const routerSub = this.router.events
      .subscribe((e: Event | RouterEvent) => {
        if (e instanceof NavigationStart) {
          if (this.isMenuExpanded) {
            this.isMenuExpanded = false;
            this.doToggleMenu(this.navToggleButton.nativeElement);
          }
        }
      });

    this.subs.push(routerSub);

    this.showTotalPicture$ = this.profileData$.pipe(map((profile) => this.enableFeature_ydl && profile?.ydlEligible));

    this.showConsolidatedHoldings$ = this.store.select<Profile>(ProfileState).pipe(
      map((profile) => {
        return profile.member === 1 && this.enableFeature_consolidatedHoldings;
      })
    );
  }

  initiateLogin() {
    this.router.navigateByUrl(this.router.parseUrl('/login'));
  }

  toggleExpandMenu(event: MouseEvent) {
    event.preventDefault();
    this.currentElement = event.target as Element;
    this.isMenuExpanded = !this.isMenuExpanded;
    this.doToggleMenu(this.currentElement);
  }

  ngOnDestroy() {
    this.subs.forEach((sub) => sub.unsubscribe());
  }

  public openSignOutDialog(){
    this.signOutComponent.open();
  }

  private doToggleMenu(el: Element) {
    if (this.isMenuExpanded && el === this.navToggleButton.nativeElement) {
      el.setAttribute('aria-expanded', 'true');
    } else {
      this.navToggleButton.nativeElement.setAttribute('aria-expanded', 'false');
      this.isMenuExpanded = false;
    }
  }
}
