
export namespace MetaActions {

  export class LazyLoadYdlModuleThenDispatch {
    static readonly type = '[Meta] Lazy load YDL module then dispatch action';
    constructor(public nextAction: any) {}
  }

  export class LazyLoadAggregationModuleThenDispatch {
    static readonly type = '[Meta] Lazy load Aggregation module then dispatch action';
    constructor(public nextAction: any) {}
  }

  export class LazyLoadIntradayModuleThenDispatch {
    static readonly type = '[Meta] Lazy load Intraday module then dispatch action';
    constructor(public nextAction: any) {}
  }

}
