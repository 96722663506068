import { Component, OnInit, OnDestroy } from '@angular/core';
import { ModalBaseClass } from '@app/shared/services/modal-mk2.service';
import { interval, Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { Title } from '@angular/platform-browser';
import { Store } from '@ngxs/store';
import { AuthActions } from '@app/_actions/auth.actions';
import { A11yModule } from '@angular/cdk/a11y';

@Component({
    selector: 'app-timeout',
    templateUrl: './timeout.component.html',
    styleUrls: ['./timeout.component.scss'],
    standalone: true,
    imports: [A11yModule]
})
export class TimeoutComponent extends ModalBaseClass
  implements OnInit, OnDestroy {
  countDownLimit = 60; //seconds to count down from
  countDownSubstractionValue = 0; //seconds counted so far, subtracted from countDownLimit for display
  countDownSubscription: Subscription;
  private docTitleCache: string;
  constructor(
    private store: Store,
    private titleService: Title
  ) {
    super();
  }

  ngOnInit() {
    this.docTitleCache = this.titleService.getTitle();
    this.countDownSubscription = interval(1000)
      .pipe(take(this.countDownLimit))
      .subscribe({
        next: cnt => {
          this.countDownSubstractionValue = cnt + 1;
          this.titleService.setTitle(
            `Signing Out...${this.countDownLimit -
              this.countDownSubstractionValue}`
          );
        },
        complete: () => {
          this.signoutSession();
        }
      });
  }

  ngOnDestroy() {
    document.title = this.docTitleCache;
    this.countDownSubscription.unsubscribe();
  }

  signoutSession() {
    this.countDownSubscription.unsubscribe();
    this.store.dispatch(new AuthActions.SignOff());
  }

  closeModal() {
    this.countDownSubscription.unsubscribe();
    this.requestClose();
  }
}
