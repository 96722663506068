import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Environment } from 'env/ienvironment';
import { ENV } from 'env/environment.provider';
import { ClientAccounts } from '@shared/models/client-accounts.model';

@Injectable({
  providedIn: 'root'
})
export class ClientAccountService {
  private dashBoard = '/dashboard';

  constructor(
    private http: HttpClient,
    @Inject(ENV) private env: Environment
  ) {}

  fetchClientAccounts(): Observable<ClientAccounts> {
    return this.http.get<ClientAccounts>(this.env.pcfUrl + this.dashBoard);
  }
}
