import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TimeoutRoutingModule } from './timeout-routing.module';
import { TimeoutComponent } from './timeout/timeout.component';
import { A11yModule } from '@angular/cdk/a11y';

@NgModule({
    imports: [CommonModule, TimeoutRoutingModule, A11yModule, TimeoutComponent],
    exports: [TimeoutComponent]
})
export class TimeoutModule { }
