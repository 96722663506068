import { Component } from '@angular/core';
import { NavTopComponent } from './nav-top/nav-top.component';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
    standalone: true,
    imports: [NavTopComponent]
})
export class HeaderComponent {

}
