import { Injectable } from '@angular/core';
import { LoggerService } from '@app/logger.service';
import { LazyLoadService } from '@app/shared/utils/lazy-load.service';

@Injectable({
  providedIn: 'root'
})
export class MetaService {
  constructor(private _lazyLoadService: LazyLoadService, private l: LoggerService) {}

  lazyLoadYdlModule(): Promise<any> {
    return this._lazyLoadService.loadModule(import('../../ydl/ydl.module').then((m) => m.YdlModule)).then(
      () => {
        this.l.info('Sucessfully lazy loaded YdlModule!');
      },
      (reason) => {
        this.l.error(new Error('ERROR: lazy load YDL Module failed'));
      }
    );
  }

  lazyLoadAggregationModule(): Promise<any> {
    return this._lazyLoadService.loadModule(import('../../aggregation/aggregation.module').then((m) => m.AggregationModule)).then(
      () => {
        this.l.info('Sucessfully lazy loaded AggregationModule!');
      },
      (reason) => {
        this.l.error(new Error('ERROR: lazy load AggregationModule failed'));
      }
    );
  }
  
  lazyLoadIntradayModule(): Promise<any> {
    return this._lazyLoadService.loadModule(import('../../account/account-holdings-intraday/account-holdings-intraday.module').then((m) => m.AccountHoldingsIntradayModule)).then(
      () => {
        this.l.info('Sucessfully lazy loaded IntradayModule!');
      },
      (reason) => {
        this.l.error(new Error('ERROR: lazy load IntradayModule failed'));
      }
    );
  }
}
