import { Inject, Injectable, NgZone } from '@angular/core';
import { Environment } from 'env/ienvironment';
import { ENV } from 'env/environment.provider';
import { LoggerService } from '@app/logger.service';
import { Actions, ofActionSuccessful, Store } from '@ngxs/store';
import { BehaviorSubject, fromEvent } from 'rxjs';
import { filter, first, switchMap, tap } from 'rxjs/operators';
import { MonitoringService } from '@shared/monitoring/monitoring.service';
import { E_004001_0, I_004001_0, COOKIE_VALUES } from './analytics-constants';
import { LazyLoadService } from '../utils/lazy-load.service';
import { CookieService } from '../services/cookie.service';
import { AuthActions } from '@app/_actions/auth.actions';
import { MetaState } from '@app/_state/meta.state';
import { AppState } from '@app/_state/app.state';
import { DOCUMENT } from '@angular/common';
import { ProfileState } from '@app/_state/profile.state';
import { ProfileActions } from '@app/_actions/profile.actions';

declare global {
  interface Window {
    utag: any;
    utag_cfg_ovrd: any;
    utag_condload: boolean;
    utag_data: any;
  }
}

@Injectable()
export class TealiumUtagService {
  script_src = '';
  excludedRoutesArray = ['callback', 'refresh', 'startimpsso'];
  installEvent: any;
  tealiumAccount = 'mtbank';
  tealiumUtagLibraryURL = `https://tags.tiqcdn.com/utag/${this.tealiumAccount}/${this.env.tealiumProfile}/${this.env.tealiumEnv}/utag.js`;
  tealiumMissedTrackCalls = [];
  tealiumMissedCallsCleanupComplete = new Event('tealiumMissedCallsCleanupComplete');
  tealiumMissedTrackCallsTimeout;
  credentialsAutoFillBiometricsUsedEvent$ = fromEvent(window, 'credentialsAutoFillBiometricsUsedEvent');
  showQualtricsMiniSurvey$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  // Typically set "noview" flag (no first page automatic view event) to true for Single Page Apps (SPAs)
  constructor(
    private logger: LoggerService,
    private _monitoringService: MonitoringService,
    private store: Store,
    private _ngZone: NgZone,
    private cookieService: CookieService,
    private actions$: Actions,
    @Inject(ENV) private env: Environment,
    @Inject(DOCUMENT) private document: Document
  ) {
    if (!this.env.enableTelemetry) {
      this.logger.info(`${I_004001_0.code}: ${I_004001_0.name} - ${I_004001_0.message}`);
    }
    // Set Configuration Overrides for Tealium
    window.utag_cfg_ovrd = {
      noload: !this.env.enableTelemetry,
      noview: true,
      dom_complete: true,
      cmcookiens: 'WTC_CONSENTMGR'
    };
    // Set initial Tealium Global Data
    window.utag_data = {
      ...window?.utag_data,
      sysEnv: this.env.tealiumEnv,
      pageName: `${this.env.appCode}:start`,
      cleanURL: window.location.hostname + window.location.pathname,
      fullURL: window.location.href,
      platform: this.env.appCode,
      authenticationState: 'logged-out',
      wtcUserId: '',
      appVersion: '',
      appType: '',
      customerType: '',
      isLandingPage: false
    };

    if (!this.env.impersonation) {
      this.store
        .select(MetaState.isInitialDataLoaded)
        .pipe(
          first((_) => _),
          switchMap(() => {
            return this.store.selectOnce(AppState);
          }),
          tap((state) => {
            this.view({
              appVersion: `WTC-${state.version}`,
              appType: state.type
            });
          })
        )
        .subscribe();

      this.actions$
        .pipe(ofActionSuccessful(AuthActions.OnAuthWidgetSuccess))
        .pipe(
          tap((_) => {
            let num = parseInt(this.cookieService.getCookie(COOKIE_VALUES.wtvisit));
            num = isNaN(num) ? 1 : num + 1;
            this.cookieService.setCookie(COOKIE_VALUES.wtvisit, num.toString());
          })
        )
        .subscribe();

      this.actions$
        .pipe(ofActionSuccessful(ProfileActions.SetProfile))
        .pipe(
          switchMap(() => {
            return this.store.select(ProfileState.profileId);
          }),

          first(),
          tap((profileId) => {
            const regex = /.*?([\w]+\.[\w]+)$/;
            const domain = this.document.defaultView.location.hostname.match(regex)?.[1];
            this.cookieService.setCookie(COOKIE_VALUES.wtcid, profileId, domain);
          })
        )
        .subscribe();
    }

    this.document.addEventListener('tealiumMissedCallsCleanupComplete', () => {
      clearTimeout(this.tealiumMissedTrackCallsTimeout);
    });
  }

  setupMiniSurveySubmitBtnListener() {
    if (this.env.impersonation || !this.env.enableTelemetry) return;
    this.store
      .select(MetaState.isInitialDataLoaded)
      .pipe(
        first((_) => _),
        switchMap(() => {
          return this.store.select(ProfileState.memberType);
        }),
        // tealium injects qualtrics script only if member = 1, so don't setup MutationObserver otherwise
        filter((memberType) => memberType === 1)
      )
      .subscribe((_) => {
        const observer = new MutationObserver(() => {
          const injectedButton = this.document.querySelector('.QSI__EmbeddedFeedbackContainer_TextButton');
          if (injectedButton) {
            observer.disconnect;
            injectedButton.addEventListener('click', () => {
              setTimeout(() => {
                this.showQualtricsMiniSurvey$.next(false);
              }, 15000);
            });
          }
        });
        observer.observe(this.document.querySelector('#qualtrics-placeholder'), { childList: true, subtree: true });
      });
  }

  // Generic script loader with callback
  getScript(callback?: Function): Promise<string | void> {
    if (!this.env.enableTelemetry || typeof this.tealiumUtagLibraryURL == 'undefined') {
      // analytics disabled or issue with URL, fail silently
      return;
    }

    return LazyLoadService.lazyloadScriptViaHTMLInsert(this.tealiumUtagLibraryURL)
      .then((script_loaded) => {
        if (this.tealiumMissedTrackCalls.length > 0 && window.utag && window.self === window.top) {
          // Tealium doesn't give a way to tell when all the dependant tags are loaded so we need to use a timeout :(
          // Plus we can use the visibilitychange or beforeunload events because of mobile support/Ping iFrame https://developer.mozilla.org/en-US/docs/Web/API/Navigator/sendBeacon#examples
          this.tealiumMissedTrackCallsTimeout = setTimeout(() => {
            this.tealiumMissedTrackCalls.forEach((tealiumCall, index) => {
              window.utag.track(tealiumCall.tealium_event, tealiumCall.data);
              this.tealiumMissedTrackCalls.splice(index, 1);
            });
            this.document.dispatchEvent(this.tealiumMissedCallsCleanupComplete);
          }, 500);
        }

        if (script_loaded && callback) {
          callback();
        }
      })
      .catch((_) => {
        this._monitoringService.sendErrorReport(E_004001_0.code, E_004001_0.name, E_004001_0.message);
      });
  }

  // Data layer is optional set of key/value pairs
  track(tealium_event: string, data?: any) {
    this._ngZone.runOutsideAngular(() => {
      if (this.env.enableTelemetry && window.self === window.top) {
        if (window.utag) {
          window.utag.track(tealium_event, data);
        } else if (
          window.utag === undefined &&
          !this.document.querySelector(`script[src="${this.tealiumUtagLibraryURL}"]`)
        ) {
          // catch missed tracking calls to fire once the dependant tags have loaded
          this.tealiumMissedTrackCalls.push({
            tealium_event,
            data
          });
        }
      }
    });
  }

  view(data?: any) {
    this.track('view', data);
  }

  link(data?: any) {
    this.track('link', data);
  }
}
