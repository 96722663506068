import { Profile } from "@app/shared/models/profile.model";


export namespace ProfileActions {
  export class FetchProfile {
    static readonly type = '[Profile] Fetch Profile';
    constructor() {}
  }

  export class SetProfile {
    static readonly type = '[Profile] Set Profile';
    constructor(public profileData: Profile) {}
  }
}
