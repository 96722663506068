<div class="u-flex-container u-flex-dir--column u-flex-align--center-middle u-flex-wrap--wrap error-handler">
  <ng-container [ngSwitch]="errorType">
    <ng-container *ngSwitchCase="'access_denied'">
      <a [href]="originURL" id="backToOriginLink" class="c-link">
        <img alt="back" src="assets/images/icons/arrow-back.svg" loading="lazy" />
        Back to Wilmington Trust
      </a>
      <div class="c-panel u-type--center l-margin-vertical--xxlarge l-padding--xlarge error-handler__message">
        <h1 class="u-type--xxlarge u-type--center l-margin-bottom--xlarge error-handler__heading">
          Trouble Logging In?
        </h1>
        <p class="l-margin-bottom--xlarge error-handler__paragraph">
          Regain access to your Wilmingtontrust.com Online Account in just a few steps.
        </p>
        <h2 class="u-type--medium error-handler__subheading">Forgot your User ID</h2>
        <p class="l-margin-top--small error-handler__paragraph">
          <a
            [href]="idRecoveryURL"
            class="c-link error-handler__link error-handler__link--has-icon error-handler__link--space-between-icon"
          >
            Help us find your account here
            <img
              alt="right"
              class="error-handler__link-icon error-handler__link-icon--orange-button-right"
              src="assets/images/icons/right-arrow-white.svg"
              loading="lazy"
            />
          </a>
        </p>
        <hr class="l-margin-vertical--large" />
        <h2 class="u-type--medium error-handler__subheading">Forgot your Password</h2>
        <p class="l-margin-top--small error-handler__paragraph">
          <a
            [href]="passwordResetURL"
            class="c-link error-handler__link error-handler__link--has-icon error-handler__link--space-between-icon"
          >
            Reset your password in just a few steps
            <img
              alt="right"
              class="error-handler__link-icon error-handler__link-icon--orange-button-right"
              src="assets/images/icons/right-arrow-white.svg"
              loading="lazy"
            />
          </a>
        </p>
        <hr class="l-margin-vertical--large" />
        <p class="l-margin-vertical--xlarge error-handler__paragraph">
          For assistance call Premier Services M-F 8 AM - 5 PM ET at
          <a class="c-link error-handler__link" [href]="clientSupportServicesPhoneNumber.href">{{
            clientSupportServicesPhoneNumber.text
          }}</a>
          or M&T Online Support M-F 5 PM - 9 PM , Sat-Sun 9 AM - 5 PM at
          <a class="c-link error-handler__link" href="tel:+18007242440">800-724-2440</a>, or email your question to
          <a href="mailto:olpsupport@wilmingtontrust.com?subject=Trouble Logging In">olpsupport@wilmingtontrust.com</a>
        </p>
        <a [routerLink]="['/login']" class="u-type--medium c-link error-handler__link">Cancel</a>
      </div>
    </ng-container>

    <ng-container *ngSwitchCase="'server_error'">
      <div class="c-panel u-type--center l-margin-vertical--xxlarge">
        <h1 class="u-type--bold u-type--center l-margin-bottom--xxlarge">Internal Server Error</h1>
        <p class="l-margin-top--medium l-margin-bottom--xxlarge">
          Please contact Premier Services at
          <a [href]="clientSupportServicesPhoneNumber.href">{{ clientSupportServicesPhoneNumber.text }}</a
          >.
        </p>
        <button class="c-button c-button--primary l-padding-horizontal--xxlarge" (click)="handleDashboardClick()">
          <span class="c-button-focus">OK</span>
        </button>
      </div>
    </ng-container>

    <ng-container *ngSwitchCase="'gateway_timeout'">
      <div class="c-panel u-type--center l-margin-vertical--xxlarge l-padding--xlarge error-handler__message">
        <h1 class="u-type--bold u-type--center l-margin-bottom--xxlarge">Error</h1>
        <p class="l-margin-top--medium l-margin-bottom--xxlarge">
          We've identified a problem that is preventing you from completing this action. If this continues to be a
          problem, please call Premier Services M-F 8 AM - 5 PM ET at
          <a [href]="clientSupportServicesPhoneNumber.href">{{ clientSupportServicesPhoneNumber.text }}</a>
          or email your question to
          <a href="mailto:olpsupport@wilmingtontrust.com?subject=Wilmingtrust.com Error"
            >olpsupport@wilmingtontrust.com</a
          >
        </p>
      </div>
    </ng-container>

    <ng-container *ngSwitchCase="'timeout'">
      <div class="c-panel u-type--center l-margin-vertical--xxlarge">
        <div class="l-flex-row u-flex-align--center-middle">
          <div class="l-padding-horizontal--small">
            <svg xmlns="http://www.w3.org/2000/svg" width="41" height="41">
              <title>Error</title>
              <path
                fill="#DD012C"
                d="M22.178 26.263c0 .92-.762 1.682-1.682 1.682a1.702 1.702 0 01-1.682-1.682c0-.92.775-1.681 1.682-1.681.92 0 1.682.762 1.682 1.681zm-2.641-14.412h1.918l-.237 10.576h-1.445l-.236-10.576zM32.03 30.113l1.59-2.076L21.861 7.043h-2.64L7.41 28.037l1.563 2.076H32.03zM22.9 5.125l12.98 23.056-2.89 3.85H8.002l-2.877-3.85L18.092 5.125H22.9z"
              />
            </svg>
          </div>
          <h1 class="u-type--bold">Page Load Error</h1>
        </div>
        <p
          class="l-padding--large u-border-top--primary-light u-type--medium u-type--primary-base u-type--normal u-type--xs-center u-type--sm-left"
        >
          We apologize for the inconvenience. The page you have requested was not able to load. If you have any
          questions about your accounts, please contact your Relationship Team or
          <a [href]="clientSupportServicesPhoneNumber.href">{{ clientSupportServicesPhoneNumber.text }}</a>
        </p>
        <button class="c-button c-button--primary l-padding-horizontal--xxlarge" (click)="handleDashboardClick()">
          <span class="c-button-focus">Go to Dashboard</span>
        </button>
      </div>
    </ng-container>

    <ng-container *ngSwitchCase="'other'">
      <div class="c-panel u-type--center l-margin-vertical--xxlarge l-padding--xlarge error-handler__message">
        <div class="l-flex-row u-flex-align--center-middle">
          <div class="l-padding-horizontal--small">
            <svg xmlns="http://www.w3.org/2000/svg" width="41" height="41">
              <title>Error</title>
              <path
                fill="#DD012C"
                d="M22.178 26.263c0 .92-.762 1.682-1.682 1.682a1.702 1.702 0 01-1.682-1.682c0-.92.775-1.681 1.682-1.681.92 0 1.682.762 1.682 1.681zm-2.641-14.412h1.918l-.237 10.576h-1.445l-.236-10.576zM32.03 30.113l1.59-2.076L21.861 7.043h-2.64L7.41 28.037l1.563 2.076H32.03zM22.9 5.125l12.98 23.056-2.89 3.85H8.002l-2.877-3.85L18.092 5.125H22.9z"
              />
            </svg>
          </div>
          <h1 class="u-type--bold">Error</h1>
        </div>
        <p
          class="l-padding--large u-border-top--primary-light u-type--medium u-type--primary-base u-type--normal u-type--xs-center u-type--sm-left"
        >
          We apologize for the inconvenience. The page you requested was not able to load. If this continues to be a
          problem, please call Premier Services M-F 8 AM - 5 PM ET at
          <a [href]="clientSupportServicesPhoneNumber.href">{{ clientSupportServicesPhoneNumber.text }}</a>
          or email your question to
          <a href="mailto:olpsupport@wilmingtontrust.com?subject=Generic Error WilmingtonTrust.com"
            >olpsupport@wilmingtontrust.com</a
          >
        </p>
      </div>
    </ng-container>

    <ng-container *ngSwitchCase="'lpl_sso_error'">
      <div class="c-panel u-type--center l-margin-vertical--xxlarge">
        <h1 class="u-type--bold u-type--center l-margin-bottom--xxlarge">Error</h1>
        <p class="l-margin-top--medium l-margin-bottom--xxlarge">
          We're unable to log you in to LPL Account Services. If this error persists please contact Premier Services at
          <a [href]="clientSupportServicesPhoneNumber.href">{{ clientSupportServicesPhoneNumber.text }}</a
          >.
        </p>
        <button class="c-button c-button--primary l-padding-horizontal--xxlarge" (click)="handleDashboardClick()">
          <span class="c-button-focus">Go to Dashboard</span>
        </button>
      </div>
    </ng-container>

    <ng-container *ngSwitchCase="'wt2-refresh'">
      <div class="c-panel u-type--center l-margin-vertical--xxlarge l-padding--xlarge error-handler__message">
        <div class="l-flex-row u-flex-align--center-middle">
          <div class="l-padding-horizontal--small">
            <svg xmlns="http://www.w3.org/2000/svg" width="41" height="41">
              <title>Error</title>
              <path
                fill="#DD012C"
                d="M22.178 26.263c0 .92-.762 1.682-1.682 1.682a1.702 1.702 0 01-1.682-1.682c0-.92.775-1.681 1.682-1.681.92 0 1.682.762 1.682 1.681zm-2.641-14.412h1.918l-.237 10.576h-1.445l-.236-10.576zM32.03 30.113l1.59-2.076L21.861 7.043h-2.64L7.41 28.037l1.563 2.076H32.03zM22.9 5.125l12.98 23.056-2.89 3.85H8.002l-2.877-3.85L18.092 5.125H22.9z"
              />
            </svg>
          </div>
          <h1 class="u-type--bold">Error</h1>
        </div>
        <p
          class="l-padding--large u-border-top--primary-light u-type--medium u-type--primary-base u-type--normal u-type--xs-center u-type--sm-left"
        >
          Refreshing this window is not an option. Please close this window or tab and return to AdvisorONE to open a
          new impersonation session.
        </p>
      </div>
    </ng-container>

    <ng-container *ngSwitchDefault>
      <div class="c-panel u-type--center l-margin-vertical--xxlarge">
        <h1 class="u-type--bold u-type--center l-margin-bottom--xxlarge">Page Not Found</h1>
        <p class="l-margin-top--medium l-margin-bottom--xxlarge">
          If you need assistance, please contact Premier Services at
          <a [href]="clientSupportServicesPhoneNumber.href">{{ clientSupportServicesPhoneNumber.text }}</a
          >.
        </p>
        <button class="c-button c-button--primary l-padding-horizontal--xxlarge" (click)="handleDashboardClick()">
          <span class="c-button-focus">Go to Dashboard</span>
        </button>
      </div>
    </ng-container>
  </ng-container>
</div>
