import { NgModule } from '@angular/core';
import { Routes, RouterModule, UrlSegment, TitleStrategy } from '@angular/router';
import { ErrorHandlerComponent } from './error-handler/error-handler.component';

import { SsoComponent } from './sso/sso.component';
import { LegalDisclosuresGuard } from './legal/legal-disclosures/legal-disclosures.guard';
import { PageTitlesService as PageTitlesStrategy } from './page-titles.service';
import { environment } from '@env/local/environment';
import { AccountHoldingsOverviewResolverService } from './account/account-holdings-overview/account-holdings-overview-resolver.service';
import {
  accountStateGuard,
  authGuard,
  clearAccountStateGuard,
  clearHoldingsByTimePeriodGuard,
  consolidatedHoldingsCanLoadGuard,
  firstTimeLoginGuard,
  loginNotNeededGuard,
  transferMoneycanLoadGuard
} from './guards/guards';
import { RouteCanLoadGuard } from './guards/route-can-load.guard';

const routes: Routes = [
  {
    path: 'account',
    canActivate: [
      authGuard(),
      LegalDisclosuresGuard,
      firstTimeLoginGuard(),
      accountStateGuard(),
      clearHoldingsByTimePeriodGuard()
    ],
    loadChildren: () => import('./account/account.module').then((m) => m.AccountModule),
    title: 'Account Overview'
  },
  {
    path: 'dashboard',
    canActivate: [authGuard(), LegalDisclosuresGuard, firstTimeLoginGuard()],
    loadChildren: () => import('./dashboard/dashboard.module').then((m) => m.DashboardModule),
    title: 'Dashboard'
  },
  {
    path: 'total-picture',
    canLoad: [RouteCanLoadGuard],
    canActivate: [authGuard(), LegalDisclosuresGuard, firstTimeLoginGuard()],
    loadChildren: () => import('./aggregation/aggregation.module').then((m) => m.AggregationModule),
    title: 'Total Picture'
  },
  {
    path: 'documents-container',
    canActivate: [authGuard(), LegalDisclosuresGuard, firstTimeLoginGuard(), clearAccountStateGuard()],
    loadChildren: () =>
      import('./documents/documents-container/documents-container.module').then((m) => m.DocumentsContainerModule),
    title: 'Documents'
  },
  {
    path: 'documents',
    canActivate: [authGuard(), LegalDisclosuresGuard, firstTimeLoginGuard(), clearAccountStateGuard()],
    loadChildren: () => import('./documents/documents.module').then((m) => m.DocumentsModule),
    title: 'Documents'
  },
  { path: 'error-handler', component: ErrorHandlerComponent, title: 'Error Handler' },
  {
    path: 'legal',
    canLoad: [authGuard()],
    loadChildren: () => import('./legal/legal-module').then((m) => m.LegalModule),
    data: {
      showFooter: false
    },
    title: 'Legal Disclosures'
  },
  {
    path: 'login',
    canActivate: [loginNotNeededGuard()],
    loadChildren: () => import('./login/login.module').then((m) => m.LoginModule),
    title: 'Login'
  },
  {
    path: 'passthrough',
    pathMatch: 'full',
    redirectTo: 'login'
  },
  {
    path: 'performance',
    canActivate: [authGuard(), LegalDisclosuresGuard, firstTimeLoginGuard()],
    loadChildren: () => import('./performance/performance.module').then((m) => m.PerformanceModule),
    title: 'Performance'
  },
  {
    path: 'profile',
    canActivate: [authGuard(), LegalDisclosuresGuard],
    loadChildren: () => import('./profile/profile.module').then((m) => m.ProfileModule),
    title: 'Profile'
  },
  {
    path: 'reporting',
    canLoad: [RouteCanLoadGuard],
    canActivate: [authGuard(), LegalDisclosuresGuard, firstTimeLoginGuard()],
    loadChildren: () => import('./reporting/reporting.module').then((m) => m.ReportingModule),
    title: 'Reporting'
  },
  { path: 'startimpsso', component: SsoComponent },
  {
    path: 'transfer',
    canLoad: [transferMoneycanLoadGuard()],
    canActivate: [authGuard(), LegalDisclosuresGuard, firstTimeLoginGuard()],
    loadChildren: () => import('@app/transfer-money/transfer-money.module').then((m) => m.TransferMoneyModule)
  },
  ...(environment.enableFeature_consolidatedHoldings
    ? [
        {
          path: 'holdings',
          canLoad: [consolidatedHoldingsCanLoadGuard()],
          canActivate: [authGuard(), LegalDisclosuresGuard, firstTimeLoginGuard(), clearHoldingsByTimePeriodGuard()],
          loadChildren: () =>
            import('./account/account-holdings-overview/account-holdings-overview.module').then(
              (m) => m.AccountHoldingsOverviewModule
            ),
          title: 'Holdings',
          resolve: {
            response: AccountHoldingsOverviewResolverService
          }
        }
      ]
    : [])
];

const fallbackDefaultRoutes: Routes = [
  {
    pathMatch: 'full',
    matcher: (url: UrlSegment[]) => {
      if (url.length === 0 || (url.length === 1 && url[0].path.includes('index.html'))) {
        return { consumed: url };
      } else {
        return null;
      }
    },
    redirectTo: 'login'
  },
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full'
  },
  { path: '**', redirectTo: '/error-handler?error=404', pathMatch: 'full' }
];

function generateRoutes(): Routes {
  return [...routes, ...fallbackDefaultRoutes];
}

@NgModule({
  imports: [
    RouterModule.forRoot(generateRoutes(), {
      scrollPositionRestoration: 'enabled',
      anchorScrolling: 'enabled'
      // enableTracing: true
    })
  ],
  exports: [RouterModule],
  providers: [
    {
      provide: TitleStrategy,
      useClass: PageTitlesStrategy
    }
  ]
})
export class AppRoutingModule {}
