import { LegalDisclosure } from '@app/shared/models/legal-disclosures.model';
export namespace LegalActions {
  export class FetchDisclosures {
    static readonly type = '[Legal] Fetch and Set Active Disclosures';
    constructor() {}
  }

  export class UpdateDisclosures {
    static readonly type = '[Legal] POST Updates to Disclosures';
    constructor(public disclosures: LegalDisclosure[]) {}
  }
}
