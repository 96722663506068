import { ChangeDetectionStrategy, Component, ElementRef, ViewChild } from '@angular/core';
import { AuthActions } from '@app/_actions/auth.actions';
import { Store } from '@ngxs/store';

@Component({
  selector: 'app-sign-out',
  templateUrl: './sign-out.component.html',
  styleUrls: ['./sign-out.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SignOutComponent {
  @ViewChild('signOutDialog', {read: ElementRef<HTMLDialogElement>, static: true})
  private dialog: ElementRef<HTMLDialogElement>;

  constructor(private store: Store) { }

  ngOnInit(){
  }

  confirmSignOut() {
    this.store.dispatch(new AuthActions.SignOff()).subscribe(() => this.closeThisDialog())
  }

  public open(){
    this.dialog.nativeElement.showModal();
  }

  public closeThisDialog() {
    this.dialog.nativeElement.close();
  }



}
