import { Injectable } from '@angular/core';
import { ClientAccountService } from '@app/client-account.service';
import { LoggerService } from '@app/logger.service';
import {
  ClientAccounts,
  ClientAccountsAccountGroup,
  ClientAccountsRoot,
  ClientAccountsSub,
  ClientAccountsSubAccounts
} from '@app/shared/models/client-accounts.model';
import { GlobalConstants } from '@app/shared/services/globalConstants';
import { AccountsActions } from '@app/_actions/accounts.actions';
import { Action, createSelector, Selector, State, StateContext, Store } from '@ngxs/store';
import { switchMap } from 'rxjs/operators';
import { sortAccounts, sortGroups, sortSections } from '@app/shared/utils/sorting-utils';
import { GlobalFormatterService } from '@app/shared/services/global-formatter.service';
import { AppActions } from '@app/_actions/app.actions';
import { ChartsWrapperService } from '@app/shared/services/charts-wrapper.service';
import { AccountSnapshot } from '@app/documents/models/account-snapshot.model';

export interface AccountsStateModel {
  clientAccounts: ClientAccounts;
  currentClientAccountState: CurrentClientAccountState;
  accountIDsAfterFilter: string[];
}

export interface CurrentClientAccountState {
  group: ClientAccountsAccountGroup | null;
  account: ClientAccountsSubAccounts | null;
}

export interface EntitlementMeta {
  name: string;
  serviceCode: string;
  serviceLinkTitle: string;
  serviceLinkDesc: string;
  allowQuickLinks: boolean;
}

const defaultState: AccountsStateModel = {
  clientAccounts: null,
  currentClientAccountState: { group: null, account: null },
  accountIDsAfterFilter: []
};

@State<AccountsStateModel>({
  name: 'accounts',
  defaults: defaultState
})
@Injectable()
export class AccountsState {
  constructor(
    private logger: LoggerService,
    private accountsService: ClientAccountService,
    private store: Store,
    private chartsService: ChartsWrapperService
  ) {}

  //Action Hanlders
  @Action([AppActions.ResetState])
  onResetState(ctx: StateContext<AccountsStateModel>, action: AppActions.ResetState) {
    ctx.setState(defaultState);
    return Promise.resolve();
  }

  @Action(AccountsActions.Fetch, { cancelUncompleted: true })
  onFetch(ctx: StateContext<AccountsStateModel>, action: AccountsActions.Fetch) {
    return this.accountsService.fetchClientAccounts().pipe(
      switchMap((_clientAccounts) => {
        return ctx.dispatch(new AccountsActions.SetClientAccountsData(_clientAccounts));
      })
    );
  }

  @Action(AccountsActions.SetClientAccountsData)
  onSetClientAccountsData(ctx: StateContext<AccountsStateModel>, action: AccountsActions.SetClientAccountsData) {
    function cleanAccountData(ca: ClientAccounts): ClientAccounts {
      if (!ca) {
        return null;
      }
      return {
        ...ca,
        accountSections: sortSections([
          ...ca.accountSections.map((section) => {
            return {
              ...section,
              subsections: [
                ...section.subsections.map((subsection) => {
                  let subsec = {
                    ...subsection,
                    accounts: [
                      ...(subsection.accounts
                        ? subsection.accounts.map((account) => {
                            if (account.accountType === 'SBL') {
                              return {
                                ...account,
                                acctId: account.acctId.substring(account.acctId.length - 10)
                              };
                            } else {
                              return {
                                ...account
                              };
                            }
                          })
                        : [])
                    ]
                  };
                  subsec.accounts = sortAccounts(subsec.accounts);
                  if (subsection.acctGroups?.length > 0) {
                    subsec.acctGroups = sortGroups([
                      ...subsection.acctGroups.map((group) => {
                        return { ...group, acctList: sortAccounts(group.acctList)};
                      })
                    ]);
                  }

                  return subsec;
                })
              ]
            };
          })
        ])
      };
    }

    ctx.patchState({ clientAccounts: cleanAccountData(action.data) });
    return Promise.resolve();
  }

  @Action(AccountsActions.SetCurrentClientAccountState)
  onSetCurrentClientAccountState(
    ctx: StateContext<AccountsStateModel>,
    action: AccountsActions.SetCurrentClientAccountState
  ) {
    this.chartsService.removeAllActiveElementsCharts();

    //falsy values are interpreted as commands to clear the currentClientAccountState
    if (!action.selected) {
      ctx.patchState({
        currentClientAccountState: {
          group: null,
          account: null
        }
      });
    }

    ctx.patchState({
      currentClientAccountState: {
        ...ctx.getState().currentClientAccountState,
        ...action.selected
      }
    });
    return Promise.resolve();
  }

  @Action(AccountsActions.SetAccountIDsAfterFilter)
  onSetGlobalFilterState(ctx: StateContext<AccountsStateModel>, action: AccountsActions.SetAccountIDsAfterFilter) {
    ctx.patchState({
      accountIDsAfterFilter: action.accountIDsAfterFilter
    });
    return Promise.resolve();
  }

  //Memoized Selectors
  @Selector([AccountsState])
  static currentClientAccountState(state: AccountsStateModel) {
    return state?.currentClientAccountState;
  }

  @Selector([AccountsState])
  static clientAccounts(state: AccountsStateModel) {
    return state?.clientAccounts;
  }

  @Selector([AccountsState])
  static wealthAccountIds(state: AccountsStateModel): string[] {
    let acctIds = [];
    try {
      acctIds = state?.clientAccounts?.accountSections
        .find((sec) => {
          return sec.serviceCode === GlobalConstants.servicesDictionary.wealth.code;
        })
        .subsections.reduce((acc, subSect) => {
          if (subSect.accounts) {
            acc.push(...subSect?.accounts.map((acct) => acct.acctId));
          }
          if (subSect.acctGroups) {
            acc.push(
              ...subSect?.acctGroups.reduce((_acc, g) => {
                _acc.push(...g.acctList.map((acct) => acct.acctId));
                return _acc;
              }, [] as string[])
            );
          }
          return acc;
        }, [] as string[]);
    } catch {
      acctIds = [];
    }

    return acctIds;
  }

  @Selector([AccountsState])
  static wealthAccountNamesAndIds(state: AccountsStateModel): AccountSnapshot[] {
    let acctNamesAndIds = [];
    try {
      acctNamesAndIds = state?.clientAccounts?.accountSections
        .find((sec) => sec.serviceCode === GlobalConstants.servicesDictionary.wealth.code)
        .subsections.reduce((acc: AccountSnapshot[], subSect) => {
          if (subSect.accounts) {
            acc.push(...subSect?.accounts.map((acct) => {
              return { name: acct.accountNickName, id: acct.acctId }
            }));
          }
          if (subSect.acctGroups) {
            acc.push(
              ...subSect?.acctGroups.reduce((_acc: AccountSnapshot[], g) => {
                _acc.push(...g.acctList.map((acct) => {
                  return { name: acct.accountNickName, id: acct.acctId }
                }));
                return _acc;
              }, [] as AccountSnapshot[])
            );
          }
          return acc;
        }, [] as AccountSnapshot[]);
    } catch {
      acctNamesAndIds = [];
    }

    return acctNamesAndIds;
  }



  @Selector([AccountsState])
  static accountIdsByCurrentAccountState(state: AccountsStateModel): string[] {
    const s = state?.currentClientAccountState;
    if (!s) {
      return [];
    } else if (s.account) {
      return [s.account.acctId];
    } else if (s.group) {
      return s.group.acctList.map((ac) => ac.acctId);
    }
  }

  @Selector([AccountsState])
  static asOfDateByCurrentAccountState(state: AccountsStateModel): string {
    const s = state.currentClientAccountState;
    if (!s) {
      return '';
    } else if (s.account) {
      return GlobalFormatterService.date(s.account.dateOfData);
    } else if (s.group) {
      return GlobalFormatterService.date(s.group.acctList[0]?.dateOfData);
    }
  }

  @Selector([AccountsState, AccountsState.currentClientAccountState])
  static accountIDsAfterFilter(state: AccountsStateModel) {
    return state?.accountIDsAfterFilter;
  }

  static hasAccounts(type: string = 'any') {
    return createSelector([AccountsState], (state: AccountsStateModel) => {
      function checkIfHasAccounts(accounts: ClientAccounts, accountType: string): boolean {
        if (!accounts) {
          return false;
        }
        const list = accounts.accountSections;
        const len = list.length;
        for (let i = 0; i < len; i++) {
          if (
            accountType === 'any' &&
            list[i].accounts &&
            list[i].subsections?.find(
              (subsec) => subsec?.accounts?.length > 0 || (subsec?.acctGroups && subsec?.acctGroups?.length > 0)
            )
          ) {
            return true;
          } else if (
            list[i].serviceCode === accountType &&
            list[i].accounts &&
            list[i].subsections?.find(
              (subsec) => subsec?.accounts?.length > 0 || (subsec?.acctGroups && subsec?.acctGroups?.length > 0)
            )
          ) {
            return true;
          }
        }
        return false;
      }
      return checkIfHasAccounts(state?.clientAccounts, type);
    });
  }

  static currentEntitlments() {
    return createSelector([AccountsState], (state: AccountsStateModel) => {
      const entitlements = state?.clientAccounts?.accountSections.map((sec: ClientAccountsRoot) => {
        return {
          name: sec.name,
          serviceCode: sec.serviceCode,
          serviceLinkDesc: sec.mainlinkdesc,
          serviceLinkTitle: sec.mainlinktitle,
          allowQuickLinks: sec.allowQuickLinks
        } as EntitlementMeta;
      });
      return entitlements;
    });
  }

  static currentEntitlementsCodes() {
    return createSelector([AccountsState], (state: AccountsStateModel) => {
      const entitlements = state.clientAccounts?.accountSections?.map((_) => _.serviceCode);
      return entitlements;
    });
  }

  static hasEntitlement(entitlementCode: string) {
    return createSelector([AccountsState], (state: AccountsStateModel) => {
      const currentEntitlmentsCodes = state.clientAccounts?.accountSections?.map((_) => _.serviceCode);
      return currentEntitlmentsCodes?.includes(entitlementCode);
    })
  }
}
