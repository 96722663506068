import { AggAccount } from '@app/aggregation/_models/AggFinSummary';

export namespace YdlActions {
  export class FetchYdlAuth {
    static readonly type = '[YDL] Fetch YdlAuth';
    constructor() {}
  }

  export class DeleteYdlAuth {
    static readonly type = '[YDL] Delete YdlAuth';
    constructor() {}
  }

  export class RegisterYdlService {
    static readonly type = '[YDL] POST Register Ydl Service, if needed';
    constructor() {}
  }

  export class OpenMarketingModal {
    static readonly type = '[YDL] Open Marketing Modal';
    constructor() {}
  }

  export class LoadFastLinkJs {
    static readonly type = '[YDL] Load Fastlink.js, if needed';
    constructor() {}
  }

  export class OpenFastlinkModal {
    static readonly type = '[YDL] Open Fastlink Modal';
    constructor(public flow?: string, public aggAccount?: AggAccount) {}
  }

  export class OpenDisclosureModal {
    static readonly type = '[YDL] Open Disclosure Modal';
    constructor() {}
  }
}
