import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { RouterStateSnapshot, TitleStrategy } from '@angular/router';
import { GlobalConstants } from './shared/services/globalConstants';

@Injectable({
  providedIn: 'root'
})
export class PageTitlesService extends TitleStrategy {

  constructor(private readonly title: Title) {
    super();
  }

  override updateTitle(routerState: RouterStateSnapshot) {
    const title = this.buildTitle(routerState);
    if (title) {
      this.title.setTitle(`${title} ${GlobalConstants.pageTitleAppend}`);
    }
  }
}
