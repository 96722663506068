/**
 * The phone number used to contact Wilmington Trust client suppot services.
 * @const {Object}
 * @property {string} text - The interpolation value to use when showing the client suppot services phone number in angular html templates.
 * @property {string} href - The client suppot services value for making the phone number click to call in an anchor tag.
 * @example
 * <a [href]="clientSupportServicesPhoneNumber.href">{{clientSupportServicesPhoneNumber.text}}</a>
 * */
export const clientSupportServicesPhoneNumber = {
  text: '1-800-982-4620',
  href: 'tel:+18009824620'
};

export const premierServicesPhoneNumber = {
  text: '1-877-836-9206',
  href: 'tel:+18778369206'
};

export const mAndTSecuritiesIncPhoneNumber = {
  text: '1-800-724-7788',
  href: 'tel:+18007247788'
};

export const phoneNumbers = {
  clientSupportServices: clientSupportServicesPhoneNumber,
  premierServices: premierServicesPhoneNumber,
  mAndTSecuritiesInc: mAndTSecuritiesIncPhoneNumber
};
