<div cdkTrapFocus [cdkTrapFocusAutoCapture]="true" class="c-modal__container c-modal__container--medium u-bg--white">
  <div class="c-modal__header l-padding--large u-bg--primary-lightest">
    <h1 class="u-type--primary-base u-type--xxlarge">Terms and Conditions</h1>
    <div class="c-modal__close-icon">
      <button (click)="requestClose()" class="c-modal__close-icon-button" aria-label="close">
        <span class="c-icon--large" aria-hidden="true">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" role="img" aria-label="Close Window">
            <title>Close Window Icon</title>
            <path fill="#425968"
              d="M20 4.86L12.86 12 20 19.14l-.86.86L12 12.86 4.86 20 4 19.14 11.14 12 4 4.86 4.86 4 12 11.14 19.14 4z">
            </path>
          </svg>
        </span>
      </button>
    </div>
  </div>
  <div class="content-box">
    <div class="scroll-container l-margin-bottom--xlarge">
      <p class="u-type--xsmall l-padding-bottom--medium">
        The <i>Total Picture</i> Terms and Conditions (the “Terms and Conditions”) govern your use of <i>Total
          Picture</i>, a
        data aggregation service provided by Yodlee, Inc. that enables you to retrieve and view information
        about your designated accounts as described herein (the “Service”). As used in the Terms and
        Conditions, Wilmington Trust means the entity making the service available to you, whether Wilmington
        Trust, N.A., Wilmington Trust Company, M&T Bank, or any parent, affiliate or subsidiary thereof (“us,”
        “we” or “our”). The Service is made available to you as part of Wilmington Trust's Digital Services. The
        Wilmington Trust Digital Services Agreement (“DSA”) is incorporated by reference and made part of the
        Terms and Conditions.
      </p>
      <div class="u-display--flex">
        <span class="l-padding-right--xlarge u-type--xsmall">1.</span>
        <div>
          <p class="u-type--xsmall l-padding-bottom--medium">
            <strong>Overview of the Service. </strong>
            Through the Service, you are able to view information about your
            Wilmington Trust accounts and third party accounts (“Third-Party Accounts”), for which you
            have access to via various third party websites. By accessing and using the Service, you
            acknowledge that you have read, understand, and agree to the Terms and Conditions, as may be
            amended from time to time. We may add, change, discontinue or terminate any part or all of
            the Service at any time in our discretion and without prior notice to you. You may not access or
            use any part of the Service which has been discontinued or terminated.
          </p>
        </div>
      </div>
      <div class="u-display--flex">
        <span class="l-padding-right--xlarge u-type--xsmall">2.</span>
        <div>
          <p class="u-type--xsmall l-padding-bottom--medium">
            <strong>Information about the Service. </strong>
            We entered into an agreement with Yodlee, Inc., a third party
            technology and data aggregation service provider (“Provider”), to provide the Service to you. In
            order to do so, you are required to have online access for your Third Party Accounts and provide
            authorization to retrieve, display and use information with respect to your Third Party Accounts
            (“Third Party Account Information”). The Provider retrieves and consolidates your Third Party
            Account Information which is stored on or available through third-party websites (“Third-Party
            Sites”) and make that information available to us and you, which may require the Provider to
            use your Wilmington Trust account information and your Third Party Account Information.
            Third Party Account Information may also include any information entered or uploaded by you
            into <i>Total Picture</i> (collectively, your "Third Party Account Information").
          </p>
        </div>
      </div>
      <div class="u-display--flex">
        <span class="l-padding-right--xlarge u-type--xsmall">3.</span>
        <div>
          <p class="u-type--xsmall l-padding-bottom--medium">
            <strong>Content You Provide. </strong>
            You are licensing to us and our service providers, including the Provider
            and its legal affiliates, any information, data, username(s), password(s), personal information or
            other content you provide through or to the Service (collectively, “Content”). We and the Provider
            may retrieve, use, access, modify, display, distribute and create new material using such Content
            to provide the Service to you. By submitting Content, you automatically agree, or promise that
            the owner of such Content has expressly agreed that, without any particular time limit, and
            without the payment of any fees, we and the Provider may use the Content for the purposes set
            out above. As between us and the Provider, we own your Wilmington Trust confidential account
            information. You authorize and grant the Provider and us the right and permission to retrieve,
            display and use the Third Party Account Information to provide the Service to you.
          </p>
        </div>
      </div>
      <div class="u-display--flex">
        <span class="l-padding-right--xlarge u-type--xsmall">4.</span>
        <div>
          <p class="u-type--xsmall l-padding-bottom--medium">
            <strong>Proprietary Rights. </strong>
            You are permitted to use content delivered to you through the Service only
            in connection with your use of the Service. You may not copy, reproduce, distribute, or create
            derivative works from this content. Further, you agree not to reverse engineer or reverse compile
            any of the service technology, including but not limited to, any Java applets associated with the
            service.
          </p>
        </div>
      </div>
      <div class="u-display--flex">
        <span class="l-padding-right--xlarge u-type--xsmall">5.</span>
        <div>
          <p class="u-type--xsmall l-padding-bottom--medium">
            <strong>Power of Attorney/Third Party Accounts. </strong>
            By using the service, you authorize the Provider to
            access Third Party Sites designated by you, on your behalf, and to register for accounts requested
            by you and aggregate your Third Party Account Information. For all purposes hereof, you grant
            the Provider a limited power of attorney, and you appoint Provider as your true and lawful
            attorney-in-fact and agent, with full power of substitution and re-substitution, for you and in your
            name, place and stead, in any and all capacities, to access Third Party Sites, servers or documents,
            retrieve information, including Third Party Account Information and use such information, all as
            described above, with the full power and authority to do and perform each and every act and
            thing requisite and necessary to be done in connection with such activities, as fully to all intents
            and purposes as you might or could do in person.
          </p>
          <p class="u-type--xsmall l-padding-bottom--medium">
            YOU ACKNOWLEDGE AND AGREE THAT WHEN THE PROVIDER ACCESSES AND RETRIEVES
            INFORMATION FROM THIRD PARTY SITES, THE PROVIDER IS ACTING AS YOUR AGENT, AND NOT
            ON BEHALF OF THE THIRD PARTY. You agree that third party account providers may rely on the
            foregoing authorization, agency and power of attorney granted by you. You understand and agree
            that the Service is not endorsed or sponsored by any third party account providers accessible
            through the Service. We do not sponsor or endorse any Third Party Sites. You acknowledge that
            through the use of the Service, the Provider shall have access to your account credentials,
            including but not limited to login username and passwords. You agree that the Provider shall have
            the right at all times during and after your use of the Service to use, sell, license, reproduce,
            distribute and disclose de-identified aggregate, non-personally identifiable data compiled through
            use of Services and website.
          </p>
        </div>
      </div>
      <div class="u-display--flex">
        <span class="l-padding-right--xlarge u-type--xsmall">6.</span>
        <div>
          <p class="u-type--xsmall l-padding-bottom--medium">
            <strong>Your Obligations and Acknowledgments. </strong>
            You agree to provide true, accurate, current and
            complete information about yourself and your Third Party Accounts maintained at other websites
            and you agree not to misrepresent your identity or your account information. You agree to keep
            your account information up to date and accurate. You agree you will not provide any information
            that would violate any applicable law, rule, or regulation. You represent and warrant on an
            ongoing basis that you are the legal owner of, and/or are otherwise authorized to access, any and
            all of your Third Party Accounts and have the authority to (i) designate the Provider as your agent,
            (ii) use the Services and (iii) provide us and the Provider with the Content.
          </p>
          <p class="u-type--xsmall l-padding-bottom--medium">
            <strong>Because Third Parties may refresh or update your Third Party Account Information at different
              times/dates, Third Party Account Information displayed may not be accurate.
            </strong>
            As a result, Third
            Party Account Information may be more accurate when obtained directly from the Third Party
            Sites. We do not verify and are not responsible or liable for Third Party Account Information
            received or published through the Service or any errors, inaccuracies, delays or inability to obtain
            Third Party Account Information. Do not rely on the account data for trading or transactional
            purposes as it is not the official record of account activity; account data is provided as a
            convenience to you and for your information. Please refer to your account statements.
          </p>
          <p class="u-type--xsmall l-padding-bottom--medium">
            You acknowledge that Third Party Sites may not maintain the same level of security against
            unauthorized access to your Third Party Accounts and/or Third Party Account Information as we
            or the Provide maintains. You agree not to share any username(s), password(s), access
            passcode(s) or personal information (collectively referred to as “Access Codes”) to protect the
            security of your Third Party Account Information from unauthorized use and disclosure. If you
            become aware of, or believe there has been, any unauthorized use of or access to your Third Party
            Account Access Codes or Third Party Accounts, you must notify us immediately by contacting your
            Relationship Manager or telephone us at 1-800-982-4620. We also strongly recommend that you
            immediately change your Access Codes, as appropriate.
          </p>
        </div>
      </div>
      <div class="u-display--flex">
        <span class="l-padding-right--xlarge u-type--xsmall">7.</span>
        <div>
          <p class="u-type--xsmall l-padding-bottom--medium">
            <strong>DISCLAIMER OF WARRANTIES. </strong>
            YOU EXPRESSLY UNDERSTAND AND AGREE THAT YOUR USE OF
            THE SERVICE AND ALL INFORMATION, PRODUCTS AND OTHER CONTENT (INCLUDING THAT OF
            THIRD PARTIES) INCLUDED IN OR ACCESSIBLE FROM THE SERVICE IS AT YOUR SOLE RISK. THE
            SERVICE IS PROVIDED ON AN "AS IS" AND "AS AVAILABLE" BASIS. PROVIDER EXPRESSLY DISCLAIMS
            ALL WARRANTIES OF ANY KIND AS TO THE SERVICE AND ALL INFORMATION, PRODUCTS AND
            OTHER CONTENT (INCLUDING THAT OF THIRD PARTIES) INCLUDED IN OR ACCESSIBLE FROM THE
            SERVICE, WHETHER EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO THE IMPLIED
            WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND
            NONINFRINGEMENT. PROVIDER MAKE NO WARRANTY THAT (i) THE SERVICE WILL MEET YOUR
            REQUIREMENTS, (ii) THE SERVICE WILL BE UNINTERRUPTED, TIMELY, SECURE, OR ERROR-FREE,
            (iii) THE RESULTS THAT MAY BE OBTAINED FROM THE USE OF THE SERVICE WILL BE ACCURATE
            OR RELIABLE, (iv) THE QUALITY OF ANY PRODUCTS, SERVICES, INFORMATION, OR OTHER
            MATERIAL PURCHASED OR OBTAINED BY YOU THROUGH THE SERVICE WILL MEET YOUR
            EXPECTATIONS, OR (V) ANY ERRORS IN THE TECHNOLOGY WILL BE CORRECTED. ANY MATERIAL
            DOWNLOADED OR OTHERWISE OBTAINED THROUGH THE USE OF THE SERVICE IS DONE AT YOUR
            OWN DISCRETION AND RISK AND YOU ARE SOLELY RESPONSIBLE FOR ANY DAMAGE TO YOUR
            COMPUTER SYSTEM OR LOSS OF DATA THAT RESULTS FROM THE DOWNLOAD OF ANY SUCH
            MATERIAL. NO ADVICE OR INFORMATION, WHETHER ORAL OR WRITTEN, OBTAINED BY YOU
            FROM PROVIDER THROUGH OR FROM THE SERVICE WILL CREATE ANY WARRANTY NOT
            EXPRESSLY STATED IN THESE TERMS AND CONDITIONS.
          </p>
        </div>
      </div>
      <div class="u-display--flex">
        <span class="l-padding-right--xlarge u-type--xsmall">8.</span>
        <div>
          <p class="u-type--xsmall l-padding-bottom--medium">
            <strong>LIMITATION OF LIABILITY. </strong>
            YOU AGREE THAT WE, OUR AFFILIATES, PROVIDER AND ANY OF ITS
            AFFILIATES WILL NOT BE LIABLE FOR ANY HARMS, WHICH LAWYERS AND COURTS OFTEN CALL
            DIRECT, INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL OR EXEMPLARY DAMAGES,
            INCLUDING, BUT NOT LIMITED TO, DAMAGES FOR LOSS OF PROFITS, GOODWILL, USE, DATA OR
            OTHER INTANGIBLE LOSSES, EVEN IF PROVIDER HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH
            DAMAGES, RESULTING FROM: (i) THE USE OR THE INABILITY TO USE THE SERVICE; (ii) THE COST
            OF GETTING SUBSTITUTE GOODS AND SERVICES, (iii) ANY PRODUCTS, DATA, INFORMATION OR
            SERVICES PURCHASED OR OBTAINED OR MESSAGES RECEIVED OR TRANSACTIONS ENTERED INTO,
            THROUGH OR FROM THE SERVICE; (iv) UNAUTHORIZED ACCESS TO OR ALTERATION OF YOUR
            TRANSMISSIONS OR DATA, EXCEPT TO THE EXTENT CAUSED BY PROVIDER'S FAILURE TO COMPLY
            WITH ITS SECURITY OBLIGATIONS UNDER THIS AGREEMENT; (v) STATEMENTS OR CONDUCT OF
            ANYONE ON THE SERVICE; (vi) THE USE, INABILITY TO USE, UNAUTHORIZED USE, PERFORMANCE
            OR NON-PERFORMANCE OF ANY THIRD PARTY ACCOUNT PROVIDER SITE, EVEN IF THE PROVIDER
            HAS BEEN ADVISED PREVIOUSLY OF THE POSSIBILITY OF SUCH DAMAGES; OR (vii) ANY OTHER
            MATTER RELATING TO THE SERVICE EXCEPT FOR LIABILITY ARISING UNDER SUBSECTION (IV) OF
            THIS SECTION, WHICH SHALL BE LIMITED TO A MAXIMUM AGGREGATE LIABILITY OF TEN MILLION
            DOLLARS ($10,000,000), THE MAXIMUM AGGREGATE LIABILITY OF THE PROVIDER AND US TO
            YOU OR ANY THIRD PARTY FOR ALL CLAIMS ARISING OUT OF OR RELATING TO THE SERVICES,
            REGARDLESS OF THE FORM OF SUCH CLAIM, SHALL BE THE LESSER OF (i) THE ACTUAL DAMAGES
            SUSTAINED BY A PARTY WITH RESPECT TO SUCH CLAIM, OR (ii) THE AMOUNT PAID TO PROVIDER
            FOR YOUR USE OF THE SERVICES DURING THE THREE (3) MONTH PERIOD IMMEDIATELY
            PRECEDING SUCH CLAIM.
          </p>
        </div>
      </div>
      <div class="u-display--flex">
        <span class="l-padding-right--xlarge u-type--xsmall">9.</span>
        <div>
          <p class="u-type--xsmall l-padding-bottom--medium">
            <strong>INDEMNIFICATION. </strong>
            You agree to defend, indemnify and hold harmless us and our affiliates (including our affiliates'
            and our officers, directors, employees and agents) from and against any and all third party claims,
            liabilities, damages, losses or expenses (including reasonable attorney's fees and costs and
            settlement amounts) caused by, arising out of or in any way connected with your access to or use of
            the Service or your violation of the Terms and Conditions. You agree to protect and fully compensate
            the Provider and their affiliates from any and all third party claims, liability, damages, expenses
            and costs (including, but not limited to, reasonable attorney's fees) caused by or arising
            from your use of the Service, your violation of these terms or your infringement, or infringement by
            any other user of your account, of any intellectual property or other right of anyone.
          </p>
        </div>
      </div>
      <div class="u-display--flex">
        <span class="l-padding-right--xlarge u-type--xsmall">10.</span>
        <div>
          <p class="u-type--xsmall l-padding-bottom--medium">
            <strong>Termination of the Service. </strong>
            You may terminate your use of the Service at any time by contacting
            your Relationship Manager or telephoning us at 1-800-982-4620. Termination will not take effect
            until we have received your notice of termination and have had a reasonable opportunity to act
            on it. We may modify, cancel or terminate your access to or use of the at any time in our sole
            discretion without prior notice to you. Sections 6, 7, 8 and 9, and any other provision which is
            expressly or by implication intended to survive termination, will survive in the event of
            termination.
          </p>
        </div>
      </div>
      <div class="u-display--flex">
        <span class="l-padding-right--xlarge u-type--xsmall">11.</span>
        <div>
          <p class="u-type--xsmall l-padding-bottom--medium">
            <strong>Changes to the Terms and Conditions. </strong>
            We may amend or change the Terms and Conditions at
            any time by posting such changes online and will be effective upon posting. Each time you use
            the Service, your use constitutes acceptance of the Terms and Conditions then in effect, and as
            may be amended from time to time.
          </p>
        </div>
      </div>
      <div class="u-display--flex">
        <span class="l-padding-right--xlarge u-type--xsmall">12.</span>
        <div>
          <p class="u-type--xsmall l-padding-bottom--medium">
            <strong>Important Information and Other Terms. We do not and will not provide any investment advice
              or recommendations with respect to your Third Party Accounts.
            </strong>
            Your use of the Service does
            not create or impose on us any obligation to provide advice, recommendations or take any
            action(s) with respect to your Third Party Accounts. Online access for your Third Party Accounts
            may be subject to any terms and conditions established by other institutions and these Terms &
            Conditions do not amend any of those terms and conditions.
          </p>
          <p class="u-type--xsmall l-padding-bottom--medium">
            You agree that Provider is a third party beneficiary of the above provisions, with all rights to
            enforce such provisions as if Provider were a party to this Agreement.
          </p>
          <p class="u-type--xsmall l-padding-bottom--medium">
            If any part of these Terms and Conditions is determined by a court of competent jurisdiction or
            applicable governmental authority to be invalid or unenforceable, the part or provision shall be
            deemed amended to the extent necessary to be valid, and all other provisions shall remain in full
            force and effect. You may not assign these Terms and Conditions to anyone. These Terms and
            Conditions, including rights and obligations thereunder, are governed by the laws of the State of
            Delaware without regard to conflict of laws principles. Any dispute arising out of or concerning
            the Services and/or the Terms and Conditions shall be brought in a State or Federal Court located
            in the State of Delaware.
          </p>
        </div>
      </div>
    </div>
    <div class="">
      <label class="u-border--primary-dark u-display--block u-border-radius--small l-padding--medium">
        <input aria-label="Accept" #agreementBox type="checkbox" id="agreementBox" required
          (change)="onInputChange()" />
        I accept
      </label>

    </div>
    <div class="buttons">
      <a href="\assets\Wilmington_Trust_Yodlee_Terms_And_Conditions.pdf"
        download="Wilmington_Trust_Yodlee_Terms_And_Conditions.pdf" class="c-link u-type--small">
        view PDF
      </a>
      <button (click)="handleAccept()" class="c-button c-button--primary u-type--small"
        [disabled]="!agreementBox.checked" [ngClass]="{ 'c-button--primary-disabled': !agreementBox.checked }"><span
          class="c-button-focus">Continue</span>

      </button>
    </div>
  </div>
</div>