import { Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router, UrlTree, Route, UrlSegment } from '@angular/router';
import { LoggerService } from '@app/logger.service';
import { Observable, of } from 'rxjs';
import { map, first, timeout, switchMap } from 'rxjs/operators';
import { Environment } from 'env/ienvironment';
import { ENV } from 'env/environment.provider';
import { Store } from '@ngxs/store';
import { LegalDisclosureState } from '@app/_state/legal.state';
import { MetaState } from '@app/_state/meta.state';

@Injectable({
  providedIn: 'root'
})
export class LegalDisclosuresGuard  {
  private blockingDisclosures$: Observable<boolean | UrlTree>;

  constructor(
    private logger: LoggerService,
    private store: Store,
    private router: Router,
    @Inject(ENV) private env: Environment) {
    if (!this.env.impersonation) {
      this.blockingDisclosures$ = this.store.select(MetaState.isInitialDataLoaded).pipe(
        first((v) => v),
        switchMap(() => {
          return this.store.selectOnce(LegalDisclosureState.blockingDisclosures);
        }),
        map((blockingDisclosures) => {
          if (!Array.isArray(blockingDisclosures)) {
            throw new Error('[LegalDisclosuresGuard] disclosures uninitialized');
          }
          if (blockingDisclosures.length > 0) {
            return this.router.parseUrl('legal/disclosures');
          }
          return true;
        }),
        timeout({ first: 30000, with: () => of(false) })
      );
    } else {
      //never challenge advisors to sign disclosures
      this.blockingDisclosures$ = of(true);
    }
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    return this.blockingDisclosures$;
  }

  canLoad(
    route: Route,
    segments: UrlSegment[]
  ): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    return this.blockingDisclosures$;
  }
}
