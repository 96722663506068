export class Profile {
  public loginId: string;
  public profileId: string;
  public firstName: string;
  public middleName: string;
  public lastName: string;
  public prefname: string;
  public businessname: string;
  public email: string;
  public emailStatus: Number;
  public phonenum: string;
  public secondPhone: string;
  public prefPhone: string;
  public lastActivity: string;
  public member: Number;
  public contactInfo: [
    {
      companyName: string;
      phonenum: string;
      addr1: string;
      addr2: string;
      city: string;
      state: string;
      zipcode: string;
      country: string;
      countryCode: string;
      isoCountryCode: string;
    }
  ];
  public ydlEligible: boolean;
  public firstTimeLogin: boolean;
  public credentialsUpdated: boolean;
}
