import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { LoggerService } from '@app/logger.service';
import {
  ArcElement,
  CategoryScale,
  Chart,
  ChartConfiguration,
  ChartData,
  Filler,
  LinearScale,
  LineController,
  LineElement,
  PieController,
  PointElement,
  Tooltip
} from 'chart.js';
import { ChartsWrapperInitContext, TOOLTIP_SECTION_ID } from './charts-wrapper.consts';

@Injectable({
  providedIn: 'root'
})
export class ChartsWrapperService {

  private _tooltipEl: HTMLElement;
  
  public get tooltipEl() : HTMLElement {
    return this._tooltipEl;
  }
  

  constructor(private logger: LoggerService, @Inject(DOCUMENT) document: Document) {
    if (Chart) {
      Chart.register([
        ArcElement,
        PieController,
        CategoryScale,
        LineController,
        LinearScale,
        PointElement,
        LineElement,
        Tooltip,
        Filler
      ]);
      const toolTipSection = document.createElement('section');
      toolTipSection.style.display = 'none';
      toolTipSection.style.opacity = '0';
      toolTipSection.id = TOOLTIP_SECTION_ID;
      toolTipSection.ariaLive = 'polite';
      this._tooltipEl = toolTipSection;
      document.body.appendChild(toolTipSection);
    } else {
      this.logger.warn('[ChartsWrapperService] no Chart dependency loaded.');
    }
  }

  //Create or Update Chart on context with provided config, returns instance of new Chart
  initChart(context: ChartsWrapperInitContext, config: ChartConfiguration): Chart {
    const _instance = Chart.getChart(context);
    if (_instance) {
      _instance.destroy();
    }
    return new Chart(context, config);
  }

  //Replaces data on chart instance and updates it. Make sure its the right data!
  updateChartData(context: ChartsWrapperInitContext, data: ChartData): void {
    const _instance = Chart.getChart(context);
    try {
      _instance.data = data;
      _instance.update();
    } catch (e) {
      this.logger.warn('[ChartsWrapperService] no chart found for destroy');
    }
  }

  //Destroys chart instance, cleaning up references in memory. Call this ngOnDestroy!
  destroyChart(context: ChartsWrapperInitContext): void {
    const _instance = Chart.getChart(context);
    try {
      _instance.destroy();
    } catch (e) {
      this.logger.warn('[ChartsWrapperService] no chart found for destroy');
    }
  }

  // removes all active elements from all charts and tooltips
  removeAllActiveElementsCharts(){
    const Charts = Chart
    Object.values(Charts.instances).forEach(
      (chart)=>{ 
        chart.tooltip?.setActiveElements([], { x: 0, y: 0 })
        chart.setActiveElements([])
        chart.update()
      }
    )
  }

  removeActiveElementsFrom(chart: Chart){
    chart.setActiveElements([])
    chart.tooltip?.setActiveElements([], {x: 0,y: 0})
    
  }

}
