import { AfterContentInit, Component, ElementRef, Input, NgZone, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AggregationActions } from '@app/aggregation/_actions/aggregation.actions';
import { AggAccount } from '@app/aggregation/_models/AggFinSummary';
import { LoggerService } from '@app/logger.service';
import { ModalBaseClass } from '@app/shared/services/modal-mk2.service';
import { isTokenValid } from '@app/shared/utils/auth-token.utils';
import { Store, Select } from '@ngxs/store';
import { BehaviorSubject, Observable, of, timer } from 'rxjs';
import { distinctUntilChanged, map, switchMap, take, tap, withLatestFrom } from 'rxjs/operators';
import { YdlActions } from '../_actions/ydl.actions';
import { YdlStateModel } from '../_state/ydl.state';
import { UserAgentState } from '@app/_state/userAgent.state';

const flContainerId = 'container_fastlink';

export type YdlFastlinkComponentParamsDict = {
  [k: string]: {
    accountId?: string;
    providerAccountId?: string;
    providerId?: string;
    container?: string;
    flow: string;
    configName: string;
  };
};

export enum YdlFastLinkStatus {
  UNINIT = 'UNINIT',
  RETRY = 'RETRY',
  RUNNING = 'RUNNING',
  CLOSED = 'CLOSED',
  FAILED = 'FAILED'
}

@Component({
    selector: 'app-ydl-fastlink',
    templateUrl: './ydl-fastlink.component.html',
    styleUrls: ['./ydl-fastlink.component.scss'],
    standalone: true
})
export class YdlFastlinkComponent extends ModalBaseClass implements OnInit, AfterContentInit, OnDestroy {
  @Input( { required: true } ) flow?: string;
  @Input( { required: true } ) aggAccount?: AggAccount;
  paramsDict: YdlFastlinkComponentParamsDict;
  defaultParams = {
    configName: 'Aggregation'
  };
  @ViewChild(flContainerId, { static: false }) flContainer: ElementRef<HTMLDivElement>;
  @Select(UserAgentState.selectUserAgentPlatform) userAgentPlatform$: Observable<any>;
  mobileView = false;

  fastLinkStatus$: BehaviorSubject<YdlFastLinkStatus> = new BehaviorSubject(YdlFastLinkStatus.UNINIT);
  showSpinner$: Observable<boolean>;
  constructor(private store: Store, private logger: LoggerService, private zone: NgZone, private router: Router) {
    super();
  }
  ngOnDestroy(): void {
    this.fastLinkStatus$.pipe(take(1)).subscribe((status) => {
      if (status === YdlFastLinkStatus.RUNNING) {
        this.logger.info('Cleaning up fastlink instance left running...');
        this.store.dispatch(new YdlActions.DeleteYdlAuth());
        window.fastlink.close();
        this.fastLinkStatus$.next(YdlFastLinkStatus.CLOSED);
      }
      this.fastLinkStatus$.complete();
    });
  }
  ngAfterContentInit(): void {
    this.launchFastLink();
  }

  ngOnInit(): void {
    this.showSpinner$ = this.fastLinkStatus$.pipe(
      map((status) => {
        return status === YdlFastLinkStatus.UNINIT || status === YdlFastLinkStatus.RETRY;
      }),
      distinctUntilChanged()
    );
    this.paramsDict = {
      edit: {
        providerAccountId: this.aggAccount?.providerAccountId,
        flow: 'edit',
        configName: 'Aggregation'
      },
      editRealEstate: {
        accountId: this.aggAccount?.id,
        flow: 'editRealEstate',
        configName: 'Aggregation'
      },
      editManual: {
        accountId: this.aggAccount?.id,
        flow: 'editManual',
        configName: 'Aggregation',
        container: this.aggAccount?.accountCategory
      },
      addForProvider: {
        providerId: this.aggAccount?.providerId,
        flow: 'add',
        configName: 'Aggregation'
      }
    };

    // TODO: un-comment this code when we are ready to move forward with the yodlee integration enhancemnts
    // TODO: This is being commented out for now so that it doesn't effect the production release.
    // this.userAgentPlatform$.pipe(
    //   take(1),
    //   map(data => {
    //     return data.platform.type;
    //   })
    // ).subscribe((data) => {
    //   if (data === 'mobile'){
    //     this.mobileView = true;
    //   }
    // });
  }

  resizeWindow(): void {
    const isIFrame = (input: HTMLElement | null): input is HTMLIFrameElement =>
      input !== null && input.tagName === 'IFRAME';

    let frame = document.getElementById('fl-frame');
    if (isIFrame(frame)) {
      frame.setAttribute('scrolling', 'yes');
    }
  }

  launchFastLink() {
    const ydlAuthState$ = this.store
      .selectOnce<YdlStateModel>((state) => state?.ydl)
      .pipe(
        map((state) => {
          return state.ydlAuthResp;
        })
      );

    ydlAuthState$
      .pipe(
        withLatestFrom(this.fastLinkStatus$),
        switchMap(([authResp, compStatus]) => {
          //if we have an authResp, check validity and pass that through
          //else dispatch a fetch action and switch its completion emision to the ydlAuthState query again
          //if this component instance is already set to status.RETRY, then fail, so we don't loop forever.
          const isAuthRespValid = isTokenValid(authResp);
          if (!isAuthRespValid) {
            return compStatus === YdlFastLinkStatus.RETRY
              ? of(null).pipe(
                  tap(() => {
                    this.logger.error(new Error(`[YdlFastlinkComponent] refetching fastlink auth failed...`));
                    this.fastLinkStatus$.next(YdlFastLinkStatus.FAILED);
                  })
                )
              : this.store.dispatch(new YdlActions.FetchYdlAuth()).pipe(
                  tap(() => this.logger.info(`[YdlFastlinkComponent] no valid fastlink token, refetching...`)),
                  switchMap(() => {
                    return ydlAuthState$;
                  })
                );
          }
          return of(authResp);
        })
      )
      .subscribe((ydlAuthResp) => {
        if (!ydlAuthResp) {
          this.logger.warn(`[YdlFastlinkComponent] unable to fetch fastlink auth, closing...`);
          this.requestClose();
          return;
        }
        if (!this.flContainer?.nativeElement) {
          this.logger.info(
            `[YdlFastlinkComponent] missing container, trying again...: `,
            this.flContainer?.nativeElement
          );
          return timer(100)
            .pipe(take(1))
            .subscribe(() => {
              this.fastLinkStatus$.next(YdlFastLinkStatus.RETRY);
              this.launchFastLink();
            });
        }

        if (!window.fastlink) {
          this.logger.warn(`[YdlFastlinkComponent] fastlink.js not loaded yet, dispatching...`);
          return this.store.dispatch(new YdlActions.LoadFastLinkJs()).subscribe(() => {
            this.fastLinkStatus$.next(YdlFastLinkStatus.RETRY);
            this.launchFastLink();
          });
        }

        try {
          this.logger.info(`[YdlFastlinkComponent] opening fastlink...`);
          type FastLinkError = Partial<{
            code: string;
            fnToCall: string;
            message: string;
            title: string;
          }>;

          if (this.flow === 'edit' && this.aggAccount?.accountType === 'REAL_ESTATE') {
            this.flow = 'editRealEstate';
          } else if (this.flow === 'edit' && this.aggAccount?.manual) {
            this.flow = 'editManual';
          }

          this.zone.runOutsideAngular(() => {
            let successEventOccured = false;
            window.fastlink.open(
              {
                fastLinkURL: ydlAuthResp.fastLinkUrl,
                accessToken: `Bearer ${ydlAuthResp.accessToken}`,
                forceIframe: true,
                iframeScrolling: true,
                params: this.paramsDict[this.flow] || this.defaultParams,
                // TODO: replace the params with this when we are ready to move forward with yodlee
                // TODO: mobile integration.
                // params: this.mobileView
                //   ? this.paramsDict[this.flow]
                //     ? { ...this.paramsDict[this.flow], isIFrameMounted: true }
                //     : { ...this.defaultParams, isIFrameMounted: true }
                //   : this.paramsDict[this.flow] || this.defaultParams,
                onSuccess: (data) => {
                  // will be called on success. For list of possible message, refer to onSuccess(data) Method.
                  successEventOccured = true;
                  this.logger.info(data);
                },
                onError: (data: FastLinkError) => {
                  // will be called on error. For list of possible message, refer to onError(data) Method.
                  this.logger.warn(data);
                  if (data.code === 'N403' || data.message === 'Invalid token.') {
                    this.logger.warn(`[YdlFastlinkComponent] fastlink token invalid on opening, retrying once...`);
                    return this.zone.run(() => {
                      this.store.dispatch(new YdlActions.FetchYdlAuth()).subscribe(() => {
                        this.fastLinkStatus$.next(YdlFastLinkStatus.RETRY);
                        this.launchFastLink();
                      });
                    });
                  }
                },
                onClose: (data) => {
                  // will be called called to close FastLink. For list of possible message, refer to onClose(data) Method.
                  this.logger.info(data);

                  const isOnAggregationRoute = this.router.url.includes('total-picture');
                  if (successEventOccured || data?.sites?.some((site) => site.status === 'SUCCESS')) {
                    this.store.dispatch(new AggregationActions.SetDefaultThenFetch(null));
                    if (!isOnAggregationRoute) {
                      this.router.navigateByUrl(this.router.parseUrl('/total-picture'));
                    }
                  }
                  this.requestClose();
                  window.scrollTo(0, 0);
                },
                onEvent: (data) => {
                  // will be called on intermittent status update.
                  if (this.fastLinkStatus$.value === YdlFastLinkStatus.UNINIT) {
                    this.logger.info('[YdlFastlinkComponent] onEvent while uninit status', data);
                    this.zone.run(() => {
                      this.fastLinkStatus$.next(YdlFastLinkStatus.RUNNING);
                    });
                  }
                  this.logger.info(data);
                  this.resizeWindow();
                }
              },
              flContainerId
            );
          });
        } catch (e) {
          this.logger.error(new Error('Fastlink opening failure'));
          this.logger.warn(e);
          this.fastLinkStatus$.next(YdlFastLinkStatus.FAILED);
          this.requestClose();
        }
      });
  }
}
