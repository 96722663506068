import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { Environment } from 'env/ienvironment';
import { ENV } from 'env/environment.provider';
import { LoggerService } from '@app/logger.service';
import { Profile } from '@shared/models/profile.model';
import { Store } from '@ngxs/store';
import { ProfileActions } from '@app/_actions/profile.actions';

export enum ProfileServiceUrls {
  profile = 'profile'
}

export interface UserSelfUpdatePhone {
  primaryPhone: string;
  secondaryPhone?: string;
  countryCode: string;
  countryCodeSecondary?: string;
}

export interface PhoneCountryCode {
  id: number;
  //the actual prefix number, with no preceeding '+'
  code: string;
  //label for options, ex: "USA +1"
  country: string;
  //some codes in our data source have the area code promoted to country code when it should be '+1[areacode]'.
  // Prefix code with this when available.
  additionalCode: '1' | '';
  //should match Profile.contactInfo[0].isoCountryCode to specificy actual calling code from list
  countryIsoCode: string;
  active: 'Y' | string;
}
export enum ResetPasswordMessage {
  SUCCESS = "SUCCESS",
  INVALID_INPUT = "INVALID INPUT",
  INVALID_CREDENTIALS = "INVALID CREDENTIALS",
  ERROR = "ERROR"
}

export interface ResetPasswordResponse {
  message: ResetPasswordMessage;
}



@Injectable({
  providedIn: 'root'
})
export class ProfileService {
  private updateEmailEndpoint = '/updateemail';
  private updatePhonesEndpoint = '/updatephones';
  private resetPasswordEndpoint = '/resetpass';
  private updateCredFlagEndpoint = '/updatecredflag';
  private profileServiceHttpHeaders: HttpHeaders = new HttpHeaders({
    'Content-Type': 'application/json'
  });

  constructor(
    private store: Store,
    private http: HttpClient,
    private logger: LoggerService,
    @Inject(ENV) private env: Environment) {}

  confirmCredentialsPOST() {
    return this.http
      .post<{ message: string }>(this.env.pcfUrl + this.updateCredFlagEndpoint, {})
      .pipe(switchMap(() => this.store.dispatch(new ProfileActions.FetchProfile())));
  }

  fetchProfile(): Observable<Profile> {
    return this.http.get<Profile>(`${this.env.pcfUrl}/${ProfileServiceUrls.profile}`);
  }

  fetchAllPhoneCountryCodes(): Observable<PhoneCountryCode[]> {
    return this.http.get<PhoneCountryCode[]>(this.env.pcfUrl + '/allcountrycodes');
  }

  setEmail(data: any) {
    if (data) {
      return this.http
        .post(
          this.env.pcfUrl + this.updateEmailEndpoint,
          {
            email: data
          },
          {
            headers: this.profileServiceHttpHeaders
          }
        )
        .pipe(tap(() => this.store.dispatch(new ProfileActions.FetchProfile())));
    } else {
    }
  }

  setPhones(data: UserSelfUpdatePhone) {
    if (data) {
      return this.http
        .post(this.env.pcfUrl + this.updatePhonesEndpoint, data, {
          headers: this.profileServiceHttpHeaders
        })
        .pipe(tap(() => this.store.dispatch(new ProfileActions.FetchProfile())));
    } else {
    }
  }

  setPassword(currentPassword: string, newPassword: string, confirmNewPassword: string) {
    if (currentPassword && newPassword && confirmNewPassword) {
      return this.http
        .post(
          this.env.pcfUrl + this.resetPasswordEndpoint,
          {
            oldPassword: currentPassword,
            newPassword: newPassword,
            confirmPassword: confirmNewPassword
          },
          {
            headers: this.profileServiceHttpHeaders
          }
        )
        .pipe(tap(() => this.store.dispatch(new ProfileActions.FetchProfile())))
    } 
      else {
    }
  }

  findCountryCodeByProfile(profile: Profile, countryCodeList: PhoneCountryCode[]): PhoneCountryCode | null {
    const _isoCode = profile.contactInfo[0].isoCountryCode;
    const filtered = countryCodeList.filter((cc) => cc.countryIsoCode === _isoCode);
    return filtered[0] || null;
  }
}
