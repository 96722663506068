import { AccountHoldings } from '@app/shared/models/client-holdings.model';
import { HoldingsTimePeriod } from '@app/shared/services/globalConstants';

export namespace HoldingsActions {
  export class Fetch {
    static readonly type = '[Holdings] Fetch Holdings Data for All Accounts';
    constructor() {}
  }

  export class FetchByAccountsForTimePeriod {
    static readonly type = '[Holdings] Fetch by Current Account(s) for Time Period';
    constructor(public data: HoldingsTimePeriod) {}
  }

  export class FetchAllHoldingsByTimePeriod {
    static readonly type = '[Holdings] Fetch All Holdings By Time Period';
    constructor(public data: HoldingsTimePeriod) {}
  }

  export class SetHoldingssData {
    static readonly type = '[Holdings] Set Holdings Data';
    constructor(public data: AccountHoldings) {}
  }

  export class SetHoldingssDataForTimePeriod {
    static readonly type = '[Holdings] Set Holdings Data for Time Period';
    constructor(public timeperiod: HoldingsTimePeriod, public accountHoldings: AccountHoldings) {}
  }

  export class ClearHoldingsByTimePeriod {
    static readonly type = '[Holdings] Clear Holdings By Time Period';
    constructor() {}
  }
}
