import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { LoggerService } from '@app/logger.service';
import { Profile } from '@app/shared/models/profile.model';
import { GlobalConstants } from '@app/shared/services/globalConstants';
import { AccountsActions } from '@app/_actions/accounts.actions';
import { HoldingsActions } from '@app/_actions/holdings.actions';
import { AccountsState, CurrentClientAccountState } from '@app/_state/accounts.state';
import { AuthState } from '@app/_state/auth.state';
import { MetaState } from '@app/_state/meta.state';
import { ProfileState } from '@app/_state/profile.state';
import { ENV } from 'env/environment.provider';
import { Store } from '@ngxs/store';
import { first, map, of, retry, switchMap, timeout } from 'rxjs';
import { MonitoringService } from '@app/shared/monitoring/monitoring.service';
import {NO_PROFILE_DATA_AVAILABLE_ERROR} from './guardsErrorConstants';

export function impersonationGuard() {
  return () => {
    const environment = inject(ENV);
    return environment.impersonation;
  };
}

export function loginNotNeededGuard() {
  return () => {
    const store = inject(Store);
    const router = inject(Router);
    const environment = inject(ENV);
    return store.selectOnce(AuthState.isAuthenticated).pipe(
      map((isAuthenticated) => {
        if (isAuthenticated) {
          return router.parseUrl('/dashboard');
        }
        if (environment.impersonation) {
          return router.parseUrl('/error-handler?error=wt2-refresh');
        }
        return !isAuthenticated;
      })
    );
  };
}

export function clearHoldingsByTimePeriodGuard() {
  return () => {
    const store = inject(Store);
    return store.dispatch(new HoldingsActions.ClearHoldingsByTimePeriod()).pipe(map(() => true));
  };
}

export function clearAccountStateGuard() {
  return () => {
    const store = inject(Store);
    return store
      .dispatch(new AccountsActions.SetCurrentClientAccountState({ account: null, group: null }))
      .pipe(map(() => true));
  };
}

//sends you to the dashboard if you try to enter the guarded route while the account state is empty
export function accountStateGuard() {
  return () => {
    const store = inject(Store);
    const router = inject(Router);
    return store.selectOnce(AccountsState.currentClientAccountState).pipe(
      map((state: CurrentClientAccountState) => {
        if (!state.account && !state.group) {
          return router.parseUrl('/dashboard');
        }
        return true;
      })
    );
  };
}

export function authGuard() {
  return () => {
    const store = inject(Store);
    const router = inject(Router);
    return store.selectOnce(AuthState.isAuthenticated).pipe(
      map((isAuthenticated) => {
        return isAuthenticated || router.parseUrl('/login');
      })
    );
  };
}

export function firstTimeLoginGuard() {
  return () => {
    const store = inject(Store);
    const router = inject(Router);
    const logger = inject(LoggerService);
    const environment = inject(ENV);
    const monitoringService = inject(MonitoringService);
    const checkFirstTimeLogin$ = environment.impersonation
      ? of(true)
      : store.selectOnce<Profile>(ProfileState).pipe(
          map((profile: Profile) => {
            if (!profile) {
              monitoringService.postAlertsToDynatrace(NO_PROFILE_DATA_AVAILABLE_ERROR);
              logger.warn('[FirstTimeLoginGuard] no profile data available');
              throw new Error('no profile data available');
            } else if (!profile?.credentialsUpdated) {
              logger.info('[FirstTimeLoginGuard] first time login detected, routing to /firstlogin');
              return router.parseUrl('/profile/firstlogin');
            } else {
              return true;
            }
          }),
          retry({
            count: 1,
            delay: () => store.select(MetaState.isInitialDataLoaded).pipe(first((isloaded) => isloaded === true))
          }),
          timeout({ first: 30000, with: () => of(false) })
        );

    return checkFirstTimeLogin$;
  };
}

export function transferMoneycanLoadGuard() {
  return () => {
    const store = inject(Store);
    const router = inject(Router);
    return store.selectOnce(AuthState.isAuthenticated).pipe(
      switchMap((isAuthed) => {
        return isAuthed
          ? of(isAuthed).pipe(
              switchMap(() => {
                return store.select(MetaState.isInitialDataLoaded).pipe(
                  first((isInitialDataLoaded) => isInitialDataLoaded),
                  switchMap(() => {
                    return store.selectOnce(AccountsState.currentEntitlementsCodes());
                  }),
                  map((entitlements: string[]) =>
                    entitlements
                      ? entitlements.filter(
                          (entitlement) => entitlement === GlobalConstants.servicesDictionary.transferMoney.code
                        )
                      : []
                  ),
                  map((filteredEntitlements) => filteredEntitlements.length > 0 || router.parseUrl('/dashboard'))
                );
              })
            )
          : of(router.parseUrl('/login'));
      })
    );
  };
}

export function consolidatedHoldingsCanLoadGuard() {
  return () => {
    const store = inject(Store);
    const router = inject(Router);
    const environment = inject(ENV);
    return store.selectOnce(AuthState.isAuthenticated).pipe(
      switchMap((isAuthed) => {
        return isAuthed
          ? of(isAuthed).pipe(
              switchMap(() => {
                return store.select(MetaState.isInitialDataLoaded).pipe(
                  first((isInitialDataLoaded) => isInitialDataLoaded),
                  switchMap(() => {
                    return store.select(ProfileState.memberType);
                  }),
                  map((memberType) => {
                    if (memberType === 1 && environment.enableFeature_consolidatedHoldings) {
                      return true;
                    } else {
                      return router.parseUrl('/dashboard');
                    }
                  })
                );
              })
            )
          : of(router.parseUrl('/login'));
      })
    );
  };
}
