import {
  formatDate,
  formatCurrency,
  formatNumber,
  formatPercent
} from '@angular/common';

import { GlobalConstants } from '@shared/services/globalConstants';
import { Injectable } from "@angular/core";

@Injectable()
export class GlobalFormatterService {
  constructor() {}

  static gridNull(value): string {
    if (value == 0 || value === null || value == undefined) {
      return '-';
    } else {
      return `${value}`;
    }
  }

  static number(value: number): string {
    return formatNumber(value, GlobalConstants.locale, '1.0-4');
  }

  static gridNumber(value): string {
    if (value == 0 || value === null) {
      return '-';
    } else {
      return GlobalFormatterService.number(value);
    }
  }

  static currency(value): string {
    return formatCurrency(value, GlobalConstants.locale, '$', 'USD');
  }

  static gridCurrency(value): string {
    if (value == 0 || value === null) {
      return '-';
    } else {
      return GlobalFormatterService.currency(value);
    }
  }

  static customGridCurrency(value): string {
    if (value === null) {
      return '-';
    } else {
      return GlobalFormatterService.currency(value);
    }
  }

  static currencyRoundUp(value): string {
    return formatCurrency(value, GlobalConstants.locale, '$', 'USD', '1.0-0');
  }


  static date(value, formatString = GlobalConstants.dateFormat): string {
    let correctedValue = value;
    if(typeof value === "string"){
      const index = value.indexOf('T00:00:00Z');
      if(index > 0){
        correctedValue = value.slice(0, index);
      }
    }
    return formatDate(
      correctedValue,
      formatString,
      GlobalConstants.locale
    );
  }

  static gridDate(value, formatString = 'MM/dd/yyyy'): string {
    if (!value) {
      return '';
    } else {
      return GlobalFormatterService.date(value, formatString)
    }
  }

  static percent(value) {
    if (
      value === '' ||
      value === null ||
      value === 'undefined' ||
      value === 'Infinity'
    ) {
      return;
    } else {
      return formatPercent(value, GlobalConstants.locale, '1.2');
    }
  }

  
  static customGridPercent(value) {
    if (
      value === '' ||
      value === null ||
      value === 'undefined' ||
      value === 'Infinity'
    ) {
      return;
    } else if (value === 0) {
      return formatPercent(value, GlobalConstants.locale, '1.0');
    } else {
      return formatPercent(value, GlobalConstants.locale, '1.2');
    }
  }

}
