import { Injectable } from '@angular/core';
import { LoggerService } from '@app/logger.service';
import { RelationshipTeamMember, RelationshipTeamService } from '@app/shared/services/relationship-team.service';
import { AppActions } from '@app/_actions/app.actions';
import { RelationshipTeamActions } from '@app/_actions/relationshipTeam.actions';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { first, switchMap, tap } from 'rxjs';
import { MetaState } from './meta.state';

export interface RelationshipTeamModel {
  relationshipTeam: RelationshipTeamMember[];
}

const defaultState: RelationshipTeamModel = {
  relationshipTeam: []
};

@State<RelationshipTeamModel>({
  name: 'relationshipTeam',
  defaults: defaultState
})
@Injectable()
export class RelationshipTeamState {
  constructor(
    private logger: LoggerService,
    private relationshipTeamService: RelationshipTeamService,
    private store: Store
  ) {}

  private initialSetup = () => {
    this.store
      .select(MetaState.isInitialDataLoaded)
      .pipe(first((_) => _))
      .subscribe((_) => {
        this.store.dispatch(new RelationshipTeamActions.FetchRelationshipTeam());
      });
  };

  ngxsOnInit(ctx: StateContext<RelationshipTeamModel>) {
    this.initialSetup();
  }

  @Action([RelationshipTeamActions.FetchRelationshipTeam])
  fetchRelationshipTeam(
    ctx: StateContext<RelationshipTeamModel>,
    action: RelationshipTeamActions.FetchRelationshipTeam
  ) {
    this.logger.info('[Relationship Team] Fetching Team');

    return this.relationshipTeamService.getRelationshipTeam().pipe(
      tap((team: RelationshipTeamMember[]) => {
        return team;
      }),
      switchMap((team) => ctx.dispatch(new RelationshipTeamActions.SetRelationshipTeam(team)))
    );
  }

  @Action([RelationshipTeamActions.SetRelationshipTeam])
  setRelationshipTeam(ctx: StateContext<RelationshipTeamModel>, action: RelationshipTeamActions.SetRelationshipTeam) {
    const teamWithHrefPhone: RelationshipTeamMember[] = action.team.map((member) => {
      let hrefPhoneFormat = member.phone?.replace(/^[(]?(\d{3})[)-]\s?(\d{3}-\d{4})$/, (match, p1, p2) => {
        return `${p1}-${p2}`;
      });
      return {
        ...member,
        hrefPhone: hrefPhoneFormat
      };
    });
    ctx.patchState({
      relationshipTeam: teamWithHrefPhone
    });
    return teamWithHrefPhone;
  }

  @Action([AppActions.ResetState])
  onResetState(ctx: StateContext<RelationshipTeamModel>, action: AppActions.ResetState) {
    ctx.setState(defaultState);
    return Promise.resolve();
  }

  @Selector([RelationshipTeamState])
  static RelationshipTeam(state: RelationshipTeamModel): RelationshipTeamMember[] {
    let sortedRelationshipTeam: RelationshipTeamMember[] = [...state?.relationshipTeam];
    return sortedRelationshipTeam.sort((member1, member2) => member1.hrRank - member2.hrRank);
  }

  @Selector([RelationshipTeamState])
  static isTeamLengthValid(state: RelationshipTeamModel): boolean {
    let teamLength: number = state.relationshipTeam.length;
    const maxTeamLength: number = 5;
    const minTeamLength: number = 1;
    if (teamLength >= minTeamLength && teamLength <= maxTeamLength) {
      return true;
    }
    return false;
  }
}
