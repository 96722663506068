import { UserAgentStateModel } from '@app/_state/userAgent.state';

export namespace UserAgentActions {

  export class Update {
    static readonly type = '[UserAgent] Update UserAgent Metadata';
    constructor(public patch: Partial<UserAgentStateModel>) {}
  }


  export class DoPWAInstall {
    static readonly type = '[UserAgent] Do PWA Install';
    constructor() {}
  }

}
