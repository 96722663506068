import { Component, AfterViewInit, OnDestroy, Inject } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { Store } from '@ngxs/store';
import { AuthActions } from '@app/_actions/auth.actions';
import { Environment } from 'env/ienvironment';
import { ENV } from 'env/environment.provider';
import { take } from 'rxjs';
import { LoadingSpinnerComponent } from '../shared/spinners/loading-spinner/loading-spinner.component';

@Component({
    selector: 'app-sso',
    templateUrl: './sso.component.html',
    styleUrls: ['./sso.component.scss'],
    standalone: true,
    imports: [LoadingSpinnerComponent]
})
export class SsoComponent implements AfterViewInit, OnDestroy {
  private sub: Subscription;
  constructor(
    public router: Router,
    private route: ActivatedRoute,
    private store: Store,
    @Inject(ENV) private env: Environment
  ) {}

  ngOnDestroy(): void {
    this.sub?.unsubscribe();
  }

  ngAfterViewInit(): void {
    if (this.env.impersonation) {
      this.sub = this.route.fragment
        .pipe(
          take(1),
          map((fragment) => new URLSearchParams(fragment || ''))
        )
        .subscribe((fragment) => {
          if (fragment.get('aecode')) {
            this.store.dispatch(
              new AuthActions.ImpInit(
                fragment.get('aecode'),
                fragment.has('groups') ? 'dashboard/grouping' : 'dashboard'
              )
            );
          } else {
            this.router.navigateByUrl(this.router.parseUrl('/error-handler'));
          }
        });
    } else {
      this.router.navigateByUrl(this.router.parseUrl('/error-handler'));
    }
  }
}
