import { GlobalFormatterService } from '../services/global-formatter.service';
import { Optional } from '@angular/core';
export class ClientHoldings {
  id: string;
  acctSourceCd: string;
  acctTypeCd: string;
  acctName: string;
  acctNameLong: string;
  acctBalance: number;
  activeInd: string;
  company: string;
  accountType: string;
  accountCategory: string;
  accountNickName: string;
  balanceName: string;
  portfolio: string;
  acctId: string;
  loanTerm: number;
  loanRate: number;
  availableCredit: number;
  accountTypeName: string;
  accountTypeOFX: string;
  availableBalance: number;
}

export class AssetClassSummary {
  totalValue: number;
  incomeTotalValue: number;
  principalTotalValue: number;
  allocation: number;

  constructor() {
    this.totalValue = 0;
    this.incomeTotalValue = 0;
    this.principalTotalValue = 0;
    this.allocation = 0;
  }
}
export interface HoldingsTotalsAsset {
  Equities: AssetClassSummary;
  'Fixed Income': AssetClassSummary;
  'Real Assets': AssetClassSummary;
  'Hedge Funds': AssetClassSummary;
  'Private Markets': AssetClassSummary;
  Other: AssetClassSummary;
  'Cash & Equivalents': AssetClassSummary;
}

export class HoldingsTotals {
  asset: HoldingsTotalsAsset;
  portfolio: {
    totalMarketValue: number;
    principalMarketValue: number;
    incomeMarketValue: number;
  };
  holdings: {
    cost: number;
    unrealizedGainLoss: number;
    estAnnualIncome: number;
    todaysChangeInTotalMarketValue: number;
  };
  estimatedYield: string;

  constructor() {
    this.asset = {
      Equities: new AssetClassSummary(),
      'Fixed Income': new AssetClassSummary(),
      'Real Assets': new AssetClassSummary(),
      'Hedge Funds': new AssetClassSummary(),
      'Private Markets': new AssetClassSummary(),
      Other: new AssetClassSummary(),
      'Cash & Equivalents': new AssetClassSummary()
    };
    this.portfolio = {
      totalMarketValue: 0,
      principalMarketValue: 0,
      incomeMarketValue: 0
    };
    this.holdings = {
      cost: 0,
      unrealizedGainLoss: 0,
      estAnnualIncome: 0,
      todaysChangeInTotalMarketValue: 0
    };
  }
}

export class Holdings {
  acctId: string;
  accruedIncome: number;
  assetClass: string;
  assetClassCd: string;
  assetName: string;
  currentPrice: number;
  cusip: string;
  dateLastModified: string;
  dateOfData: string;
  effDuration: string;
  estAnnualIncome: number;
  estYield: number;
  id: string;
  marketCap: string;
  marketValue: number;
  portfolioNum: number;
  qty: number;
  securityIdReporting: string;
  sortOrder: string;
  taxCost: number;
  ticker: string;
  seiAssetClassCd?: string;
  isin_Id?: string;
  unrealizedGainLoss: number;
  yieldToWorst: number;
  todaysChangePercentage?: number;
  todaysChangeValue?: number;
  displayDeltaValue?: boolean;
  schemaLevel1?: string;
  schemaLevel2?: string;
  schemaLevel3?: string;
  schemaLevel4?: string;
  allocation?: number;
}

export type Holding = Partial<Holdings> & { isFiltered?: boolean };

export type ConsolidatedHolding = Partial<Holdings> & { ids?: string[] };

export class AccountHoldings {
  totals: HoldingsTotals;
  holdings: Holdings[];
  dateOfData?: string; //preformated date string, ready for display || undefined

  constructor(_totals: HoldingsTotals = null, _holdings: Holdings[] = null) {
    this.totals = _totals;
    this.holdings = _holdings;
    let _rawDate = _holdings[0]?.dateOfData;
    if (_rawDate) {
      this.dateOfData = GlobalFormatterService.date(_rawDate);
    }
  }
}

export class IntradayHoldings {
  totals: HoldingsTotals;
  holdings: Holdings[];
  dateOfData?: string; //preformated date string, ready for display || undefined

  constructor(
    _totals: HoldingsTotals = null,
    _holdings: Holdings[] = null,
    _status: string = null,
    @Optional() _intradayDate = null
  ) {
    this.totals = _totals;
    this.holdings = _holdings;
    const defaultIntradayTime = new Date().getTime() - 15 * 60 * 1000;
    let _rawDate = _intradayDate || defaultIntradayTime;
    this.dateOfData =
      _status !== 'UNAVAIL_EOD'
        ? GlobalFormatterService.date(_rawDate, "MMMM dd, y 'at' h:mm a' EST'")
        : _holdings[0]?.dateOfData
        ? GlobalFormatterService.date(_holdings[0]?.dateOfData)
        : GlobalFormatterService.date(defaultIntradayTime);
  }
}

export class TaxLots {
  id: string;
  sourceUID: string;
  acctSourceUID: string;
  acctSourceCd: string;
  acctId: string;
  portfolioNum: string;
  assetSourceUID: string;
  assetName: string;
  acquisitionDate: string;
  holdingPeriod: string;
  qty: number;
  costBasis: number;
  currentPrice: number;
  marketValue: number;
  taxCost: number;
  shortGainLoss: number;
  longGainLoss: number;
  positionTotalQty: number;
  positionPercent: number;
  accruedInterest: number;
  yieldToMaturity: number;
  dateOfData: string;
  dateLastModified: string;
}
