import { UserAgentActions } from '@app/_actions/userAgent.actions';
import { environment } from '@env/local/environment';

export enum CODES {
  USR_CONNECTION_OFFLINE = 'E-001001-0',
  USR_CONNECTION_ONLINE = 'S-001001-2',
  USR_BROWSER_UNSUPPORTED = 'E-001002-0',
  USR_APP_INSTALL_PROMPT = 'I-001003-2',
  USR_APP_UPDATE_AVAILABLE = 'I-001004-2',

  PUSH_WARNING_EMERGENCY = 'W-001501-0',
  PUSH_WARNING_EMERGENCY_INTRADAY = 'W-001501-0-INTRADAY-ERROR' // Error should start with emergency code to pass the checks
}

export interface NotificationAction {
  url?: URL | string;
  title?: string;
  isExternal?: boolean;
  action?: new (...args: any[]) => any;
  label?: string;
  icon?: URL | string;
}

export interface NotificationConfig {
  classification?: string;
  id: string;
  title?: string;
  icon?: string;
  duration?: number;
  body?: string[];
  frequencyShown?: string;
  action?: NotificationAction;
  restrictOnUserClose?: boolean;
  utag_pageAlert?: string;
  name?: string;
  serviceTargets: string[];
}

export const NOTIFICATION_CONFIGS: [string, Partial<NotificationConfig>][] = [
  [
    CODES.USR_CONNECTION_ONLINE,
    {
      id: CODES.USR_CONNECTION_ONLINE,
      classification: 'info',
      title: 'online',
      duration: 5000,
      name: 'USR_CONNECTION_ONLINE',
      icon: ''
    }
  ],
  [
    CODES.USR_CONNECTION_OFFLINE,
    {
      classification: 'error',
      id: CODES.USR_CONNECTION_OFFLINE,
      title: 'offline',
      name: 'USR_CONNECTION_OFFLINE',
      icon: ''
    }
  ],
  [
    CODES.USR_APP_INSTALL_PROMPT,
    {
      id: CODES.USR_APP_INSTALL_PROMPT,
      classification: 'info',
      title: 'Want quick and easy access to this application?',
      frequencyShown: 'monthly',
      name: 'USR_APP_INSTALL_PROMPT',
      icon: '',
      action: {
        title: 'Install App',
        action: UserAgentActions.DoPWAInstall
      },
      restrictOnUserClose: true,
      utag_pageAlert: 'PWAInstallPrompt'
    }
  ],
  [
    CODES.USR_APP_UPDATE_AVAILABLE,
    {
      id: CODES.USR_APP_UPDATE_AVAILABLE,
      classification: 'info',
      name: 'USR_APP_UPDATE_AVAILABLE',
      title: 'Would you like to install updates now?',
      icon: '',
      restrictOnUserClose: true
    }
  ],
  [
    CODES.USR_BROWSER_UNSUPPORTED,
    {
      action: {
        title: 'more details',
        url: new URL('/systems', environment.wtuOriginURL),
        isExternal: true,
        icon: 'assets/images/icons/launch.svg'
      },

      body: [
        'The web browser you are using appears to be unsupported. Please visit our list of supported browsers in order to upgrade.'
      ],
      classification: 'warning',
      id: CODES.USR_BROWSER_UNSUPPORTED,
      title: 'Unsupported Browser',
      name: 'USR_BROWSER_UNSUPPORTED',
      restrictOnUserClose: true,
      utag_pageAlert: 'unsupportedBrowser'
    }
  ],
  [
    CODES.PUSH_WARNING_EMERGENCY,
    {
      id: CODES.PUSH_WARNING_EMERGENCY,
      classification: 'warning',
      name: 'PUSH_WARNING_EMERGENCY',
      restrictOnUserClose: true,
      utag_pageAlert: 'emergencyPushMessage'
    }
  ],
  [
    CODES.PUSH_WARNING_EMERGENCY_INTRADAY,
    {
      id: CODES.PUSH_WARNING_EMERGENCY_INTRADAY,
      classification: 'warning',
      name: 'PUSH_WARNING_EMERGENCY_INTRADAY',
      restrictOnUserClose: true,
      utag_pageAlert: 'emergencyPushMessage',
      body: ["We are experiencing an issue retrieving Today’s Pricing. Please try again later."],
      title: 'Today’s Pricing is not currently available',
      serviceTargets: [CODES.PUSH_WARNING_EMERGENCY_INTRADAY],
    }
  ]
];
