import { ERROR_OBJECT } from "../monitoring/monitoring.service";

/**
 * Used when retry limit has exceeded for Token Refresh
 *  @constant {ERROR_OBJECT}
 */
export const TOKEN_INTERCEPTOR_EXCEEDED_RETRY_LIMIT : ERROR_OBJECT = {
    code: 'E-000401-0',
    name: 'TOKEN_INTERCEPTOR_EXCEEDED_RETRY_LIMIT',
    message: '[Token Interceptor] Retry Limit for Token Refresh has been Exceeded'
};

/**
 * Used when API Request experiences server error
 *  @constant {ERROR_OBJECT}
 */
export const WTC_API_REUQEST_SERVER_ERROR : ERROR_OBJECT = {
    code: 'E-000500-0',
    name: 'WTC_API_REUQEST_SERVER_ERROR',
    message: '[Token Interceptor] WTC API Request encountered an Internal Server error'
};

/**
 * Used when API Request experiences gateway timeout error 
 * @constant {ERROR_OBJECT}
 */
export const WTC_API_REUQEST_GATEWAY_TIMEOUT_ERROR : ERROR_OBJECT = {
    code: 'E-000504-0',
    name: 'WTC_API_REUQEST_GATEWAY_TIMEOUT_ERROR',
    message: '[Token Interceptor] WTC API Request encountered an gateway timeout error'
};

/**
 * Used when API Request encounters an error
 *  @constant {ERROR_OBJECT}
 */
export const WTC_API_REUQEST_GENERAL_ERROR : ERROR_OBJECT = {
    code: 'E-004301-0',
    name: 'WTC_API_REUQEST_GENERAL_ERROR',
    message: '[Token Interceptor] WTC API Request encountered an error'
};

export const TOKEN_INTERCEPTOR_ERRORS = Object.freeze({
    TOKEN_INTERCEPTOR_EXCEEDED_RETRY_LIMIT,
    WTC_API_REUQEST_SERVER_ERROR,
    WTC_API_REUQEST_GATEWAY_TIMEOUT_ERROR,
    WTC_API_REUQEST_GENERAL_ERROR
});