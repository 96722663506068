import { Inject, Injectable } from '@angular/core';
import {
  IntradayHoldingsState,
  IntradayHoldingsStateModel
} from '@app/account/account-holdings-intraday/_state/intradayHoldings.state';
import { AggregationState, AggregationStateModel } from '@app/aggregation/_state/aggregation.state';
import { DashboardStateModel } from '@app/dashboard/_state/dashboard.state';
import { LoggerService } from '@app/logger.service';
import { GlobalConstants } from '@app/shared/services/globalConstants';
import { MetaService } from '@app/shared/services/meta.service';
import { ModalMk2Service } from '@app/shared/services/modal-mk2.service';
import { YdlState, YdlStateModel } from '@app/ydl/_state/ydl.state';
import { AccountsActions } from '@app/_actions/accounts.actions';
import { AppActions } from '@app/_actions/app.actions';
import { AuthActions } from '@app/_actions/auth.actions';
import { HoldingsActions } from '@app/_actions/holdings.actions';
import { LegalActions } from '@app/_actions/legal.actions';
import { MetaActions } from '@app/_actions/meta.actions';
import { ProfileActions } from '@app/_actions/profile.actions';
import { Environment } from 'env/ienvironment';
import { ENV } from 'env/environment.provider';
import { Action, Actions, ofActionDispatched, Selector, State, StateContext, Store } from '@ngxs/store';
import { of, from, EMPTY, concat, forkJoin } from 'rxjs';
import { switchMap, catchError, tap, first, concatWith, map, takeUntil, take } from 'rxjs/operators';
import { AccountsState } from './accounts.state';
import { AuthState } from './auth.state';
import { ProfileState } from './profile.state';

export interface MetaStateModel {
  bootstrapComplete: boolean;
}

@State<MetaStateModel>({
  name: '_meta',
  defaults: {
    bootstrapComplete: false
  }
})
@Injectable()
export class MetaState {
  constructor(
    private logger: LoggerService,
    private store: Store,
    private metaService: MetaService,
    private actions$: Actions,
    @Inject(ENV) private env: Environment
  ) {}

  //create the AppInitialization sequence observable and subscribe to it.
  private AppInitialization = (ctx: StateContext<MetaStateModel>) => {
    this.logger.info(
      'App Initialization: listener starting with current boot status = ',
      ctx.getState()?.bootstrapComplete
    );
    const signOffAction$ = this.actions$.pipe(
      ofActionDispatched(AuthActions.SignOff),
      take(1),
      tap(() => this.logger.info('App Initialization: Canceled by Sign Off'))
    );

    this.store
      .select(AuthState.isAuthenticated)
      .pipe(
        //wait for good auth before continuing
        first((_) => _),
        tap(() => this.logger.info('App Initialization: starting...')),
        //executes each action in order, waiting for completion of each before subscribing next observable
        concatWith(
          forkJoin([
            of('Accounts').pipe(
              tap((step) => this.logger.info(`App Initialization: Loading ${step}...`)),
              switchMap((step) => {
                return this.store.dispatch(new AccountsActions.Fetch()).pipe(
                  map(() => {
                    return step;
                  })
                );
              }),
              tap((step) => this.logger.info(`App Initialization: Loading ${step} Complete`)),
              concatWith(
                of('Holdings').pipe(
                  tap((step) => this.logger.info(`App Initialization: Loading ${step}...`)),
                  switchMap((step) => {
                    return this.store.dispatch(new HoldingsActions.Fetch()).pipe(
                      map(() => {
                        return step;
                      })
                    );
                  }),
                  tap((step) => this.logger.info(`App Initialization: Loading ${step} Complete`))
                )
              ),
              concatWith(
                forkJoin([
                  of('Intraday').pipe(
                    tap((step) => this.logger.info(`App Initialization: Loading ${step}...`)),
                    switchMap((step) => {
                      const memberType = this.store.selectSnapshot<Number>(ProfileState.memberType);
                      return memberType === 1 && this.env.enableFeature_intraday
                        ? this.store.dispatch(new MetaActions.LazyLoadIntradayModuleThenDispatch(null)).pipe(
                            tap((step) => this.logger.info(`App Initialization: Loading ${step} Complete`)),
                            map(() => {
                              return step;
                            })
                          )
                        : of(true).pipe(
                            tap((step) => this.logger.info(`App Initialization: Loading ${step} - not Complete`))
                          );
                    })
                  )
                ])
              )
            ),
            of('Profile').pipe(
              tap((step) => this.logger.info(`App Initialization: Loading ${step}...`)),
              switchMap((step) => {
                return this.store.dispatch(new ProfileActions.FetchProfile()).pipe(
                  map(() => {
                    return step;
                  })
                );
              }),
              tap((step) => this.logger.info(`App Initialization: Loading ${step} Complete`))
            ),
            //don't check disclosures for impersonation users as it will throw error 451
            this.env.impersonation
              ? of('Disclosures').pipe(tap((step) => this.logger.info(`App Initialization: no ${step}...`)))
              : of('Disclosures').pipe(
                  tap((step) => this.logger.info(`App Initialization: Loading ${step}...`)),
                  switchMap((step) =>
                    this.store.dispatch(new LegalActions.FetchDisclosures()).pipe(
                      map(() => {
                        return step;
                      })
                    )
                  ),
                  tap((step) => this.logger.info(`App Initialization: Loading ${step} Complete`))
                )
          ])
        ),
        takeUntil(signOffAction$),
        tap({
          next: (v) => {
            this.logger.info('App Initialization: status', v);
          },
          complete: () => {
            this.logger.info('App Initialization: Complete');
            ctx.dispatch(new AuthActions.InitTimers());
            ctx.setState({ bootstrapComplete: true });
          },
          error: (err) => {
            this.logger.warn('App Initialization: Error', err);
            ctx.setState({ bootstrapComplete: false });
          }
        })
      )
      .subscribe();
  };

  ngxsOnInit(ctx: StateContext<MetaStateModel>) {
    this.AppInitialization(ctx);
  }

  @Selector([AggregationState])
  static isAggregationModuleLoaded(state: AggregationStateModel): boolean {
    return !!state;
  }

  @Selector([YdlState])
  static isYdlModuleLoaded(state: YdlStateModel): boolean {
    return !!state;
  }

  @Selector([IntradayHoldingsState])
  static isIntradayModuleLoaded(state: IntradayHoldingsStateModel): boolean {
    return !!state;
  }

  @Selector([MetaState])
  static isInitialDataLoaded(state: MetaStateModel): boolean {
    return state.bootstrapComplete;
  }

  // Action Handlers
  @Action([AppActions.ResetState])
  onResetState(ctx: StateContext<MetaStateModel>, action: AppActions.ResetState) {
    ctx.setState({ bootstrapComplete: false });
    this.AppInitialization(ctx);
  }

  @Action(MetaActions.LazyLoadYdlModuleThenDispatch)
  onLazyLoadYdlModuleThenDispatch(
    ctx: StateContext<DashboardStateModel>,
    action: MetaActions.LazyLoadYdlModuleThenDispatch
  ) {
    return this.store.selectOnce(MetaState.isYdlModuleLoaded).pipe(
      switchMap((isYdlLoaded) => {
        return isYdlLoaded ? of(isYdlLoaded) : from(this.metaService.lazyLoadYdlModule());
      }),
      switchMap((_) => {
        return action.nextAction ? ctx.dispatch(action.nextAction) : EMPTY;
      }),
      catchError((e) => {
        this.logger.warn(e);
        return EMPTY;
      })
    );
  }

  @Action(MetaActions.LazyLoadAggregationModuleThenDispatch)
  onLazyLoadAggregationModuleThenDispatch(
    ctx: StateContext<DashboardStateModel>,
    action: MetaActions.LazyLoadAggregationModuleThenDispatch
  ) {
    let aggLoaded = this.store.selectSnapshot(MetaState.isAggregationModuleLoaded);

    if (aggLoaded) {
      return action.nextAction ? ctx.dispatch(action.nextAction) : EMPTY;
    } else {
      return from(this.metaService.lazyLoadAggregationModule()).pipe(
        tap(() => {
          if (action.nextAction) {
            ctx.dispatch(action.nextAction);
          }
        })
      );
    }
  }

  @Action(MetaActions.LazyLoadIntradayModuleThenDispatch)
  onLazyLoadIntradayModuleThenDispatch(
    ctx: StateContext<DashboardStateModel>,
    action: MetaActions.LazyLoadIntradayModuleThenDispatch
  ) {
    let intradayLoaded = this.store.selectSnapshot(MetaState.isIntradayModuleLoaded);

    if (intradayLoaded) {
      return action.nextAction ? ctx.dispatch(action.nextAction) : EMPTY;
    } else {
      return from(this.metaService.lazyLoadIntradayModule()).pipe(
        tap(() => {
          if (action.nextAction) {
            ctx.dispatch(action.nextAction);
          }
        })
      );
    }
  }
}
