import { Injectable } from '@angular/core';
import { Profile } from '@app/shared/models/profile.model';
import { ProfileService } from '@app/shared/services/profile.service';
import { AppActions } from '@app/_actions/app.actions';
import { ProfileActions } from '@app/_actions/profile.actions';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { switchMap } from 'rxjs';
import { SESSION_STORAGE_EMAIL_KEY, SESSION_STORAGE_MEMBER_TYPE_KEY } from '@app/shared/analytics/analytics-constants';

@State<Profile>({
  name: 'profile',
  defaults: null
})
@Injectable()
export class ProfileState {
  constructor(private profileService: ProfileService) {}

  ngxsOnInit(ctx: StateContext<Profile>) {
    // this.setupInitialAuthSubscription();
  }

  @Action([AppActions.ResetState])
  onResetState(ctx: StateContext<Profile[]>, action: AppActions.ResetState) {
    ctx.setState(null);
    window.sessionStorage.removeItem(SESSION_STORAGE_EMAIL_KEY);
    window.sessionStorage.removeItem(SESSION_STORAGE_MEMBER_TYPE_KEY);
  }

  @Action(ProfileActions.FetchProfile)
  onFetchProfile(ctx: StateContext<Profile>, action: ProfileActions.FetchProfile) {
    return this.profileService
      .fetchProfile()
      .pipe(switchMap((profileData) => ctx.dispatch(new ProfileActions.SetProfile(profileData))));
  }

  @Action(ProfileActions.SetProfile)
  onSetProfile(ctx: StateContext<Profile>, action: ProfileActions.SetProfile) {
    ctx.setState(action.profileData);
    window.sessionStorage.setItem(SESSION_STORAGE_EMAIL_KEY, action.profileData?.email);
    window.sessionStorage.setItem(SESSION_STORAGE_MEMBER_TYPE_KEY, action.profileData?.member.toString());
    return Promise.resolve();
  }

  @Selector([ProfileState])
  static memberType(profile: Profile) {
    return profile?.member;
  }

  @Selector([ProfileState])
  static loginId(profile: Profile) {
    return profile?.loginId;
  }

  @Selector([ProfileState])
  static profileId(profile: Profile) {
    return profile?.profileId;
  }
}
