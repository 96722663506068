import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { LoggerService } from '@app/logger.service';
import { Environment } from 'env/ienvironment';
import { ENV } from 'env/environment.provider';
import { Observable, tap } from 'rxjs';

export interface RelationshipTeamMember {
  userName: string;
  hrTitle: string;
  email: string;
  phone: string;
  hrRank: number;
  hrefPhone?: string;
}

@Injectable({
  providedIn: 'root'
})
export class RelationshipTeamService {
  constructor(
    private http: HttpClient,
    private logger: LoggerService,
    @Inject(ENV) private env: Environment) {}

  getRelationshipTeam(): Observable<RelationshipTeamMember[]> {
    const relationshipTeamEndpoint = `${this.env.pcfUrl}/getUserViewRelTeam`;
    return this.http
      .get<RelationshipTeamMember[]>(relationshipTeamEndpoint)
      .pipe(tap((obs) => this.logger.info('[Relationship Team Service] ', obs)));
  }
}
