import { RelationshipTeamMember } from '@app/shared/services/relationship-team.service';

export namespace RelationshipTeamActions {
  export class FetchRelationshipTeam {
    static readonly type = '[RelationshipTeam] Fetch Relationship Team';
    constructor() {}
  }

  export class SetRelationshipTeam {
    static readonly type = '[RelationshipTeam] Set Relationship Team';
    constructor(public team: RelationshipTeamMember[]) {}
  }
}
