<dialog #signOutDialog id="signOutDialog" aria-labelledby="signOutDialogTitle" class="backdrop">
    <div class="modal-content-panel c-modal__container u-bg--white u-border-radius--small c-modal__container--medium">
        <header class="c-modal__header l-padding--large u-bg--primary-lightest">
            <div class="c-modal__close-icon" style="z-index:900;">
                <button id="closeSignOutDialog" type="reset" (click)="closeThisDialog()"
                    class="c-modal__close-icon-button" aria-label="close the dialog window">
                    <span class="c-icon--large" aria-hidden="true">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" role="img"
                            aria-label="Close the dialog icon">
                            <title>Close the dialog icon</title>
                            <path fill="#425968"
                                d="M20 4.86L12.86 12 20 19.14l-.86.86L12 12.86 4.86 20 4 19.14 11.14 12 4 4.86 4.86 4 12 11.14 19.14 4z">
                            </path>
                        </svg>
                    </span>
                </button>
            </div>
            <h1 class="u-type--primary-base u-type--xxlarge">Sign Out</h1>
        </header>
        <div class="c-modal__body u-type--center">
            <h2 id="signOutDialogTitle" class="u-type--center u-type--secondary-dark-4 u-type--normal u-type--xxxlarge">
                Are you sure you want to sign out?
            </h2>
            <div class="c-modal__button-wrapper">
                <button (click)="confirmSignOut()"
                    class="c-button c-button--primary c-modal__button-wrapper-button u-type--medium u-type--small">
                    <span class="c-button-focus">Yes</span>
                </button>
                <button (click)="closeThisDialog()"
                    class="c-button c-button--secondary c-modal__button-wrapper-button u-type--medium u-type--small">
                    <span class="c-button-focus">No</span>

                </button>
            </div>
        </div>
        <footer class="c-modal__footer">
            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 662 212">
                <title>Background design</title>
                <defs>
                    <path id="a" d="M0 .294h792V216H0z" />
                    <path id="c" d="M0 .881h792V262H0z" />
                </defs>
                <g fill="none" fill-rule="evenodd">
                    <path stroke="#F16521" stroke-linejoin="round"
                        d="M488.887 1l-88.682 42.261v41.726l88.682 45.454V1zM341.016 141.734l59.189-30.83V84.987l-59.19-30.034v86.781z" />
                    <g transform="translate(-100 76.194)">
                        <mask id="b" fill="#fff">
                            <use xlink:href="#a" />
                        </mask>
                        <path stroke="#F16521" stroke-linecap="round" stroke-linejoin="round"
                            d="M441.016 237.64l-88.682 42.261v41.726l88.682 45.454v-129.44zm-147.87 140.734l59.188-30.83v-25.917l-59.189-30.034v86.781zM-2.598 227.397v42.66-42.66zm0 129.442v42.66-42.66zM1032.5 280.712l-88.682 42.261V364.7l88.682 45.454v-129.44zM884.63 421.447l59.188-30.83V364.7l-59.189-30.034v86.782zm147.87-11.294l-88.682 42.261v41.726l88.682 45.454v-129.44zm-88.682 83.987l-59.189-30.034 59.19 30.034zM884.63 205.223l-88.682 42.262v41.725l88.682 45.455V205.223zM736.76 345.958l59.188-30.83V289.21l-59.189-30.034v86.782zM884.63 75.783l-88.682 42.261v41.725l88.682 45.454V75.783zM736.76 216.517l59.188-30.83V159.77l-59.189-30.034v86.782zm207.06-110.7l-59.19-30.034 59.19 30.034zm-207.06-18.74l59.188-30.83v-25.92L736.758.294v86.782zm0 172.1l-88.683 42.26v41.726l88.682 45.454v-129.44zm-147.872-75.49l-88.682 42.262v41.725l88.682 45.455V183.687zm-147.87 140.735l59.188-30.83v-25.918l-59.189-30.034v86.782zm147.87-270.176l-88.682 42.262v41.725l88.682 45.454V54.247zm-147.87 140.735l59.188-30.83v-25.918l-59.19-30.033v86.782zM736.757.294l-88.682 42.261v41.726l88.682 45.454V.295zM588.888 141.03l59.188-30.83V84.281l-59.189-30.035v86.783zm-295.743 21.122l-88.682 42.261v41.726l88.682 45.455V162.151zm-147.87 140.735l59.188-30.83v-25.918l-59.189-30.034v86.782zM293.144 32.71l-88.682 42.262v41.725l88.682 45.454V32.711zm-147.87 140.735l59.188-30.83v-25.918l-59.189-30.034v86.782z"
                            mask="url(#b)" />
                    </g>
                    <path stroke="#F16521" stroke-linejoin="round"
                        d="M341.016 54.953l-88.682 42.26v41.726l88.682 45.455V54.953zm-147.87 140.734l59.188-30.83v-25.918l-59.189-30.034v86.782z" />
                    <g transform="translate(-100 30.194)">
                        <mask id="d" fill="#fff">
                            <use xlink:href="#c" />
                        </mask>
                        <path stroke="#F16521" stroke-linecap="round" stroke-linejoin="round"
                            d="M145.274 262.104l-88.682 42.261v41.726l88.682 45.454V262.104zM-2.597 402.838l59.189-30.829v-25.918l-59.19-30.034v86.781zm887.226-22.173l-88.682 42.261v41.725l88.682 45.455V380.665zm0-42.659l59.189-30.83v-25.918l-59.19-30.034v86.782zm147.87-140.735l-88.681 42.261v41.726l88.682 45.454V197.271zm-295.741-21.536l-88.682 42.262v41.725l88.682 45.454V175.735zM588.887 316.47l59.189-30.83v-25.918l-59.19-30.035v86.783zm-295.742 21.123l-88.682 42.261v41.726l88.682 45.454V337.593zM145.274 478.327l59.189-30.83V421.58l-59.19-30.035v86.782zm295.742-324.128l-88.682 42.261v41.726l88.682 45.454V154.199zM293.145 294.934l59.189-30.83v-25.918l-59.19-30.035v86.783zM145.274 132.663l-88.682 42.261v41.726l88.682 45.454V132.663zM-2.597 273.397l59.189-30.83V216.65l-59.19-30.035v86.782zm739.355 161.22l59.189 30.034-59.19-30.034zm-147.871-75.488l59.189 30.034-59.19-30.034zM884.71 121.824L796.028 76.37l88.682 45.454zm147.79 75.447l-88.682-45.454v25.918l-59.19 30.83 147.872-11.294zM736.838 46.335L648.157.881v25.918l-59.19 30.83"
                            mask="url(#d)" />
                    </g>
                </g>
            </svg>
        </footer>
    </div>
</dialog>