import { ERROR_OBJECT } from "@app/shared/monitoring/monitoring.service";

export const AUTH_STATE_NO_TOKEN_TO_REFRESH_ERROR : ERROR_OBJECT = {
    code: 'E-004401-0',
    name: 'AUTH_STATE_NO_TOKEN_TO_REFRESH_ERROR',
    message: '[AuthState.SilentTokenRefresh] There is no token to refresh'
};



export const AUTH_STATE_API_REQUEST_ERROR : ERROR_OBJECT = {
    code: 'E-004501-0',
    name: 'AUTH_STATE_API_REQUEST_ERROR',
    message: '[AuthState.SilentTokenRefresh] API Request to get a new refresh-token encountered an error'
};