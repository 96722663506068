import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { PkceChallengeGenerator } from '../utils/PkceChallengeGenerator';
import { TokenResponse, TokenResponseJson } from '@openid/appauth';
import { Store } from '@ngxs/store';
import { AuthActions } from '@app/_actions/auth.actions';
import { LoggerService } from '@app/logger.service';
import { Environment } from 'env/ienvironment';
import { ENV } from 'env/environment.provider';
import { LazyLoadService } from '@app/shared/utils/lazy-load.service';

declare global {
  interface Window {
    PfAuthnWidget: { new (...args: any[]): any };
  }
}

@Injectable({
  providedIn: 'root'
})
export class RedirectlessAuthService {
  private readonly CLIENT_ID = 'wtcapp2api';
  private readonly httpHeader: HttpHeaders = new HttpHeaders({
    'X-XSRF-Header': 'wtc-login-app',
    'Content-Type': 'application/x-www-form-urlencoded',
    'ngsw-bypass': 'true',
    Authorization: `Basic ${this.env.pingHeader}`
  });

  constructor(
    private http: HttpClient,
    private logger: LoggerService,
    private store: Store,
    @Inject(ENV) private env: Environment
  ) {}

  public async initRedirectlessWidget(hostElementID: string) {
    if (window.PfAuthnWidget) {
      return this.#initWidget(hostElementID);
    }
    return Promise.resolve().then(() => {
      this.logger.error(new Error('error loading authn widget'));
    });
  }

  #initWidget = async (hostElementID: string) => {
    const pkce = await new PkceChallengeGenerator().generateChallenge(128);
    let w = window.PfAuthnWidget;
    var authnWidget = new w(this.env.issuer_uri, {
      divId: hostElementID,
      logo: './assets/images/logo/wt-logo-165x160.jpg'
    });
    var config = {
      client_id: this.CLIENT_ID,
      response_type: 'code',
      scope: 'openid',
      code_challenge: pkce.code_challenge,
      code_challenge_method: 'S256',
      'ngsw-bypass': true,
      onAuthorizationSuccess: (response) => {
        if (response) {
          this.store.dispatch(new AuthActions.OnAuthWidgetSuccess(response.code, pkce.code_verifier));
        } else {
          this.logger.warn('Authentication Error.');
        }
      }
    };
    return authnWidget.initRedirectless(config);
  };

  public exchangeAuthorizationCode(authCode: string, pkceVerifier: string): Observable<any> {
    const tokenUrl = '/as/token.oauth2?ngsw-bypass=true';
    const body =
      'grant_type=authorization_code&code=' +
      authCode +
      '&code_verifier=' +
      pkceVerifier +
      '&client_id=' +
      this.CLIENT_ID;
    return this.http.post<TokenResponse>(`${this.env.issuer_uri}${tokenUrl}`, body, {
      headers: this.httpHeader,
      withCredentials: true
    });
  }

  public refreshTokenPOST(refreshToken: string): Observable<TokenResponseJson> {
    const tokenUrl = '/as/token.oauth2?ngsw-bypass=true';
    const body = `grant_type=refresh_token&refresh_token=${refreshToken}&client_id=${this.CLIENT_ID}`;
    return this.http.post<TokenResponseJson>(`${this.env.issuer_uri}${tokenUrl}`, body, {
      headers: this.httpHeader,
      withCredentials: true
    });
  }

  public revokeRefreshToken(refresh_token: string): Observable<any> {
    const revokeTokenUrl = `/as/revoke_token.oauth2?ngsw-bypass=true`;
    if (!refresh_token) {
      return of(false);
    }
    this.logger.info('Revoking Refresh Token - ' + refresh_token);
    const body = `token=${refresh_token}&client_id=${this.CLIENT_ID}`;
    return this.http.post<any>(`${this.env.issuer_uri}${revokeTokenUrl}`, body, {
      headers: this.httpHeader,
      withCredentials: true
    });
  }
}
