import { IntradayHoldingsData, IntradayPricingSymbols, IntradayStatus } from "../services/intraday-pricing.service";
import { InitiateIntraday } from "../_state/intradayHoldings.state";

export namespace IntradayHoldingsActions {
  export class FetchIntradayHoldings {
    static readonly type = '[Intraday] Fetch Intraday Holdings';
    constructor(public symbols: IntradayPricingSymbols[])  {}
  }

  export class SetIntradayHoldings {
    static readonly type = '[Intraday] Set Intraday Holdings';
    constructor(public data: IntradayHoldingsData) {}
  }

  export class SetIntradayHoldingsError {
    static readonly type = '[Intraday] Error in fetching Intraday Holdings';
    constructor() {}
  }

  export class ClearIntradayHoldings {
    static readonly type = '[Intraday] Clear Intraday Holdings';
    constructor() {}
  }

  export class FetchIntradayStatus {
    static readonly type = '[Intraday] Fetch Intraday Status';
    constructor()  {}
  }

  export class SetIntradayStatus {
    static readonly type = '[Intraday] Set Intraday Status';
    constructor(public data: IntradayStatus) {}
  }

  export class SetIntradayStatusError {
    constructor() {}
    static readonly type = '[Intraday] Error in fetching Intraday Status';
  }

  export class FetchIntradayStatusAndThenPricing {
    constructor(public params: InitiateIntraday) {}
    static readonly type = '[Intraday] Fetch IntradayStatus And Then Pricing';
  }
}
