import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { LegalDisclosure, LegalDisclosureUpdateResponse } from '@app/shared/models/legal-disclosures.model'
import { LoggerService } from '@app/logger.service';
import { Environment } from 'env/ienvironment';
import { ENV } from 'env/environment.provider';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class LegalService {
  private readonly legalDisclosuresServiceUrls = {
    disclosuresEndpoint: `${this.env.pcfUrl}/disclosures`,
    acceptDisclosuresEndpoint: `${this.env.pcfUrl}/acceptdisclosures`
  };

  constructor(
    private http: HttpClient,
    private logger: LoggerService,
    @Inject(ENV) private env: Environment) {}

  fetchLegalDisclosures(): Observable<LegalDisclosure[]> {
    return this.http.get<LegalDisclosure[]>(`${this.legalDisclosuresServiceUrls.disclosuresEndpoint}`).pipe(
      map((v) => {
        return this.formatLegalDisclosuresResponse(v);
      })
    );
  }

  acceptLegalDisclosures(disclosuresToUpdate: LegalDisclosure[]): Observable<LegalDisclosureUpdateResponse[]> {
    return this.http.post<LegalDisclosureUpdateResponse[]>(
      `${this.legalDisclosuresServiceUrls.acceptDisclosuresEndpoint}`,
      disclosuresToUpdate
    );
  }

  private formatLegalDisclosuresResponse(legalDisclosuresArray: LegalDisclosure[]): LegalDisclosure[] {
    const formatedLegalDisclosuresArray = legalDisclosuresArray.map<LegalDisclosure>((disclosureObject) => {
      if (disclosureObject.id === 11) {
        return {
          ...disclosureObject,
          agreeClauseUrl: 'online-access-agreement',
          title: 'Wilmington Trust Online Access Disclosure Agreement (Online Agreement)',
          description:
            'The Online Agreement contains the terms and conditions applicable to Online Service, which includes On-Line Portfolio.',
          input: {
            checked: false,
            label: 'I have read and accept the Wilmington Trust Online Access Disclosure Agreement.'
          }
        };
      } else if (disclosureObject.id === 17) {
        return {
          ...disclosureObject,
          agreeClauseUrl: 'on-line-statements-agreement',
          title: 'On-Line Statements Disclosure (OLS Disclosure)',
          description: `The OLS Disclosure contains the terms and conditions specific to the electronic account statement service provided through On-Line Statements.
              With your agreement, we may provide statements to you electronically. The OLS Disclosure describes how we will provide statements to you electronically,
              what system requirements you need to access statements electronically and how to terminate enrollment in electronic statement service if you change your mind.`,
          input: {
            checked: false,
            label: 'I have read and accept the On-Line Statements Disclosure.'
          }
        };
      } else if (disclosureObject.id === 25) {
        return {
          ...disclosureObject,
          agreeClauseUrl: 'mbms-agreement',
          title: 'MBMS User Online Agreement',
          description: '',
          input: {
            checked: false,
            label: 'I have read and accept the MBMS User Online Agreement.'
          }
        };
      } else if (disclosureObject.id === 27) {
        return {
          ...disclosureObject,
          agreeClauseUrl: 'esign-agreement',
          title: 'Consent to Receive Information Electronically (ESign Consent)',
          description: `The ESign Consent gives us permission to provide documents to you in an electronic format and contains important information
              about how we will deliver information to you. The ESign Consent describes what system requirements you need to access and retain documents
              that we will provide to you electronically, how we will provide those documents to you and how to withdraw your consent to receive documents
              electronically if you change your mind and what that means if you do.`,
          input: {
            checked: false,
            label: 'I have read and accept the Consent to Receive Information Electronically.'
          }
        };
      } else if (disclosureObject.id === 28) {
        return {
          ...disclosureObject,
          agreeClauseUrl: 'digital-services-agreement',
          title: 'Wilmington Trust Digital Services Agreement (DSA)',
          description: 'The DSA contains the terms and conditions applicable to Digital Service.',
          input: {
            checked: false,
            label: 'I have read and accept the Wilmington Trust Digital Services Agreement.'
          }
        };
      } else if (disclosureObject.id === 29) {
        return {
          ...disclosureObject,
          agreeClauseUrl: 'eVault-agreement',
          title: 'Wilmington Trust Electronic Vault Terms of Use',
          description: 'The agreement contains the terms and conditions applicable to Electronic Vault.',
          input: {
            checked: false,
            label: 'I have read and accept the Wilmington Trust Electronic Vault Terms of Use.'
          }
        };
      } else {
        return {
          ...disclosureObject,
          input: {
            checked: false,
            label: 'I have read and accept the Agreement.'
          }
        };
      }
    });

    // Filters out any empty objects, this needs to be fixed in the service call
    return formatedLegalDisclosuresArray.filter((value) => Object.keys(value).length !== 0);
  }
}
