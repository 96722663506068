import { Injectable, ComponentRef, EventEmitter, OnInit, Directive } from '@angular/core';
import {
  Overlay,
  OverlayPositionBuilder,
  ScrollStrategyOptions,
  OverlayRef,
  PositionStrategy,
  OverlayConfig,
  ScrollStrategy
} from '@angular/cdk/overlay';
import { ComponentPortal, ComponentType } from '@angular/cdk/portal';
import { Subscription, merge, Observable, fromEvent } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { LoggerService } from '@app/logger.service';

@Injectable({
  providedIn: 'root'
})
export class ModalMk2Service {
  private modalPortals: Map<ComponentType<any>, ComponentPortal<ModalInterface>>;
  private subscriptions: Map<ComponentType<any>, Subscription[]>;
  private modalConfigs: Map<ComponentType<any>, ModalConfig>;
  //one shared overlayRef for all modals
  private overlayRef: OverlayRef;
  private defaultPositionStrategy: PositionStrategy;
  private defaultScrollStrategy: ScrollStrategy;
  private defaultOverlayConf: OverlayConfig;
  private escKeyEvent$: Observable<boolean>;
  settings = {};

  public get overlayPositionBuilder(): OverlayPositionBuilder {
    return this._overlayPositionBuilder;
  }

  public get scrollStrategyOptions(): ScrollStrategyOptions {
    return this._scrollStrategyOptions;
  }

  constructor(
    private overlay: Overlay,
    private _overlayPositionBuilder: OverlayPositionBuilder,
    private _scrollStrategyOptions: ScrollStrategyOptions,
    private logger: LoggerService
  ) {
    this.settings = Object.assign({}, this.settings);
    this.modalPortals = new Map<ComponentType<any>, ComponentPortal<ModalInterface>>();
    this.subscriptions = new Map<ComponentType<any>, Subscription[]>();
    this.modalConfigs = new Map<ComponentType<any>, ModalConfig>();
    this.defaultPositionStrategy = this._overlayPositionBuilder.global().centerHorizontally().centerVertically();
    this.defaultScrollStrategy = this._scrollStrategyOptions.block();
    this.defaultOverlayConf = {
      positionStrategy: this.defaultPositionStrategy,
      hasBackdrop: true,
      backdropClass: 'modal-overlay-backdrop--dark',
      panelClass: [],
      scrollStrategy: this.defaultScrollStrategy
    };
    this.overlayRef = this.initOverlay(this.defaultOverlayConf);
    this.escKeyEvent$ = fromEvent(window, 'keydown').pipe(
      filter((event: KeyboardEvent) => {
        const key = event.key;
        const escapeCodes = new Set(['Escape', 'Esc']);
        if (escapeCodes.has(key)) {
          return true;
        }
        return false;
      }),
      map(() => true)
    );
  }

  add<T extends ModalBaseClass>(componentType: ComponentType<T>, conf?: ModalConfig) {
    if (!this.modalPortals.has(componentType)) {
      // Create Portal for componentType
      const _portal = new ComponentPortal(componentType);
      // Attach portal to overlay
      this.modalPortals.set(componentType, _portal);
    }
    if (conf) {
      this.modalConfigs.set(componentType, conf);
    } else {
      this.modalConfigs.set(componentType, { configOverrides: {} });
    }
  }

  remove<T extends ModalBaseClass>(componentType: ComponentType<T>) {
    this.modalPortals.delete(componentType);
    (this.subscriptions.get(componentType) || []).forEach((sub) => sub.unsubscribe());
    this.subscriptions.delete(componentType);
    this.modalConfigs.delete(componentType);
  }

  open<T extends ModalBaseClass>(
    componentType: ComponentType<T>,
    params?: { [P in keyof T]?: T[P] },
    modalConf?: ModalConfig
  ) {
    let _portal = this.modalPortals.get(componentType);
    if (!_portal) {
      //add it to the service cache if not already
      this.add(componentType, modalConf);
      _portal = this.modalPortals.get(componentType);
    }

    if (params) {
      let supportDialogs: boolean = params['supported'];
      if (!supportDialogs) {
      }
    }

    //override config if needed, will rebuild the overlay
    let _conf: ModalConfig;
    if (modalConf) {
      _conf = modalConf;
    } else {
      _conf = this.modalConfigs.get(componentType);
    }

    const closeStreamSources: Observable<any>[] = [];
    if (_conf) {
      if (_conf.configOverrides) {
        this.overlayRef = this.updateOverlayConf(_conf.configOverrides, this.overlayRef);
      } else {
        this.overlayRef = this.updateOverlayConf(this.defaultOverlayConf, this.overlayRef);
      }
      if (_conf.closeOnBackdropClick === undefined || _conf.closeOnBackdropClick) {
        closeStreamSources.push(this.overlayRef.backdropClick());
      }
      if (_conf.closeOnEscapeKey === undefined || _conf.closeOnEscapeKey) {
        closeStreamSources.push(this.escKeyEvent$);
      }
    } else {
      //if no conf, assume esc
      closeStreamSources.push(this.overlayRef.backdropClick());
      closeStreamSources.push(this.escKeyEvent$);
    }
    const _componentRef: ComponentRef<ModalInterface> = this.overlayRef.attach(_portal);
    const _instance = _componentRef.instance;
    closeStreamSources.push(_instance.doClose.asObservable());
    //map component input props to component instance
    if (params) {
      Object.keys(params).forEach((key) => {
        _instance[key] = params[key];
      });
    }
    //set up listener for close events
    const closeSubscription = merge(...closeStreamSources)
      .pipe(map(() => true))
      .subscribe((doClose: boolean) => {
        doClose && this.close(componentType);
      });
    const subs = this.subscriptions.get(componentType) || [];
    subs.push(closeSubscription);
    this.subscriptions.set(componentType, subs);
  }

  close<T extends ModalBaseClass>(componentType: ComponentType<T>) {
    const subs = this.subscriptions.get(componentType) || [];
    if (subs.length > 0) {
      this.overlayRef.detach();
      subs.forEach((sub) => sub.unsubscribe());
      this.subscriptions.set(componentType, []);
    } else {
      this.logger.warn(`[ModalService] close called for ${componentType.toString()} but wasn't open`);
    }
  }

  closeAnyOpen(){
    this.overlayRef.detach();
    this.subscriptions.forEach((subList)=>{
      subList.forEach((sub) => sub.unsubscribe());
    });
    this.subscriptions.clear();
  }

  private updateOverlayConf(conf: OverlayConfig, overlayRef: OverlayRef): OverlayRef {
    //rebuild overlay completely if given new conf. If this is very slow, its possible to only make edits to existing overlayRef, but the logic is more tedius
    const defaultConf = Object.assign({}, this.defaultOverlayConf);
    const mergedConf = Object.assign(defaultConf, conf);
    overlayRef.dispose();
    return this.overlay.create(mergedConf);
  }

  private initOverlay(conf: OverlayConfig): OverlayRef {
    return this.overlay.create(conf);
  }
}

export interface ModalConfig {
  closeOnEscapeKey?: boolean;
  closeOnBackdropClick?: boolean;
  configOverrides?: OverlayConfig;
}

export interface ModalInterface {
  requestClose(): void;
  doClose: EventEmitter<boolean>;
}

@Directive()
export class ModalBaseClass implements OnInit, ModalInterface {
  doClose: EventEmitter<boolean> = new EventEmitter<boolean>();
  constructor() {}
  ngOnInit() {}
  requestClose() {
    this.doClose.emit(true);
  }
}
