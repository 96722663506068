import { Inject, Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
  HttpContextToken
} from '@angular/common/http';
import { EMPTY, Observable, of, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { LoggerService } from '@app/logger.service';
import { Environment } from 'env/ienvironment';
import { ENV } from 'env/environment.provider';
import { ErrorHandlingService, INTERNAL_ERRORS } from '@shared/services/error-handling.service';
import { Store } from '@ngxs/store';
import { AuthActions } from '@app/_actions/auth.actions';
import { AuthState, AuthStateModel } from '@app/_state/auth.state';
import { MonitoringService } from '../monitoring/monitoring.service';
import { TOKEN_INTERCEPTOR_ERRORS } from './token-interceptor-error-constants';

export const _401_RETRY_COUNT = new HttpContextToken(() => 1);

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(
    private logger: LoggerService,
    private errorHandlingService: ErrorHandlingService,
    private monitoringService: MonitoringService,
    private store: Store,
    @Inject(ENV) private env: Environment
  ) {}
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const authState$ = this.store.selectOnce<AuthStateModel>(AuthState);
    const isWtcApiRequest = request.url.indexOf(this.env.pcfUrl) !== -1;
    const context = request.context;
    const retriesLeft = context.get(_401_RETRY_COUNT);
    if (!isWtcApiRequest) {
      //not a WTC api request, let it thru
      return next.handle(request);
    }

    //observable that dispatchs a refresh action and tries this request again with new auth, less 1 retry
    const switchToRefreshedAuthState$ = of(true).pipe(
      switchMap(() => this.store.dispatch(new AuthActions.SilentTokenRefresh())),
      switchMap(() => {
        //once the refresh actions completes, switch to the authState
        return this.store.selectOnce<AuthStateModel>(AuthState);
      }),
      switchMap((authState) => {
        if (authState?.tokenResponse?.access_token) {
          // use the new access_token, if we have it
          return next.handle(
            request.clone({
              setHeaders: {
                Authorization: `Bearer ${authState.tokenResponse.access_token}`,
                'ngsw-bypass': 'true'
              },
              context: context.set(_401_RETRY_COUNT, retriesLeft - 1)
            })
          );
        } else {
          //if we don't have it, directly throw a 401
          return throwError(() => new HttpErrorResponse({ error: 'No Access Token After Refresh', status: 401 }));
        }
      })
    );

    if (retriesLeft > 0) {
      return authState$.pipe(
        switchMap((authState) => {          
          //using the authState to determine next step
          if (authState?.tokenResponse?.access_token) {
            //if authState has an access_token, use it
            return next.handle(
              request.clone({
                setHeaders: {
                  Authorization: `Bearer ${authState.tokenResponse.access_token}`,
                  'ngsw-bypass': 'true'
                }
              })
            );
          } else if (authState?.tokenResponse?.refresh_token) {
            //if the authState has a refresh_token
            return switchToRefreshedAuthState$;
          } else {
            this.logger.warn('[interceptor] no refresh token available');
            return EMPTY;
          }
        }),
        //handling the error response here
        catchError((error: HttpErrorResponse) => {
          this.logger.warn('[interceptor.catchError]', error);
          switch (error.status) {
            case 401: {
              if (context.get(_401_RETRY_COUNT) > 0) {
                //we can still retry, so refresh auth if we can
                return switchToRefreshedAuthState$;
              } else {
                this.logger.warn('[interceptor] exceeded retry limit');
                this.monitoringService.postAlertsToDynatrace(TOKEN_INTERCEPTOR_ERRORS.TOKEN_INTERCEPTOR_EXCEEDED_RETRY_LIMIT);
                return this.store.dispatch(new AuthActions.SignOff());
              }
            }
            case 500: {
              this.monitoringService.postAlertsToDynatrace(TOKEN_INTERCEPTOR_ERRORS.WTC_API_REUQEST_GATEWAY_TIMEOUT_ERROR);
              this.errorHandlingService.pageLoadError(INTERNAL_ERRORS.serverError);
              return;
            }
            case 504: {
              this.monitoringService.postAlertsToDynatrace(TOKEN_INTERCEPTOR_ERRORS.WTC_API_REUQEST_GATEWAY_TIMEOUT_ERROR);
              this.errorHandlingService.pageLoadError(INTERNAL_ERRORS.gatewayTimeout);
              return;
            }
            default: {
              this.monitoringService.postAlertsToDynatrace(TOKEN_INTERCEPTOR_ERRORS.WTC_API_REUQEST_GENERAL_ERROR);
              return throwError(() => error);
            }
          }
        })
      );
    } else {
      this.logger.warn('[interceptor] exceeded retry limit');
      this.monitoringService.postAlertsToDynatrace(TOKEN_INTERCEPTOR_ERRORS.TOKEN_INTERCEPTOR_EXCEEDED_RETRY_LIMIT);
      return this.store.dispatch(new AuthActions.SignOff());
    }
  }
}
