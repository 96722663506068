import { ClientAccountsSubAccounts, ClientAccountsAccountGroup, ClientAccountsRoot } from "../models/client-accounts.model";

export function sortAccounts(accts: ClientAccountsSubAccounts[]): ClientAccountsSubAccounts[] {
    return !accts
      ? accts
      : [...accts].sort((a, b) => {
          return a.acctId > b.acctId ? 1 : a.acctId === b.acctId ? 0 : -1;
        });
  }

  export function sortGroups(acctGroups: ClientAccountsAccountGroup[]): ClientAccountsAccountGroup[] {
    return !acctGroups
      ? acctGroups
      : [...acctGroups].sort((a, b) => {
          return a.acctGroupInfo.groupName > b.acctGroupInfo.groupName
            ? 1
            : a.acctGroupInfo.groupName === b.acctGroupInfo.groupName
            ? 0
            : -1;
        });
  }

  export function sortSections(sections: ClientAccountsRoot[]): ClientAccountsRoot[] {
    return sections.sort((a, b) => {
      return a.sortOrder - b.sortOrder;
    });
  }

  export function chunkArrayToSize<T>(origArray: Array<T>, desiredChunkSize: number): Array<Array<T>> {
    let arrayOfChunks = [];
    for(let i = 0; i < origArray.length; i += desiredChunkSize) {
      arrayOfChunks.push(origArray.slice(i, i+desiredChunkSize));
    }
    return arrayOfChunks;
  }