import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Router } from '@angular/router';
import { LoggerService } from '@app/logger.service';
import { RedirectlessAuthService } from '@app/login/services/redirectless-auth.service';
import { ModalMk2Service } from '@app/shared/services/modal-mk2.service';
import { TokenMaintenanceService } from '@app/shared/services/token-maintenance.service';
import { TimeoutComponent } from '@app/timeout/timeout/timeout.component';
import { AppActions } from '@app/_actions/app.actions';
import { AuthActions } from '@app/_actions/auth.actions';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { TokenResponseJson } from '@openid/appauth';
import { EMPTY, from } from 'rxjs';
import { catchError, finalize, first, switchMap, tap } from 'rxjs/operators';
import { MetaState } from './meta.state';
import { MonitoringService } from '@app/shared/monitoring/monitoring.service';
import { AUTH_STATE_API_REQUEST_ERROR, AUTH_STATE_NO_TOKEN_TO_REFRESH_ERROR } from './authStateErrorConstants';

export enum AUTH_STATE_STATUS {
  'AUTHENTICATED' = 'AUTHENTICATED',
  'REFRESHING' = 'REFRESHING',
  'SIGN_OFF' = 'SIGN_OFF',
  'UNAUTHENTICATED' = 'UNAUTHENTICATED'
}
export interface AuthStateModel {
  tokenResponse: TokenResponseJson;
  authStatus: AUTH_STATE_STATUS;
}

const defaultState: AuthStateModel = {
  tokenResponse: null,
  authStatus: AUTH_STATE_STATUS.UNAUTHENTICATED
};

@State<AuthStateModel>({
  name: 'auth',
  defaults: defaultState
})
@Injectable()
export class AuthState {
  constructor(
    private logger: LoggerService,
    private authService: RedirectlessAuthService,
    private tokenMaintananceService: TokenMaintenanceService,
    private monitoringService: MonitoringService,
    private router: Router,
    private store: Store,
    private modalService: ModalMk2Service,
    @Inject(DOCUMENT) public _document: Document
  ) {}

  @Action([AppActions.ResetState])
  onResetState(ctx: StateContext<AuthStateModel>, action: AppActions.ResetState) {
    ctx.setState(defaultState);
    return Promise.resolve();
  }

  @Action(AuthActions.SetTokenResponse)
  onSetTokenResponse(ctx: StateContext<AuthStateModel>, action: AuthActions.SetTokenResponse) {
    const nextStatus =
      action.tokenResponse?.access_token || action.tokenResponse?.refresh_token
        ? AUTH_STATE_STATUS.AUTHENTICATED
        : AUTH_STATE_STATUS.UNAUTHENTICATED;
    ctx.patchState({
      tokenResponse: action.tokenResponse,
      authStatus: nextStatus
    });
    return Promise.resolve();
  }

  @Action(AuthActions.InitRedirectlessLoginFlow)
  onInitRedirectlessLoginFlow(ctx: StateContext<AuthStateModel>, action: AuthActions.InitRedirectlessLoginFlow) {
    return from(this.authService.initRedirectlessWidget(action.hostElementDomID)).pipe(
      switchMap(() => {
        return this.store.select(MetaState.isInitialDataLoaded);
      }),
      first((_) => _),
      tap(() => {
        this.logger.info('App Initialization: Routing to dashboard');
        this.router.navigate(['./dashboard']);
      })
    );
  }

  @Action(AuthActions.InitTimers)
  onInitTimers(ctx: StateContext<AuthStateModel>, action: AuthActions.InitTimers) {
    this.tokenMaintananceService.initTimers();
    return Promise.resolve();
  }

  @Action(AuthActions.ImpInit)
  onImpInit(ctx: StateContext<AuthStateModel>, action: AuthActions.ImpInit) {
    return ctx.dispatch(new AuthActions.SetTokenResponse({ access_token: action.authCode, token_type: 'bearer' })).pipe(
      switchMap(() => {
        return this.store.select(MetaState.isInitialDataLoaded);
      }),
      first((_) => _),
      tap((_) => {
        this.logger.info('[onImpInit] routing to ' + action.destinationUrl);
        action.destinationUrl && this.router.navigateByUrl(this.router.parseUrl(action.destinationUrl));
      })
    );
  }

  @Action(AuthActions.OnAuthWidgetSuccess)
  OnAuthWidgetSuccess(ctx: StateContext<AuthStateModel>, action: AuthActions.OnAuthWidgetSuccess) {
    return this.authService.exchangeAuthorizationCode(action.authCode, action.pkceVerifier).pipe(
      switchMap((tokenResponse) => {
        return ctx.dispatch(new AuthActions.SetTokenResponse(tokenResponse));
      })
    );
  }

  @Action(AuthActions.SignOff)
  onSignOff(ctx: StateContext<AuthStateModel>, action: AuthActions.SignOff) {
    if (ctx.getState()?.authStatus === AUTH_STATE_STATUS.SIGN_OFF) {
      return Promise.resolve();
    } else {
      ctx.patchState({
        authStatus: AUTH_STATE_STATUS.SIGN_OFF
      });
      const refreshToken = ctx.getState()?.tokenResponse?.refresh_token;

      return this.authService.revokeRefreshToken(refreshToken).pipe(
        switchMap(() => {
          return ctx.dispatch(new AppActions.ResetState());
        }),
        tap(() => {
          this.modalService.closeAnyOpen();
          sessionStorage?.clear();
          this.logger.info('CLEARED SESSION');
          this._document.location.reload();
        })
      );
    }
  }

  @Action(AuthActions.SilentTokenRefresh)
  onSilentTokenRefresh(ctx: StateContext<AuthStateModel>, action: AuthActions.SilentTokenRefresh) {
    const refreshToken = ctx.getState()?.tokenResponse?.refresh_token;
    const authStatus = ctx.getState()?.authStatus;
    this.logger.info('[AuthState.SilentTokenRefresh] start', authStatus);
    if (authStatus === AUTH_STATE_STATUS.REFRESHING) {
      //if request for refresh comes in while a refresh is happening, just wait until the state updates
      return this.store
        .select(AuthState)
        .pipe(first((state: AuthStateModel) => state.authStatus !== AUTH_STATE_STATUS.REFRESHING));
    }
    if (refreshToken) {
      //we have a refresh token, and we are not already refreshing, so set the flag and try
      ctx.patchState({
        authStatus: AUTH_STATE_STATUS.REFRESHING
      });
      return this.authService.refreshTokenPOST(refreshToken).pipe(
        catchError((err) => {
          this.logger.warn('[AuthState.SilentTokenRefresh] error', err); 
          this.monitoringService.postAlertsToDynatrace(AUTH_STATE_API_REQUEST_ERROR);        
          return ctx.dispatch(new AuthActions.SignOff()).pipe(switchMap(() => EMPTY));
        }),
        switchMap((tokenResponse) => {
          //keep the old refresh_token, but let a new one in the response override if supplied
          let newTokenRespClone = Object.assign(
            { refresh_token: ctx.getState()?.tokenResponse?.refresh_token },
            tokenResponse
          );
          return ctx.dispatch(new AuthActions.SetTokenResponse(newTokenRespClone));
        })
      );
    } else {
      this.monitoringService.postAlertsToDynatrace(AUTH_STATE_NO_TOKEN_TO_REFRESH_ERROR);     
      throw new Error('[AuthState.SilentTokenRefresh] no token to refresh');
    }
  }

  @Action(AuthActions.StartSessionTimeout)
  onStartSessionTimeout(ctx: StateContext<AuthStateModel>, action: AuthActions.StartSessionTimeout) {
    this.modalService.open(TimeoutComponent, null, { closeOnBackdropClick: false });
  }

  @Selector([AuthState])
  static refreshToken(state: AuthStateModel) {
    return state?.tokenResponse?.refresh_token;
  }

  @Selector([AuthState])
  static accessToken(state: AuthStateModel) {
    return state?.tokenResponse?.access_token;
  }

  @Selector([AuthState])
  static isAuthenticated(state: AuthStateModel) {
    const isAuthStatusGood = [AUTH_STATE_STATUS.AUTHENTICATED, AUTH_STATE_STATUS.REFRESHING].includes(
      state?.authStatus
    );
    const doWeHaveTokens = !!(state?.tokenResponse?.access_token || state?.tokenResponse?.refresh_token);
    return isAuthStatusGood && doWeHaveTokens;
  }
}
