import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of, forkJoin } from 'rxjs';
import { Environment } from 'env/ienvironment';
import { ENV } from 'env/environment.provider';
import { map } from 'rxjs/operators';
import { Holdings, TaxLots } from '@shared/models/client-holdings.model';
import { mapAssetClassToHoldings } from './shared/services/asset-code-mapper.service';
import { HoldingsTimePeriod } from './shared/services/globalConstants';
import { chunkArrayToSize } from './shared/utils/sorting-utils';

export interface HoldingsSearchRequestBody {
  acctnum: string;
  acctsrc: 'SEI' | 'PER';
  timePeriod?: HoldingsTimePeriod;
}

@Injectable({
  providedIn: 'root'
})
export class ClientHoldingsService {
  private readonly HOLDINGS_SEARCH_URL = '/allholdings/search';
  private taxLotsUrl = '/lots?id=';
  private multipleTaxLotsUrl = '/lots/search';

  constructor(
    private http: HttpClient,
    @Inject(ENV) private env: Environment
    ) {}

  #fetchHoldingsSimple(acctids: string[], timePeriod: HoldingsTimePeriod = null): Observable<Holdings[]> {
    let url = this.env.pcfUrl + this.HOLDINGS_SEARCH_URL;
    let req: HoldingsSearchRequestBody = {
      acctnum: acctids.join(','),
      acctsrc: 'SEI'
    };
    if (timePeriod) {
      req.timePeriod = timePeriod;
    }
    return this.http.post<Holdings[]>(url, req).pipe(map((h) => mapAssetClassToHoldings(h)));
  }

  fetchHoldings(acctids: string[], timePeriod: HoldingsTimePeriod = null): Observable<Holdings[]> {
    if (acctids?.length === 0) {
      return of([]);
    }
    if (acctids?.length > 500) {
      let chunkedAcctNums = chunkArrayToSize(acctids, 500);
      return forkJoin(chunkedAcctNums.flatMap((chunk) => this.#fetchHoldingsSimple(chunk))).pipe(
        map((chunkedResults) => chunkedResults.flat(1))
      );
    } else {
      return this.#fetchHoldingsSimple(acctids, timePeriod);
    }
  }

  fetchTaxLots(holdingIds: string | string[]): Observable<TaxLots[]> {
    if (!holdingIds || holdingIds.length === 0) {
      return of([]);
    }
    if (Array.isArray(holdingIds)) {
      let req = {
        ids: holdingIds
      };
      return this.http.post<TaxLots[]>(this.env.pcfUrl + this.multipleTaxLotsUrl, req);
    } else {
      return this.http.get<TaxLots[]>(this.env.pcfUrl + this.taxLotsUrl + holdingIds);
    }
  }
}
