import { Inject, Injectable } from '@angular/core';
import { LoggerService } from '@app/logger.service';
import { ClientAccountsAccountGroup, ClientAccountsSubAccounts } from '@app/shared/models/client-accounts.model';
import { ErrorHandlingService } from '@app/shared/services/error-handling.service';
import { GlobalConstants } from '@app/shared/services/globalConstants';
import { AppActions } from '@app/_actions/app.actions';
import { MetaState } from '@app/_state/meta.state';
import { Environment } from 'env/ienvironment';
import { ENV } from 'env/environment.provider';
import { Action, createSelector, Selector, State, StateContext, Store } from '@ngxs/store';
import { catchError, EMPTY, first, map, of, switchMap, tap } from 'rxjs';
import { refreshingStatusCodes } from '../aggregation.const';
import { AggregationService } from '../aggregation.service';
import { AggregationActions } from '../_actions/aggregation.actions';
import {
  AggAccount,
  AggFinSummary,
  AggFinSumSection,
  AggProvider,
  AggProviderAccount,
  AggProviderAccountsByIdMap
} from '../_models/AggFinSummary';
import { AccountsActions } from '@app/_actions/accounts.actions';

export interface AggregationStateModel {
  aggFinSummary: AggFinSummary;
  providerAccountsMap: AggProviderAccountsByIdMap;
  enableAutoRefresh: boolean;
  filteredAggFinSummary?: AggFinSummary;
  pendingFilterRequest?: boolean;
  aggError?: boolean;
}

const defaultState: AggregationStateModel = {
  aggFinSummary: undefined,
  providerAccountsMap: undefined,
  enableAutoRefresh: false,
  pendingFilterRequest: false,
  aggError: false
};

@State<AggregationStateModel>({
  name: 'aggregation',
  defaults: defaultState
})
@Injectable()
export class AggregationState {
  private refreshAccountsTimeoutHandle;
  private hasRefreshCallBeenMadeThisSession: boolean = false;

  constructor(
    private logger: LoggerService,
    private errorService: ErrorHandlingService,
    private aggregationService: AggregationService,
    private store: Store,
    @Inject(ENV) private env: Environment
  ) {}

  private setupInitialAuthSubscription = () => {
    this.store
      .select(MetaState.isInitialDataLoaded)
      .pipe(first((_) => _))
      .subscribe((_) => {
        this.store.dispatch(new AggregationActions.Fetch());
      });
  };

  ngxsOnInit(ctx: StateContext<AggregationStateModel>) {
    this.setupInitialAuthSubscription();
  }

  @Action([AppActions.ResetState])
  onResetState(ctx: StateContext<AggregationStateModel>, action: AppActions.ResetState) {
    ctx.setState(defaultState);
    this.setupInitialAuthSubscription();
  }

  @Action([AggregationActions.Fetch, AccountsActions.Fetch], { cancelUncompleted: true })
  onFetchAggregation(ctx: StateContext<AggregationStateModel>, action: AggregationActions.Fetch) {
    return this.aggregationService.fetchAggregationData(null, action.dataSrc).pipe(
      tap((aggFinSummary) => {
        ctx.dispatch(new AggregationActions.SetAggregationState(aggFinSummary));
      }),
      catchError((errorCode) => {
        ctx.patchState({ aggError: true });
        this.logger.error(errorCode);
        return of([]);
      })
      // Disable auto refresh behavior for now; preservering temporarily as needs evolve
      // ,
      // switchMap(() =>
      //   ctx.getState().enableAutoRefresh ? this.store.selectOnce(AggregationState.refreshingAccounts) : of([])
      // ),
      // tap((refreshingAccts) => {
      //   //call fetch in 30s if there is 1 or more accounts refreshing
      //   if (this.refreshAccountsTimeoutHandle) {
      //     this.logger.info('[onFetchAggregation] clearing pending timeout');
      //     clearTimeout(this.refreshAccountsTimeoutHandle);
      //     this.refreshAccountsTimeoutHandle = null;
      //   }
      //   if (refreshingAccts.length > 0) {
      //     this.logger.info('[onFetchAggregation] accountsRefreshingCount', refreshingAccts.length);
      //     this.refreshAccountsTimeoutHandle = setTimeout(() => {
      //       this.refreshAccountsTimeoutHandle = null;
      //       ctx.dispatch(new AggregationActions.Fetch());
      //     }, 30000);
      //   }
      // })
    );
  }

  @Action(AggregationActions.Fetch, { cancelUncompleted: true })
  onFetchAggProviders(ctx: StateContext<AggregationStateModel>, action: AggregationActions.Fetch) {
    return this.aggregationService.fetchProviderAccountData(action.dataSrc).pipe(
      map((providerAccounts) => {
        try {
          return providerAccounts.reduce((acc, item) => {
            acc[item.id] = item;
            return acc;
          }, {} as AggProviderAccountsByIdMap);
        } catch (e) {
          return {} as AggProviderAccountsByIdMap;
        }
      }),
      tap((providerAccounts) => {
        ctx.dispatch(new AggregationActions.SetAggregationProviderAccounts(providerAccounts));
      }),
      catchError((errorCode) => {
        ctx.patchState({ aggError: true });
        this.logger.error(errorCode);
        return of([]);
      })
    );
  }

  @Action(AggregationActions.FilterAggregationByAccounts, { cancelUncompleted: true })
  onFilterAggregationByAccounts(
    ctx: StateContext<AggregationStateModel>,
    action: AggregationActions.FilterAggregationByAccounts
  ) {
    if (action.ids) {
      if (action.ids.length === this.store.selectSnapshot(AggregationState.aggAccounts(null, false)).length) {
        this.logger.info('[onFilterAggregationByAccounts] action.ids same length as all ids, reverting to unfiltered');
        ctx.patchState({ filteredAggFinSummary: null });

        return EMPTY;
      }
      if (action.ids.length === 0) {
        this.logger.info('[onFilterAggregationByAccounts] zero action.ids, patching out ');
        let emptySummary = { ...ctx.getState().aggFinSummary };
        emptySummary.accountSections = { ...emptySummary.accountSections };
        for (let key in emptySummary.accountSections) {
          emptySummary.accountSections[key] = {
            ...emptySummary.accountSections[key],
            ...{
              subsections: {
                none: {
                  name: 'NONE',
                  type: 'NONE',
                  accounts: [],
                  acctGroups: [],
                  totalAmount: 0
                }
              }
            }
          };
        }
        ctx.patchState({ filteredAggFinSummary: emptySummary });
        return EMPTY;
      }
      //Possible to-do: decide if we need to hit yodlee api in any cases
      return this.aggregationService.fetchAggregationData(action.ids, 'db').pipe(
        tap((aggFinSummary) => {
          if (aggFinSummary) {
            ctx.patchState({ filteredAggFinSummary: aggFinSummary });
          }
        })
      );
    }
    return EMPTY;
  }

  @Action(AggregationActions.SetAggregationState)
  onSetAggregationState(ctx: StateContext<AggregationStateModel>, action: AggregationActions.SetAggregationState) {
    ctx.patchState({
      aggFinSummary: action.data
    });
  }

  @Action(AggregationActions.SetAutoRefresh)
  onSetAutoRefresh(ctx: StateContext<AggregationStateModel>, action: AggregationActions.SetAutoRefresh) {
    const newEnableAutoRefreshState = action.nextState ?? !ctx.getState().enableAutoRefresh;
    ctx.patchState({
      enableAutoRefresh: newEnableAutoRefreshState
    });
    const hasRefreshingAccounts = this.store.selectSnapshot(AggregationState.refreshingAccounts)?.length > 0;
    const isCurrentlyFiltered = !!ctx.getState().filteredAggFinSummary;

    if (newEnableAutoRefreshState) {
      if (!this.hasRefreshCallBeenMadeThisSession) {
        ctx.dispatch(new AggregationActions.RefreshProviderAccounts());
      }
      if (hasRefreshingAccounts && !isCurrentlyFiltered) {
        ctx.dispatch(new AggregationActions.Fetch());
      }
    } else {
      clearTimeout(this.refreshAccountsTimeoutHandle);
      this.refreshAccountsTimeoutHandle = null;
    }
  }

  @Action(AggregationActions.SetAggregationProviderAccounts)
  onSetAggregationProviderAccounts(
    ctx: StateContext<AggregationStateModel>,
    action: AggregationActions.SetAggregationProviderAccounts
  ) {
    ctx.patchState({
      providerAccountsMap: action.data
    });
  }

  @Action(AggregationActions.RefreshProviderAccounts)
  onRefreshProviderAccounts(
    ctx: StateContext<AggregationStateModel>,
    action: AggregationActions.RefreshProviderAccounts
  ) {
    if (this.hasRefreshCallBeenMadeThisSession) {
      this.logger.info('[onRefreshProviderAccounts] Refresh call has already been made this session.');
      return EMPTY;
    }

    let providerAccountIdsToRefresh = action.providerAccountIds;
    if (!providerAccountIdsToRefresh) {
      const providers = this.store.selectSnapshot(AggregationState.allAggProviderAccountIds);
      providerAccountIdsToRefresh = providers;
    }
    return this.aggregationService.refreshProviderAccounts(providerAccountIdsToRefresh).pipe(
      switchMap((resp) => {
        if (!resp) {
          this.logger.info('[onRefreshProviderAccounts] empty resp', resp);
          return of(false);
        }
        this.logger.info('[onRefreshProviderAccounts] refetching after resp:', resp);
        return ctx.dispatch(new AggregationActions.Fetch());
      }),
      tap(() => {
        this.hasRefreshCallBeenMadeThisSession = true;
      })
    );
  }

  @Action(AggregationActions.DeleteProviderAccounts)
  onDeleteProviderAccounts(
    ctx: StateContext<AggregationStateModel>,
    action: AggregationActions.DeleteProviderAccounts
  ) {
    if (this.env.impersonation) {
      this.logger.error(new Error('[WT2] Feature unavailable for legal reasons'));
      this.errorService.pushNotificationErrorMessege(GlobalConstants.error451Message);
      return EMPTY;
    }
    ctx.setState(defaultState);

    return this.aggregationService.deleteProviderAccounts(action.providerAccountIds).pipe(
      tap((resp) => {
        ctx.dispatch(new AggregationActions.Fetch(null));
      })
    );
  }

  @Action(AggregationActions.DeleteAggregationAccounts)
  onDeleteAggregationAccounts(
    ctx: StateContext<AggregationStateModel>,
    action: AggregationActions.DeleteAggregationAccounts
  ) {
    if (this.env.impersonation) {
      this.logger.error(new Error('[WT2] Feature unavailable for legal reasons'));
      this.errorService.pushNotificationErrorMessege(GlobalConstants.error451Message);
      return EMPTY;
    }

    return this.aggregationService.deleteAggregationAccounts(action.accountIds).pipe(
      tap(() => {
        ctx.dispatch(new AggregationActions.SetDefaultThenFetch(null));
      })
    );
  }

  @Action([AggregationActions.SetDefaultThenFetch])
  onSetDefaultThenFetch(ctx: StateContext<AggregationStateModel>, action: AggregationActions.SetDefaultThenFetch) {
    ctx.setState(defaultState);
    ctx.dispatch(new AggregationActions.Fetch(action.dataSrc));
  }

  //memoized selectors

  @Selector([AggregationState])
  static aggProviders(state: AggregationStateModel): AggProvider[] {
    let providersDict: { [id: string]: AggProvider } = {};
    try {
      //basically creates two levels of dictionaries:
      //outer level key: providerId, outer level value: AggProvider, selector returns the values of this dict.
      //inner level: each AggProvider has a dictionary with key: ProviderAccountId, value: array of accounts
      //each provider hold multiple provider accounts, which hold multiple accounts
      Object.values(state?.aggFinSummary?.accountSections).forEach((section: AggFinSumSection) => {
        Object.values(section?.subsections).forEach((subSection) => {
          subSection?.accounts.reduce((accountsList, account: AggAccount) => {
            try {
              if (account.providerId && account.providerAccountId) {
                if (!accountsList.hasOwnProperty(account.providerId)) {
                  accountsList[account.providerId] = {
                    providerId: account.providerId,
                    providerName: account.providerName,
                    providerAccounts: {}
                  };
                }
                if (!accountsList[account.providerId].providerAccounts.hasOwnProperty(account.providerAccountId)) {
                  accountsList[account.providerId].providerAccounts[account.providerAccountId] = [];
                }
                accountsList[account.providerId].providerAccounts[account.providerAccountId].push(account);
              }
            } catch (e) {}
            return accountsList;
          }, providersDict);
        });
      });
    } catch (e) {
      providersDict = {};
    }
    return Object.values(providersDict);
  }

  @Selector([AggregationState])
  static providerAccounts(state: AggregationStateModel): AggProviderAccountsByIdMap {
    return state.providerAccountsMap;
  }

  @Selector([AggregationState.aggProviders])
  static allAggProviderAccountIds(state: AggProvider[]): string[] {
    return state.flatMap((provider) => Object.keys(provider.providerAccounts));
  }

  @Selector([AggregationState.aggAccounts(null, false)])
  static refreshingAccounts(state: Partial<ClientAccountsSubAccounts>[]): Partial<ClientAccountsSubAccounts>[] {
    return state?.filter((acct) => {
      return (
        acct?.acctSourceCd === 'YDL' &&
        acct?.dataSets?.some((dataset) => refreshingStatusCodes.includes(dataset.additionalStatus))
      );
    });
  }

  @Selector([AggregationState])
  //null if no state initialized to report on
  static hasUserAggregated(state: AggregationStateModel): boolean | null {
    if (state?.aggFinSummary === undefined || state?.providerAccountsMap === undefined) {
      return null;
    }
    //they have aggregated if there are providerAccounts coming back
    return Object.keys(state.providerAccountsMap).length > 0;
  }

  //AggFinSummary
  @Selector([AggregationState])
  static marshalledAccounts(aggregationState: AggregationStateModel) {
    const constants = {
      INV_SUBSECTION: aggregationState?.aggFinSummary?.accountSections?.Assets?.subsections?.INVESTMENT_ACCOUNTS,
      INV_SUBSECTION_FILT:
        aggregationState?.filteredAggFinSummary?.accountSections?.Assets?.subsections?.INVESTMENT_ACCOUNTS,
      GROUP_COMPANY_NAME: 'Wilmington Trust',
      GROUP_ACCTID: 'Account Group',
      acctGroupMap(acctGroup: ClientAccountsAccountGroup) {
        return {
          company: constants.GROUP_COMPANY_NAME,
          acctName: acctGroup.acctGroupInfo.groupName,
          acctId: constants.GROUP_ACCTID,
          acctBalance: acctGroup.listSummAmt,
          id: acctGroup.id.toString(),
          subAccounts: acctGroup.acctList,
          group: true
        } as AggAccount;
      }
    };

    return {
      ...aggregationState,
      ...(!!aggregationState?.aggFinSummary && {
        aggFinSummary: {
          ...aggregationState?.aggFinSummary,
          accountSections: {
            ...aggregationState?.aggFinSummary?.accountSections,
            Assets: {
              ...aggregationState?.aggFinSummary?.accountSections?.Assets,
              subsections: {
                ...aggregationState?.aggFinSummary?.accountSections?.Assets?.subsections,
                INVESTMENT_ACCOUNTS: {
                  ...constants.INV_SUBSECTION,
                  accounts: (constants.INV_SUBSECTION?.acctGroups.map(constants.acctGroupMap) as AggAccount[]).concat(
                    constants.INV_SUBSECTION.accounts || []
                  )
                }
              }
            }
          }
        }
      }),
      ...(!!aggregationState?.filteredAggFinSummary && {
        filteredAggFinSummary: {
          ...aggregationState?.filteredAggFinSummary,
          accountSections: {
            ...aggregationState?.filteredAggFinSummary?.accountSections,
            Assets: {
              ...aggregationState?.filteredAggFinSummary?.accountSections?.Assets,
              subsections: {
                ...aggregationState?.filteredAggFinSummary?.accountSections?.Assets?.subsections,
                // don't patch in investment groups if no ids selected in filter and state patches out with 'none'
                ...(!aggregationState?.filteredAggFinSummary?.accountSections?.Assets?.subsections?.none && {
                  INVESTMENT_ACCOUNTS: {
                    ...constants.INV_SUBSECTION_FILT,
                    accounts: (
                      constants.INV_SUBSECTION_FILT?.acctGroups.map(constants.acctGroupMap) as AggAccount[]
                    ).concat(constants.INV_SUBSECTION_FILT.accounts || [])
                  }
                })
              }
            }
          }
        }
      })
    } as AggregationStateModel;
  }

  /**
   *
   * @param aggAcctId optional accountId to filter the list of AggAccounts. Will return the full list with no args provided
   * @returns Partial<ClientAccountsSubAccounts>[]: an array of accounts matching entityId, or all of the accounts as a flat list if no arg
   */
  static aggAccounts(entityId: string | null = null, useFiltered: boolean = false) {
    return createSelector(
      [AggregationState.marshalledAccounts],
      (state: AggregationStateModel): Partial<ClientAccountsSubAccounts>[] => {
        try {
          //use flag to determine if selector uses filtered summary, or unfiltered (defailt)
          if (useFiltered && !state.filteredAggFinSummary) {
            //if we're selecting the filtered data, but there is none, return null and not an empty array
            return null;
          }
          let accountsList = Object.values(
            useFiltered ? state?.filteredAggFinSummary?.accountSections : state?.aggFinSummary?.accountSections
          ).reduce((acc, section) => {
            return acc.concat(
              Object.values(section.subsections).reduce((_acc, _subsec) => {
                return _acc.concat(_subsec.accounts);
              }, [] as Partial<ClientAccountsSubAccounts>[])
            );
          }, [] as Partial<ClientAccountsSubAccounts>[]);

          if (entityId !== null) {
            return accountsList.filter((acct) => {
              return acct.id === entityId;
            });
          } else {
            return accountsList;
          }
        } catch (e) {
          return null;
        }
      }
    );
  }

  static providerAccountById(providerAcctId: string) {
    return createSelector(
      [AggregationState.providerAccounts],
      (state: AggProviderAccountsByIdMap): AggProviderAccount => {
        return state?.[providerAcctId];
      }
    );
  }
}
