import { Injectable } from '@angular/core';
import { LoggerService } from '@app/logger.service';
import { Event, RouterEvent, Router, NavigationStart } from '@angular/router';
import { Subject, BehaviorSubject, Observable } from 'rxjs';

export enum INTERNAL_ERRORS {
  'timeout' = 'timeout',
  'serverError' = 'server_error',
  'other' = 'other',
  'gatewayTimeout' = 'gateway_timeout'
}

@Injectable({
  providedIn: 'root'
})
export class ErrorHandlingService {
  private _error$: Subject<number> = new BehaviorSubject<number>(null);
  private _notificationBannerErrorMessage$: Subject<
    string
  > = new BehaviorSubject<string>(null);

  constructor(private logger: LoggerService, private router: Router) {
    this.router.events.subscribe((event: Event | RouterEvent): void => {
      if (event instanceof NavigationStart) {
        this.clearNotificationBannerErrorState();
      }
    });
  }

  pageLoadError(error: INTERNAL_ERRORS | string) {
    const err = error ? error : INTERNAL_ERRORS.other;
    this.router.navigate(['error-handler'], {
      queryParams: { error: err }
    });
  }

  pushNotificationErrorMessege(errorMessage: string) {
    this._notificationBannerErrorMessage$.next(errorMessage);
  }

  error$(): Observable<number> {
    return this._error$.asObservable();
  }

  notificationBannerErrorMessage$(): Observable<string> {
    return this._notificationBannerErrorMessage$.asObservable();
  }

  clearErrorState() {
    this._error$.next(null);
  }

  clearNotificationBannerErrorState() {
    this._notificationBannerErrorMessage$.next(null);
  }
}
