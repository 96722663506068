import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ModalBaseClass } from '@app/shared/services/modal-mk2.service';
import { Store } from '@ngxs/store';
import { YdlActions } from '../_actions/ydl.actions';
import { NgFor } from '@angular/common';
import { A11yModule } from '@angular/cdk/a11y';

interface marketingInfoItem {
  title: String;
  description: String;
  image: String;
}
@Component({
    selector: 'app-ydl-marketing',
    templateUrl: './ydl-marketing.component.html',
    styleUrls: ['./ydl-marketing.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [A11yModule, NgFor]
})
export class YdlMarketingComponent extends ModalBaseClass {
  marketingText: marketingInfoItem[] = [
    {
      title: 'Everything in one place',
      description:
        'In one dashboard, Total Picture displays all aggregated deposits, investments, and other assets as well as liabilities, such as credit cards, mortgages, and loans from across your financial institutions.',
      image: 'assets/images/icons/three-dots.svg'
    },
    {
      title: 'Better informed financial decisions',
      description:
        'With Total Picture, you and your Advisor can make more holistic planning decisions by sharing a single, dynamic view of your finances.',
      image: 'assets/images/icons/chart.svg'
    },
    {
      title: 'Built into Wilmington Trust Online',
      description:
        "Because Total Picture is built into your existing online experience with Wilmington Trust, there's no complicated sign-up process. It's all here in your secure online experience.",
      image: 'assets/images/icons/password.svg'
    }
  ];

  constructor(private store: Store) {
    super();
  }

  onLaunchClick(): void {
    this.store.dispatch(new YdlActions.OpenFastlinkModal());
  }
}
