import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { LoggerService } from '@app/logger.service';
import { Environment } from 'env/ienvironment';
import { ENV } from 'env/environment.provider';
import { Observable, tap } from 'rxjs';

export class UserPreferences {
  unrenderAccounts: string[];
  callout: boolean;
  wealthTeam: boolean;
  calloutDismissals: { [key: string]: boolean };
  showDashboardAd?: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class PreferencesService {
  constructor(private http: HttpClient, private logger: LoggerService, @Inject(ENV) private env: Environment) {}

  fetchPreferences(): Observable<UserPreferences> {
    return this.http
      .get<UserPreferences>(`${this.env.pcfUrl}/userpreferences`)
      .pipe(tap((obs) => this.logger.info('[Preferences Service] ', obs)));
  }
  setCalloutPreferenceToFalse() {
    return this.http.post(`${this.env.pcfUrl}/disablecallout`, null);
  }

  dismissCallouts(ids: string[]) {
    return this.http.post(`${this.env.pcfUrl}/dismisscallout`, { dismissedCalloutIds: ids });
  }

  fetchDashboardAdPref(): Observable<{ showDepositAd: boolean }> {
    return this.http
      .get<{ showDepositAd: boolean }>(`${this.env.pcfUrl}/adspaceindicator`)
      .pipe(tap((obs) => this.logger.info('[Preferences Service] fetchDashboardAdPref', obs)));
  }
}
