import { AggFinSummary, AggProviderAccountsByIdMap } from '../_models/AggFinSummary';

export namespace AggregationActions {
  export class Fetch {
    static readonly type = '[Aggregation] Fetch Accounts and Provider Accounts';
    constructor(public dataSrc: 'db'|null = 'db') {}
  }

  export class FilterAggregationByAccounts {
    static readonly type = '[Aggregation] POST Filter Aggregation By Accounts';
    constructor(public ids: Array<string>) {}
  }

  export class SetAggregationState {
    static readonly type = '[Aggregation] Set Aggregation State';
    constructor(public data: AggFinSummary) {}
  }

  export class SetAggregationProviderAccounts {
    static readonly type = '[Aggregation] Set Aggregation Provider Accounts';
    constructor(public data: AggProviderAccountsByIdMap) {}
  }

  export class RefreshProviderAccounts {
    static readonly type = '[Aggregation] Attempt Refresh provider accounts';
    constructor(public providerAccountIds?: string[]) {}
  }

  export class DeleteAggregationAccounts {
    static readonly type = '[Aggregation] Delete Aggregation Account(s)';
    constructor(public accountIds: string[]) {}
  }

  export class DeleteProviderAccounts {
    static readonly type = '[Aggregation] Delete provider accounts';
    constructor(public providerAccountIds: string[]) {}
  }

  export class SetAutoRefresh {
    static readonly type = '[Aggregation] Set AutoRefresh behavior toggle';
    constructor(public nextState?: boolean) {}
  }
  
  export class SetDefaultThenFetch {
    static readonly type = '[Aggregation] Set default state';
    constructor(public dataSrc: 'db'|null = 'db') {}
  }
}
