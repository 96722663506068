import { ChangeDetectionStrategy, Component, OnInit, Inject } from '@angular/core';
import { LoggerService } from '../logger.service';
import { formatDate, NgIf, AsyncPipe } from '@angular/common';
import { Environment } from 'env/ienvironment';
import { ENV } from 'env/environment.provider';
import { Observable, Subscription } from 'rxjs';
import { premierServicesPhoneNumber, mAndTSecuritiesIncPhoneNumber } from '@locale/messages.global.en-US';
import { map, shareReplay } from 'rxjs/operators';
import { Store } from '@ngxs/store';
import { AccountsState } from '@app/_state/accounts.state';
import { AuthState } from '@app/_state/auth.state';
import { GlobalConstants } from '@app/shared/services/globalConstants';
import { Profile } from '@app/shared/models/profile.model';
import { ProfileState } from '@app/_state/profile.state';
import { RouterLink, RouterLinkActive } from '@angular/router';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [NgIf, RouterLink, RouterLinkActive, AsyncPipe]
})
export class FooterComponent implements OnInit {
  url: string = this.env.wtuOriginURL;
  subscriptions: Subscription[] = [];
  currentYear: string = new Date().getFullYear().toString(10);
  authorized$: Observable<boolean>;
  showTransfer$: Observable<boolean>;
  showReporting$: Observable<boolean>;
  loginDateTime$: Observable<{ loginDate: string; loginTime: string }>;
  showTotalPicture$: Observable<boolean>;
  enableFeature_ydl: boolean = this.env.enableFeature_ydl;
  enableFeature_consolidatedHoldings: boolean = this.env.enableFeature_consolidatedHoldings;

  showPerformanceLink = false;
  showInsightsLink = false;
  showInvestmentPolicyLink = false;

  showTrustAdvisorLink = false;
  premierServicesPhoneNumber = premierServicesPhoneNumber;
  mAndTSecuritiesIncPhoneNumber = mAndTSecuritiesIncPhoneNumber;
  showConsolidatedHoldings$: Observable<boolean>;

  constructor(
    private logger: LoggerService,
    private store: Store,
    @Inject(ENV) private env: Environment) {}

  ngOnInit() {
    this.authorized$ = this.store.select(AuthState.isAuthenticated);

    this.showTransfer$ = this.store.select(AccountsState.currentEntitlementsCodes()).pipe(
      map((entitlements) => {
        if (Array.isArray(entitlements)) {
          return entitlements.includes(GlobalConstants.servicesDictionary.transferMoney.code);
        }
        return false;
      })
    );

    this.showReporting$ = this.store.select(AccountsState.currentEntitlments()).pipe(
      map((entitlements) => {
        if (Array.isArray(entitlements)) {
          return entitlements.find((e) => e.serviceCode === GlobalConstants.servicesDictionary.wealth.code)
            ?.allowQuickLinks;
        }
        return false;
      })
    );

    this.loginDateTime$ = this.store.select<Profile>(ProfileState).pipe(
      map((prof) => {
        if (prof && prof.lastActivity && prof.lastActivity.length > 0) {
          return {
            loginDate: formatDate(prof.lastActivity, 'longDate', 'en-US'),
            loginTime: formatDate(prof.lastActivity, 'shortTime', 'en-US')
          };
        }
        return null;
      }),
      shareReplay(1)
    );

    this.showTotalPicture$ = this.store.select<Profile>(ProfileState).pipe(
      map((profile) => {
        return this.enableFeature_ydl && profile?.ydlEligible;
      })
    );

    this.showConsolidatedHoldings$ = this.store.select<Profile>(ProfileState).pipe(
      map((profile) => {
        return profile?.member === 1 && this.enableFeature_consolidatedHoldings;
      })
    );
  }
}
