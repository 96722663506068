import { Injectable } from '@angular/core';
import { LoggerService } from '@app/logger.service';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Notifications } from '../_actions/notification.actions';
import { CODES, NotificationConfig, NOTIFICATION_CONFIGS } from './notifications.config';
import { patch } from '@ngxs/store/operators';
import { noop } from 'rxjs';
import { AppActions } from '@app/_actions/app.actions';
import { AccountsState } from '@app/_state/accounts.state';

export const LS_INSTALL_DISMISS_TS_KEY: string = CODES.USR_APP_INSTALL_PROMPT;

export interface NotificationsStateModel {
  activeNotifications?: string[];
  notificationConfigs?: [string, Partial<NotificationConfig>][];
  restrictedCodes?: string[];
}

const defaultState: NotificationsStateModel = {
  activeNotifications: [],
  notificationConfigs: NOTIFICATION_CONFIGS,
  restrictedCodes: ['W-001501-0_1101'],
};

@State<NotificationsStateModel>({
  name: 'notifications',
  defaults: defaultState
})
@Injectable()
export class NotificationState {
  constructor(private logger: LoggerService) {}

  private initialSetup = (ctx: StateContext<NotificationsStateModel>) => {
    const lastInstallDismissal = localStorage.getItem(LS_INSTALL_DISMISS_TS_KEY);
    if (lastInstallDismissal) {
      const lastDismissalMonth = new Date(lastInstallDismissal).getMonth();
      const thisMonth = new Date().getMonth();
      let newRestrictions = [];
      lastDismissalMonth != thisMonth ? newRestrictions.push(CODES.USR_APP_INSTALL_PROMPT) : noop();
      if (newRestrictions.length > 0) {
        ctx.setState({
          ...ctx.getState(),
          restrictedCodes: [...ctx.getState().restrictedCodes, ...newRestrictions]
        });
      }
    }
  };
  ngxsOnInit(ctx: StateContext<NotificationsStateModel>) {
    this.initialSetup(ctx);
  }

  @Action([AppActions.ResetState])
  onResetState(ctx: StateContext<NotificationsStateModel>, action: AppActions.ResetState) {
    ctx.setState(defaultState);
    this.initialSetup(ctx);
  }

  @Action(Notifications.AddWithOverrides)
  onAddWithOverrides(ctx: StateContext<NotificationsStateModel>, action: Notifications.AddWithOverrides) {
    const actives = ctx.getState().activeNotifications;
    const configs = new Map(ctx.getState().notificationConfigs);
    const restricted = ctx.getState().restrictedCodes;
    const onlyValidCodes = action.notifications.filter((_) => {
      if (restricted.includes(_)) {
        this.logger.info(`[Notifications.Add] Code "${_}" cannot be added: restricted`);
        return false;
      }
      if (actives.includes(_)) {
        this.logger.info(`[Notifications.Add] Code "${_}" cannot be added: duplicate`);
        return false;
      }
      if (!configs.has(_) && !_.startsWith(CODES.PUSH_WARNING_EMERGENCY)) {
        this.logger.warn(`[Notifications.Add] Code "${_}" cannot be added: unknown`);
        return false;
      }
      return true;
    });

    action.notifications.forEach((code, i) => {
      let origConf;
      if (code.startsWith(CODES.PUSH_WARNING_EMERGENCY)) {
        origConf = { ...configs.get(CODES.PUSH_WARNING_EMERGENCY) };
      } else {
        origConf = { ...configs.get(code) };
      }

      const mergedConf = Object.assign(origConf, action.overrides[i]);
      configs.set(code, mergedConf);
    });

    ctx.setState(
      patch({
        activeNotifications: [...actives, ...onlyValidCodes],
        notificationConfigs: [...configs.entries()]
      })
    );
  }

  @Action(Notifications.Add)
  onAdd(ctx: StateContext<NotificationsStateModel>, action: Notifications.Add) {
    const actives = ctx.getState().activeNotifications;
    const configs = new Map(ctx.getState().notificationConfigs);
    const restricted = ctx.getState().restrictedCodes;
    const onlyValidCodes = action.notifications.filter((_) => {
      if (restricted.includes(_)) {
        this.logger.info(`[Notifications.Add] Code "${_}" cannot be added: restricted`);
        return false;
      }
      if (actives.includes(_)) {
        this.logger.info(`[Notifications.Add] Code "${_}" cannot be added: duplicate`);
        return false;
      }
      if (!configs.has(_)) {
        this.logger.warn(`[Notifications.Add] Code "${_}" cannot be added: unknown`);
        return false;
      }
      return true;
    });
    ctx.setState(
      patch({
        activeNotifications: [...actives, ...onlyValidCodes]
      })
    );
  }

  @Action(Notifications.Remove)
  onRemove(ctx: StateContext<NotificationsStateModel>, action: Notifications.Remove) {
    const actionCodes = action.codesToRemove;
    ctx.setState(
      patch({
        activeNotifications: ctx.getState().activeNotifications.filter((code) => !actionCodes.includes(code))
      })
    );

    if (action.restrict && action.restrict.length === action.codesToRemove.length) {
      // Adds codes to restriction list as per Restrict Action
      const restrictedNotifications =  actionCodes.filter((_, i) => {
        return action.restrict[i];
      })
      ctx.setState(
        //add new codes to existing codes, removing dupes
        patch({
          restrictedCodes: [...new Set([...ctx.getState().restrictedCodes, ...restrictedNotifications])]
        })
      );
      if (restrictedNotifications.includes(CODES.USR_APP_INSTALL_PROMPT)) {
        //set the date of dismissal in localStorage to check later
        localStorage.setItem(LS_INSTALL_DISMISS_TS_KEY, JSON.stringify(new Date()));
      }
    }
  }

  @Action(Notifications.HidePushWarnings)
  onRemoveAllOf(ctx: StateContext<NotificationsStateModel>, action: Notifications.HidePushWarnings) {
    ctx.setState(
      patch({
        activeNotifications: ctx
          .getState()
          .activeNotifications.filter((code) => !code.startsWith(CODES.PUSH_WARNING_EMERGENCY))
      })
    );
  }

  @Selector([NotificationState])
  static activeNotifications(state: NotificationsStateModel) {
    return state.activeNotifications.filter((_) => !state.restrictedCodes.includes(_));
  }

  @Selector([NotificationState, AccountsState.currentEntitlementsCodes()])
  static activeNotificationConfigs(state: NotificationsStateModel, entitlements: string[]) {
    const configs = new Map(state.notificationConfigs);
    return this.activeNotifications(state)
      .map((v) => {
        return configs.get(v);
      })
      .filter((notificationConfig) => {
        if (!notificationConfig) {
          return false;
        }
        if (Array.isArray(notificationConfig?.serviceTargets)) {
          //if there are service targets, return true if at least one serviceTarget matches a user entitlement
          return (
            notificationConfig?.serviceTargets?.findIndex((noteServiceTarget) => {
              return entitlements?.includes(noteServiceTarget) || noteServiceTarget === CODES.PUSH_WARNING_EMERGENCY_INTRADAY;;
            }) > -1
          );
        }
        return true;
      });
  }
  
}
