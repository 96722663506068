import { AppStateModel } from '@app/_state/app.state';

export namespace AppActions {
  export class Update {
    static readonly type = '[App] Update App Metadata';
    constructor(public patch: Partial<AppStateModel>) {}
  }
``
  export class ResetState {
    static readonly type = '[App] Reset Global State';
    constructor() {}
  }
}
