<div cdkTrapFocus [cdkTrapFocusAutoCapture]="true"
  class="dialog-panel u-bg--white u-border-radius--small c-modal__container c-modal__container--medium u-display--flex u-flex-dir--column">
  <div class="c-modal__header l-padding--large u-bg--primary-lightest">
    <h1 class="u-type--primary-base u-type--xlarge">Your Wealth In One View</h1>
    <div class="c-modal__close-icon">
      <button (click)="requestClose()" class="c-modal__close-icon-button" aria-label="close">
        <span class="c-icon--large" aria-hidden="true">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" role="img" aria-label="Close Window">
            <title>Close Window Icon</title>
            <path fill="#425968"
              d="M20 4.86L12.86 12 20 19.14l-.86.86L12 12.86 4.86 20 4 19.14 11.14 12 4 4.86 4.86 4 12 11.14 19.14 4z">
            </path>
          </svg>
        </span>
      </button>
    </div>
  </div>
  <div class="overflow-container">
    <div class="u-display--flex flex-direction-toggle u-flex-child--grow">
      <div class="l-padding--large u-bg--primary-base pattern-background fix-border">
        <p class="u-type--white font--large">Connect your accounts from outside financial institutions to gain a full
          view of your net worth in one convenient, dynamic view.</p>
      </div>
      <div class="l-padding--large u-display--flex u-flex-dir--column u-flex-align--justify">
        <div *ngFor="let text of marketingText"
          class="u-display--flex l-padding-bottom--medium l-padding-right--sm-large">
          <img class="l-padding-top--xsmall l-padding-right--medium" [attr.src]="text.image" alt="Lock" loading="lazy"
            width="36px" height="36px" />
          <div>
            <p class="u-type--primary-base u-type--semibold font--medium l-padding-bottom--xsmall">{{text.title}}</p>
            <p class=" u-type--primary-base font--small">{{text.description}}</p>
          </div>
        </div>
        <p class="u-type--right l-padding-top--medium">
          <button (click)="requestClose()" class="c-button c-button--secondary u-type--small l-margin-right--small">
            <span class="c-button-focus">Close</span>

          </button>
          <button (click)="onLaunchClick()" class="c-button c-button--primary u-type--small">
            <span class="c-button-focus">Link Accounts</span>

          </button>
        </p>
      </div>
    </div>
  </div>
</div>