<div class="app-container">
  <a tabindex="0" class="skip-link" [routerLink]="[currentUrl]" [fragment]="mainContainerFragment">
    <div>Skip to content</div>
  </a>
  <app-header *ngIf="showHeader"> </app-header>
  <div id="notificationBannerContainer" class="notificationBannerContainer">
    <div class="app-container__notification-banner l-padding--xxsmall">
      <ng-container *ngTemplateOutlet="notificationContainer; context: { notification: notificationMessage$ | async }">
      </ng-container>
      <app-notification-container *ngIf="isDashboardRoute$ | async"></app-notification-container>
    </div>
  </div>

  <div id="appBody" #appBody class="app-container__body">
    <div #mainContainer class="app-container__body-container" id="mainContainer" tabindex="-1">
      <main class="app-container__main">
        <router-outlet></router-outlet>
      </main>
      <div class="app-container__side">
        <router-outlet name="sideContent"></router-outlet>
      </div>
    </div>
  </div>
  <div id="qualtrics-placeholder" [style.display]="(showQualtricsMiniSurvey$ | async) ? '' : 'none'"></div>
  <app-footer *ngIf="showFooter"></app-footer>
</div>

<ng-template #notificationContainer let-notification="notification">
  <div
    *ngIf="notification"
    [ngClass]="{
      'c-notification-banner u-border-radius--small': true,
      'u-border--red-light u-bg--white': true
    }"
  >
    <span class="c-icon--medium alert_icon">
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
        <title>Alert Error</title>
        <path
          fill="#F34235"
          fill-rule="nonzero"
          d="M12.982 15.374a.991.991 0 0 1-.984.984.996.996 0 0 1-.985-.984c0-.539.454-.985.985-.985.538 0 .984.446.984.985zm-1.546-8.437h1.123l-.138 6.191h-.846l-.139-6.19zm7.314 10.69l.93-1.215-6.883-12.29h-1.545l-6.914 12.29.915 1.215H18.75zM13.405 3l7.598 13.496-1.692 2.254H4.684L3 16.496 10.59 3h2.815z"
        />
      </svg>
    </span>
    <ng-container *ngIf="notification !== 'fastLinkError'; else fastLinkError">
      <span class="u-type--primary-dark l-padding-left--small notification_body" style="flex: 1 1 auto">
        {{ notification ? notification : '' }}
      </span>
    </ng-container>
    <button class="c-button button__dismiss" aria-label="close" (click)="dismissErrorBanner()">
      <svg
        class="c-icon--medium"
        role="img"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
        aria-label="Close Panel"
      >
        <title>Close Panel Icon</title>
        <path
          d="M20 4.86L12.86 12 20 19.14l-.86.86L12 12.86 4.86 20 4 19.14 11.14 12 4 4.86 4.86 4 12 11.14 19.14 4z"
          fill="#425968"
        ></path>
      </svg>
    </button>
  </div>
</ng-template>

<ng-template #fastLinkError>
  <span class="u-type--primary-dark l-padding-left--small notification_body" style="flex: 1 1 auto">
    Linking external accounts is currently unavailable. Please try again later. If the issue persists, contact Premier
    Services at
    <a class="c-link" style="width: auto; font-weight: bold; font-size: 1em; color: #000000" href="tel:+18009824620"
      >1-800-982-4620</a
    >
    (Mon-Fri 8 AM to 5 PM ET).</span
  >
</ng-template>
