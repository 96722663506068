
import { PkceChallengeObject } from "@app/login/models/PkceChallengeObject";
import { TokenResponse, TokenResponseJson } from "@openid/appauth";

export namespace AuthActions {

    export class InitRedirectlessLoginFlow {
        static readonly type = '[Auth] Init Redirectless Login Flow';
        constructor(public hostElementDomID: string) {}
    }

    export class OnAuthWidgetSuccess {
        static readonly type = '[Auth] OnAuthWidgetSuccess: Fetch Token Response';
        constructor(public authCode: string, public pkceVerifier: string) {}
    }

    export class ImpInit {
        static readonly type = '[Auth] ImpInit';
        constructor(public authCode: string, public destinationUrl: string) {}
    }

    export class SetTokenResponse {
        static readonly type = '[Auth] Set Token Response';
        constructor(public tokenResponse: TokenResponseJson) {}
    }

    export class SignOff {
        static readonly type = '[Auth] Sign Off';
        constructor() {}
    }

    export class SilentTokenRefresh {
        static readonly type = '[Auth] Silent Token Refresh';
        constructor() {}
    }

    export class StartSessionTimeout {
        static readonly type = '[Auth] Start Session Timeout';
        constructor() {}
    }

    export class InitTimers {
        static readonly type = '[Auth] (Re)Initialize Timers';
        constructor() {}
    }

}