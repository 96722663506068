import {
  Component,
  OnInit,
  AfterViewInit,
  OnDestroy,
  NgZone,
  ChangeDetectionStrategy,
  ViewChild,
  ElementRef,
  Renderer2,
  Inject,
  ChangeDetectorRef
} from '@angular/core';
import { Subscription, Observable, ReplaySubject, BehaviorSubject, combineLatest, map, tap } from 'rxjs';
import { LoggerService } from './logger.service';
import {
  Event,
  Router,
  ActivatedRoute,
  NavigationEnd,
  RouterEvent,
  NavigationStart,
  RouterLink,
  RouterOutlet
} from '@angular/router';
import { ModalMk2Service } from './shared/services/modal-mk2.service';
import { ErrorHandlingService } from './shared/services/error-handling.service';
import { Meta, MetaDefinition } from '@angular/platform-browser';
import { TealiumUtagService } from './shared/analytics/utag.service';
import { Environment } from 'env/ienvironment';
import { ENV } from 'env/environment.provider';
import { Store } from '@ngxs/store';
import { UserAgentState, UserAgentStateModel } from './_state/userAgent.state';
import { LazyLoadService } from './shared/utils/lazy-load.service';
import { MONITORING_ERRORS, MONITORING_INFORMATION } from './shared/monitoring/monitoring-constants';
import { ProfileState } from './_state/profile.state';
import { Profile } from './shared/models/profile.model';
import { FooterComponent } from './footer/footer.component';
import { NotificationContainerComponent } from './notifications/notification-container/notification-container.component';
import { HeaderComponent } from './header/header.component';
import { NgIf, NgTemplateOutlet, NgClass, AsyncPipe } from '@angular/common';
import { disableMiniSurveyRouteUrls } from './shared/services/globalConstants';

declare const APP_VERSION: string;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    RouterLink,
    NgIf,
    HeaderComponent,
    NgTemplateOutlet,
    NotificationContainerComponent,
    RouterOutlet,
    FooterComponent,
    NgClass,
    AsyncPipe
  ]
})
export class AppComponent implements OnInit, AfterViewInit, OnDestroy {
  private subscriptions: Subscription[] = [];
  title = 'wt-client-experience';
  public sideNavVisible = true;

  public showHeader = false;
  public showSidebar = false;
  public showFooter = false;
  public loading;
  public notificationMessage$: Observable<string>;
  public currentUrl: any = '';
  public mainContainerFragment = '';
  noCacheMetaTag: MetaDefinition = {
    httpEquiv: 'Cache-Control',
    content: 'no-store'
  };
  showTotalPicture$: Observable<boolean>;
  @ViewChild('mainContainer', { static: false }) mainContainer: ElementRef;
  isDashboardRoute$ = new ReplaySubject<boolean>();
  isInvalidRouteForSurvey$ = new ReplaySubject<boolean>();
  showQualtricsMiniSurvey$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  constructor(
    private logger: LoggerService,
    public modalService: ModalMk2Service,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private errorService: ErrorHandlingService,
    private metaTagService: Meta,
    private analyticsService: TealiumUtagService,
    private store: Store,
    private _ngZone: NgZone,
    private renderer: Renderer2,
    private changeDetectorRef: ChangeDetectorRef,
    @Inject(ENV) private env: Environment
  ) {
    // For WCAG Skip to Content Links
    router.events.subscribe((e: Event | RouterEvent) => {
      window.location.hash = '';
      if (e instanceof NavigationEnd) {
        if (e.url != '') {
          this.currentUrl = e.url;
          this.isInvalidRouteForSurvey$.next(disableMiniSurveyRouteUrls.includes(e.url));
          this.isDashboardRoute$.next(e.url === '/dashboard');          
        } else {
          this.currentUrl = '';
        }
        this.mainContainerFragment = 'mainContainer';
        if (this.currentUrl === '/dashboard' || this.currentUrl === '/dashboard/grouping') {
          this.renderer.setStyle(this.mainContainer.nativeElement, 'max-width', '1350px');
        } else {
          this.renderer.removeStyle(this.mainContainer.nativeElement, 'max-width');
        }
      }
    });

  }

  ngOnInit() {
    this.subscriptions.push(
      combineLatest([this.analyticsService.showQualtricsMiniSurvey$, this.isInvalidRouteForSurvey$, this.isDashboardRoute$])
        .pipe(
          map(([showQualtricsMiniSurvey, isInvalidRouteForSurvey, isDashboardRoute]) => {
            return showQualtricsMiniSurvey && !isInvalidRouteForSurvey && !isDashboardRoute;
          })
        )
        .subscribe((showQualtricsMiniSurvey) => {
          this.showQualtricsMiniSurvey$.next(showQualtricsMiniSurvey);
          this.changeDetectorRef.detectChanges();
        }),
      this.router.events.subscribe((event): void => {
        if (event instanceof NavigationStart) {
          this.loading = true;
        } else if (event instanceof NavigationEnd) {
          this.loading = false;
          this.showHeader = this.activatedRoute.firstChild.snapshot.data['showHeader'] !== false;
          this.showSidebar = this.activatedRoute.firstChild.snapshot.data['showSidebar'] !== false;
          this.showFooter = this.activatedRoute.firstChild.snapshot.data['showFooter'] !== false;
        }
      })
    );

    this.notificationMessage$ = this.errorService.notificationBannerErrorMessage$().pipe(
      tap((_) => {
        if (_) {
          window.scrollTo(0, 0);
        }
      })
    );

    this.showTotalPicture$ = this.store.select<Profile>(ProfileState).pipe(
      map((profile) => {
        return this.env?.enableFeature_ydl && profile?.ydlEligible;
      })
    );
    // Apple Smart App Banner
    if (this.metaTagService.getTag('name="apple-itunes-app"')) {
      this.subscriptions.push(
        this.store
          .selectOnce<UserAgentStateModel>(UserAgentState)
          .pipe(
            tap((userAgentState) => {
              if (!this.env.enableAppleSmartAppBanner || userAgentState.isPwaStandalone) {
                this.metaTagService.removeTag('name="apple-itunes-app"');
              }
            })
          )
          .subscribe()
      );
    }
  }

  ngAfterViewInit(): void {
    // Add version to the app-root element
    document
      .querySelector('app-root')
      .setAttribute('data-app-version', this.metaTagService.getTag('name="version"').getAttribute('content'));

    this._ngZone.runOutsideAngular(() => {
      // Get monitoring script
      if (this.env.monitoringAppID && this.env.monitoringEnvironmentID) {
        if (document.querySelector('script[src*="ruxitagentjs"],script[src*="dynatrace"]')) {
          this.logger.info(
            `${MONITORING_INFORMATION.I_004101_0.code}: ${MONITORING_INFORMATION.I_004101_0.name} - ${MONITORING_INFORMATION.I_004101_0.message}`
          );
        } else {
          LazyLoadService.lazyloadScriptViaHTMLInsert(
            'assets/vendor/dynatrace/dynatrace-manual-injection-script.js'
          ).catch((error) => {
            this.logger.error(
              new Error(
                `${MONITORING_ERRORS.E_004101_0.code}: ${MONITORING_ERRORS.E_004101_0.name} - ${MONITORING_ERRORS.E_004101_0.message}`
              )
            );
          });
        }
      } else {
        this.logger.info(
          `${MONITORING_INFORMATION.I_004102_0.code}: ${MONITORING_INFORMATION.I_004102_0.name} - ${MONITORING_INFORMATION.I_004102_0.message}`
        );
      }
      // Get the analytics scripts
      this.analyticsService.getScript();
      this.analyticsService.setupMiniSurveySubmitBtnListener();
    });
  }

  ngOnDestroy() {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }

  dismissErrorBanner(): void {
    this.errorService.clearNotificationBannerErrorState();
  }
}
