export const refreshingStatusCodes = ['ACCT_SUMMARY_RECEIVED', 'LOGIN_IN_PROGRESS', 'DATA_RETRIEVAL_IN_PROGRESS'];
export const AGG_ERROR_STATUS_CODES__DEEP_LINK_AVAILABLE_ = [
  'INCORRECT_OAUTH_TOKEN',
  'DATA_NOT_AVAILABLE',
  'NEW_AUTHENTICATION_REQUIRED',
  'ADDL_AUTHENTICATION_REQUIRED',
  'INCORRECT_CREDENTIALS'
];
export const AGG_ERROR_STATUS_CODES__DEEP_LINK_UNAVAILABLE = [
  'ACCOUNT_LOCKED',
  'CREDENTIALS_UPDATE_NEEDED',
  'USER_ACTION_NEEDED_AT_SITE'
];
export const AGG_ERROR_STATUS_CODES__ALL = [
  ...AGG_ERROR_STATUS_CODES__DEEP_LINK_AVAILABLE_,
  ...AGG_ERROR_STATUS_CODES__DEEP_LINK_UNAVAILABLE
];
