import { Directive, HostListener, Output, EventEmitter, HostBinding } from '@angular/core';

const KEY_ENTER = 13;
const KEY_SPACE = 32;

@Directive({
    selector: '[appKeyboardA11y]',
    standalone: true
})
export class KeyboardA11yDirective {
  @HostListener('click', ["$event"])
  click(event: PointerEvent) {
    event.stopPropagation();
    this.onInteraction.emit(event);
    return false;
  }

  @HostListener('keypress', ['$event'])
  keypress(event: KeyboardEvent) {
    if (event.key === 'Enter' || event.key === ' ') {
      event.stopPropagation();
      this.onInteraction.emit(event);
      return false;
    };
    return true;
  }

  @HostBinding('attr.tabindex') tabIndex = 0;

  @Output() onInteraction: EventEmitter<Event> = new EventEmitter();

  constructor() {
  }



}
