import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AggAccount } from '@app/aggregation/_models/AggFinSummary';
import { LoggerService } from '@app/logger.service';
import { ErrorHandlingService } from '@app/shared/services/error-handling.service';
import { GlobalConstants } from '@app/shared/services/globalConstants';
import { ModalMk2Service } from '@app/shared/services/modal-mk2.service';
import { LazyLoadService } from '@app/shared/utils/lazy-load.service';
import { Environment } from 'env/ienvironment';
import { ENV } from 'env/environment.provider';
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { RESP_ERROR_SERVICE_EXIST, RESP_ERROR_UNKNOWN, RESP_STATUS_ERROR, RESP_STATUS_SUCCESS } from './ydl-constants';
import { YdlDisclosureComponent } from './ydl-disclosure/ydl-disclosure.component';
import { YdlFastlinkComponent } from './ydl-fastlink/ydl-fastlink.component';
import { YdlMarketingComponent } from './ydl-marketing/ydl-marketing.component';
import { YdlAuthResp } from './_models/YdlAuthResp';
import { YdlUserResp } from './_models/YdlUserResp';

declare global {
  interface Window {
    fastlink: any;
  }
}

@Injectable()
export class YdlService {
  private readonly authReqUrl: string = '/fastlink';
  private readonly registerReqUrl: string = '/ydlregister';
  private readonly deleteAuthReqUrl: string = '/deletefastlinktoken';

  constructor(
    private http: HttpClient,
    private logger: LoggerService,
    private modalService: ModalMk2Service,
    private errorService: ErrorHandlingService,
    private router: Router,
    @Inject(ENV) private env: Environment
  ) {}

  fetchFastlinkConnectionData(): Observable<YdlAuthResp> {
    return this.http.get<YdlAuthResp>(this.env.pcfUrl + this.authReqUrl);
  }

  deleteFastlinkConnectionData(token: string): Observable<YdlAuthResp> {
    return this.http.post<YdlAuthResp>(this.env.pcfUrl + this.deleteAuthReqUrl, { token: token });
  }

  registerYdlService(): Observable<YdlUserResp> {
    return this.http.post<YdlUserResp>(this.env.pcfUrl + this.registerReqUrl, {}).pipe(
      catchError((err, caught) => {
        this.logger.error(err);
        return of({ status: RESP_STATUS_ERROR, error: RESP_ERROR_UNKNOWN } as YdlUserResp);
      }),
      map((resp) => {
        if (resp?.error === RESP_ERROR_SERVICE_EXIST) {
          //convert to success and move on
          let clone = { ...resp } as YdlUserResp;
          clone.error = null;
          clone.status = RESP_STATUS_SUCCESS;
          return clone;
        }
        return resp;
      }),
      tap((resp) => {
        if (resp.error) {
          this.router.navigate(['dashboard']);
          this.errorService.pushNotificationErrorMessege(GlobalConstants.fastLinkError);
        }
      })
    );
  }

  /**
   * Lazy loads the yodlee fastlink initialize.js IF NEEDED.
   *
   * @returns Promise<any> : represents availablility of the fastlink object on the window.
   */
  lazyLoadFastlinkJs(): Promise<any> {
    if (window.fastlink) {
      return Promise.resolve();
    } else {
      return LazyLoadService.lazyloadScriptViaHTMLInsert('https://cdn.yodlee.com/fastlink/v4/initialize.js');
    }
  }

  openMarketingModal(): void {
    this.modalService.open(YdlMarketingComponent, null, { closeOnBackdropClick: false });
  }

  openFastLinkModal(flow?: string, aggAccount?: AggAccount): void {
    this.modalService.open(
      YdlFastlinkComponent,
      {
        flow: flow,
        aggAccount: aggAccount
      },
      { closeOnBackdropClick: false }
    );
  }

  openDisclosureModal(): void {
    this.modalService.open(YdlDisclosureComponent, null, { closeOnBackdropClick: false });
  }
}
