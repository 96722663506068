/**
 * used for qualtrics survey 'Client_Email' embedded data retrieval
 */
export const SESSION_STORAGE_EMAIL_KEY = 'wtcProfileEmail';

export const SESSION_STORAGE_MEMBER_TYPE_KEY = 'wtcProfileMemberType';

export const COOKIE_VALUES = {
  wtvisit: 'WTVisit',
  wtcid: 'WTCID'
};
/**
 * Used when the analytics utag library script fails to load
 * @constant {Object}
 */
export const E_004001_0 = {
  code: 'E-004001-0',
  name: 'ANALYTICS_UTAG_LIBRARY_JS_LOAD_ERROR',
  message: 'Utag.js script not loaded'
};

export const ANALYTICS_ERRORS = Object.freeze({
  E_004001_0
});

/**
 * Used when the analytics are intentionally disabled or turned off
 * @constant {Object}
 */
export const I_004001_0 = {
  code: 'I-004001-0',
  name: 'ANALYTICS_UTAG_LIBRARY_JS_LOAD_DISABLED',
  message: 'Utag.js script loading has been disabled or toggled off'
};

export const ANALYTICS_INFORMATION = Object.freeze({
  I_004001_0
});
