import { Injectable } from '@angular/core';
import { LoggerService } from '@app/logger.service';
import { IntradayHoldings } from '@app/shared/models/client-holdings.model';
import { GlobalFormatterService } from '@app/shared/services/global-formatter.service';
import {
  IntradayPricing,
  IntradayPricingService,
  IntradayPricingSymbols,
  IntradayStatus
} from '../services/intraday-pricing.service';
import { IntradayHoldingsActions } from '../_actions/intradayHoldings.actions';
import { Action, createSelector, Selector, State, StateContext, Store } from '@ngxs/store';
import { switchMap, of } from 'rxjs';
import { tap} from 'rxjs/operators';
import { AccountsState, CurrentClientAccountState } from '@app/_state/accounts.state';
import { combineDuplicateHoldings, HoldingsState, HoldingsStateModel } from '@app/_state/holdings.state';
import { Holdings, HoldingsTotals } from '@app/shared/models/client-holdings.model';
import { AppActions } from '@app/_actions/app.actions';
import { ProfileState } from '@app/_state/profile.state';
import { environment } from '@env/local/environment';
import { Notifications } from '@app/notifications/_actions/notification.actions';
import { CODES, NOTIFICATION_CONFIGS } from '@app/notifications/_state/notifications.config';
import { UserPreferencesModel, UserPreferencesState } from '@app/_state/userPreferences.state';
export interface IntradayHoldingsStateModel extends IntradayStatus{
  status: string;
  intradayDictionary: IntradayPricing;
  apiError: boolean;
}

export interface InitiateIntraday {
  fetchFor: string;
  state?: string
}


/**
 * INTRADAY STATE MAINTAINS THE PRICING DATA FOR ALL ASSETS/HOLDINGS
 */

const defaultState: IntradayHoldingsStateModel = {
  status: '',
  intradayDictionary: null,
  apiError: false,
};

const intradayFeatureFlag = environment.enableFeature_intraday;
const validIntradayStatus = ['AVAIL_TRADING', 'AVAIL_NO_TRADING'];
const inScopeAssets = new Set(['43', '44', '63', '95', '68', '49', '65', '60', '51', '84','98']);

@State<IntradayHoldingsStateModel>({
  name: 'intraday',
  defaults: defaultState
})
@Injectable()
export class IntradayHoldingsState {
  constructor(
    private logger: LoggerService,
    private intradayService: IntradayPricingService,
    private store: Store) {}

  @Action(IntradayHoldingsActions.FetchIntradayStatusAndThenPricing)
  onFetchIntradayStatusAndThenPricing(
    ctx: StateContext<IntradayHoldingsStateModel>,
    action: IntradayHoldingsActions.FetchIntradayStatusAndThenPricing
  ) {

    const nextSelector = action.params.fetchFor === 'selectedAccount'
      ? IntradayHoldingsState.uniqueSymbolsForAccountHoldings()
      : IntradayHoldingsState.uniqueSymbolsForAllHoldings()

    const intradayTimeElapsed = new Date(ctx.getState().lastPriceCheck).getMinutes() + ctx.getState().updateRateMinutes <= new Date().getMinutes();
    return this.store.selectOnce(nextSelector).pipe(
      tap((symbols) => {
         //Set in-scope assets state at user level so trigger only once during default state
         if(ctx.getState().status === '' && ctx.getState().apiError === false) {
          ctx.patchState({ inScopeAssets: symbols.length > 0 });
        }
      }),
      switchMap((symbols) => {
        // Re-Triggers intraday if previously failed or timed out
        if (ctx.getState().status === '' || intradayTimeElapsed || ctx.getState().apiError) {
          // Triggers both API calls on initial load of app
          return ctx.dispatch(new IntradayHoldingsActions.FetchIntradayStatus()).pipe(
            switchMap(() => {
              return ctx.dispatch(new IntradayHoldingsActions.FetchIntradayHoldings(symbols))
            })
          )
        } else if (action.params.state === 'refresh' || !(validIntradayStatus.includes(ctx.getState().status)) || (ctx.getState().intradayDictionary && Object.keys(ctx.getState().intradayDictionary)?.length === 0)) {
          // Triggers pricing API during Refresh or when there's no pricing data
          return ctx.dispatch(new IntradayHoldingsActions.FetchIntradayHoldings(symbols))
        }
        return of(true);
      })
    );
  }

  @Action(IntradayHoldingsActions.FetchIntradayStatus)
  onFetchIntradayStatus(
    ctx: StateContext<IntradayHoldingsStateModel>,
    action: IntradayHoldingsActions.FetchIntradayStatus
  ) {
    return this.intradayService.fetchIntradayStatus().pipe(
      switchMap((intradayStatusResponse) => {
        if (intradayStatusResponse === null || intradayStatusResponse?.error) {
          this.logger.warn('[IntradayHoldingsState] onFetch: failed to load intraday status', intradayStatusResponse);
          return ctx.dispatch(new IntradayHoldingsActions.SetIntradayStatusError());
        }
        return ctx.dispatch(new IntradayHoldingsActions.SetIntradayStatus(intradayStatusResponse));
      })
    );
  }

  @Action(IntradayHoldingsActions.SetIntradayStatus)
  onSetSetIntradayStatus(
    ctx: StateContext<IntradayHoldingsStateModel>,
    action: IntradayHoldingsActions.SetIntradayStatus
  ) {
    ctx.patchState({
      status: action.data?.status,
      latestUpdateTimestamp: action.data?.latestUpdateTime,
      apiError: false,
      apiCompleted: true,
      lastPriceCheck: action.data.lastPriceCheck,
      updateRateMinutes: action.data.updateRateMinutes
    });
    return Promise.resolve();
  }

  @Action(IntradayHoldingsActions.FetchIntradayHoldings)
  onFetch(ctx: StateContext<IntradayHoldingsStateModel>, action: IntradayHoldingsActions.FetchIntradayHoldings) {
    const currentStatus = ctx.getState().status;
    if (currentStatus === 'AVAIL_TRADING' || currentStatus === 'AVAIL_NO_TRADING') {
      return this.intradayService.fetchIntradayPricing(action.symbols).pipe(
        switchMap((intradayHoldingsResponse) => {
          if (intradayHoldingsResponse === null || intradayHoldingsResponse?.error) {
            this.logger.warn(
              '[IntradayHoldingsState] onFetch: failed to load intraday holdings',
              intradayHoldingsResponse
            );
            return ctx.dispatch(new IntradayHoldingsActions.SetIntradayHoldingsError());
          }
          return ctx.dispatch(new IntradayHoldingsActions.SetIntradayHoldings(intradayHoldingsResponse));
        })
      );
    } else if (currentStatus === 'UNAVAIL_EOD') {
      return ctx.dispatch(new IntradayHoldingsActions.SetIntradayHoldings({ pricingData: null }));
    } else if (!currentStatus || currentStatus === 'UNAVAIL_WEEKEND_HOLIDAY') {
      return ctx.dispatch(new IntradayHoldingsActions.SetIntradayHoldingsError());
    }
  }

  @Action(IntradayHoldingsActions.SetIntradayHoldings)
  onSetIntradayHoldingsData(
    ctx: StateContext<IntradayHoldingsStateModel>,
    action: IntradayHoldingsActions.SetIntradayHoldings
  ) {
    ctx.dispatch(new Notifications.Remove([CODES.PUSH_WARNING_EMERGENCY_INTRADAY], [false]));
    ctx.patchState({
      intradayDictionary: { ...ctx.getState().intradayDictionary, ...action.data?.pricingData },
      latestUpdateTimestamp: action.data?.latestUpdateTimestamp || ctx.getState().latestUpdateTimestamp,
      apiError: false,
      apiCompleted: true
    });
    return Promise.resolve();
  }

  @Action(IntradayHoldingsActions.ClearIntradayHoldings)
  onClearIntradayHoldings(ctx: StateContext<IntradayHoldingsStateModel>) {
    ctx.patchState({
      intradayDictionary: null,
      latestUpdateTimestamp: null,
      apiError: false,
      apiCompleted: false
    });
    return Promise.resolve();
  }

  @Action([AppActions.ResetState])
  onResetState(ctx: StateContext<IntradayHoldingsStateModel>, action: AppActions.ResetState) {
    ctx.setState(defaultState);
    return Promise.resolve();
  }

  @Action([IntradayHoldingsActions.SetIntradayHoldingsError, IntradayHoldingsActions.SetIntradayStatusError])
  onIntradayHoldingsError(ctx: StateContext<IntradayHoldingsStateModel>) {
    if(validIntradayStatus.includes(ctx.getState().status)) {
      ctx.dispatch(new Notifications.Add([CODES.PUSH_WARNING_EMERGENCY_INTRADAY]));
    }
    ctx.patchState({ apiError: true, apiCompleted: true });
    return Promise.resolve();
  }

  //Selectors
  @Selector([IntradayHoldingsState])
  static IntradayPricingDictionary(state: IntradayHoldingsStateModel): IntradayPricing {
    return state?.intradayDictionary;
  }

  @Selector([IntradayHoldingsState])
  static IntradayStatus(state: IntradayHoldingsStateModel): IntradayStatus {
    return {
      ...state,
      error: state?.apiError,
    };
  }

  static uniqueSymbolsForAccountHoldings() {
    return createSelector(
      [HoldingsState.AccountHoldingsByCurrentAccountState],
      (accountHoldings: IntradayHoldings): IntradayPricingSymbols[] => {
        let requestParams = accountHoldings.holdings
          .filter((holding) => inScopeAssets.has(holding?.seiAssetClassCd))
          .map((holding) => holdingToIntradaySymbol(holding));
        let uniqueParams = requestParams.reduce((acc, obj) => {
          if (!acc.some((o) => o?.type === obj?.type && o?.value === obj?.value)) {
            if (obj !== null) {
              acc.push(obj);
            }
          }
          return acc;
        }, []);
        return uniqueParams;
      }
    );
  }

  static uniqueSymbolsForAllHoldings() {
    return createSelector([HoldingsState], (accountHoldings: HoldingsStateModel): IntradayPricingSymbols[] => {
      let requestParams = accountHoldings.allHoldings.holdings
        .filter((holding) => inScopeAssets.has(holding?.seiAssetClassCd))
        .map((holding) => holdingToIntradaySymbol(holding));
      let uniqueParams = requestParams.reduce((acc, obj) => {
        if (!acc.some((o) => o?.type === obj?.type && o?.value === obj?.value)) {
          if (obj !== null) {
            acc.push(obj);
          }
        }
        return acc;
      }, []);
      return uniqueParams;
    });
  }

  /**
   * UPDATES THE PRICING CALCULATIONS WHILE SWITCHING THE ACCOUNTS
   */
  @Selector([HoldingsState, AccountsState.currentClientAccountState, IntradayHoldingsState])
  static AccountHoldingsByCurrentAccountState(
    state: HoldingsStateModel,
    currentAcctState: CurrentClientAccountState,
    intradayState: IntradayHoldingsStateModel
  ): IntradayHoldings {
    if (!intradayState?.apiError) {
      if (currentAcctState?.account) {
        return {
          ...calculateHoldingsTotalsIntraday(
            state.allHoldings.holdings.filter((v) => {
              return currentAcctState.account.acctId === v.acctId;
            }),
            intradayState
          )
        };
      }
      if (currentAcctState?.group) {
        let acctIds: string[] = currentAcctState.group.acctList.map((act) => act.acctId);
        return {
          ...calculateHoldingsTotalsIntraday(
            state?.allHoldings?.holdings.filter((v) => {
              return acctIds.includes(v.acctId);
            }),
            intradayState
          )
        };
      }
    } else {
      return new IntradayHoldings();
    }
  }

  // Get pricing for all holdings for dashboard
  @Selector([HoldingsState, IntradayHoldingsState, UserPreferencesState])
  static AllAccountsIntradayHoldings(
    state: HoldingsStateModel,
    intradayState: IntradayHoldingsStateModel,
    userPrefState: UserPreferencesModel
  ): IntradayHoldings {
    if (!intradayState?.apiError) {
      const filteredHoldings = state.allHoldings.holdings.filter(
        (val)  => {
          return !userPrefState.acctIdsToOmitFromSnapshot.includes(val.acctId);
        }
      );
      return {
        ...calculateHoldingsTotalsIntraday(
          filteredHoldings,
          intradayState
        )
      }
    } else {
      return new IntradayHoldings();
    }
  }

  @Selector([ProfileState.memberType, IntradayHoldingsState.IntradayStatus])
  static showIntradayView(memberType: Number) {
    return (memberType === 1) && intradayFeatureFlag
  }

  @Selector([IntradayHoldingsState.IntradayStatus])
  static isValidIntradayStatus(intradayState: IntradayStatus) {
    return (intradayState?.status !== '') &&
      validIntradayStatus.includes(intradayState?.status) && 
      intradayState.inScopeAssets
  }

  @Selector([IntradayHoldingsState.IntradayStatus, IntradayHoldingsState.isValidIntradayStatus])
  static isIntradaySuccess(intradayState: IntradayHoldingsStateModel, isValidIntradayStatus: boolean) {
    return !intradayState.error && isValidIntradayStatus && intradayState.intradayDictionary;
  }

  @Selector([IntradayHoldingsState.showIntradayView, IntradayHoldingsState.isIntradaySuccess])
  static isIntradayActive(showIntradayView: boolean, isIntradaySuccess: boolean) : boolean {
    return showIntradayView && !!isIntradaySuccess;
  }

  @Selector([HoldingsState, IntradayHoldingsState])
  static allHoldings(state: HoldingsStateModel, intradayState: IntradayHoldingsStateModel) {
    if (!intradayState.apiError) {
      return { holdings: state?.allHoldings?.holdings, time: intradayState.latestUpdateTimestamp };
    } else {
      return { holdings: [], time: intradayState.latestUpdateTimestamp };
    }
  }

  static getIntradayDetailsForAccounts(acctIds: string[]) {
    return createSelector([HoldingsState, IntradayHoldingsState], 
      (accountHoldings: HoldingsStateModel,
        intradayHoldingsState: IntradayHoldingsStateModel): IntradayHoldings => {
      let holdings = accountHoldings.allHoldings.holdings
      const combinedHoldings = combineDuplicateHoldings(
        holdings?.filter((v) => {
          return acctIds?.includes(v.acctId);
        })
      ) as Holdings[];
        return calculateHoldingsTotalsIntraday(
          combinedHoldings,
          intradayHoldingsState
        )  
    });
}
}

/**
 * CALCULATES THE INTRADAY PRICING FOR ASSETS/HOLDINGS
 */
export function calculateHoldingsTotalsIntraday(
  holdings: Holdings[],
  intradayState: IntradayHoldingsStateModel
): IntradayHoldings {
  const totals: HoldingsTotals = holdings.reduce((acc, holding, i) => {
    //capture asset class summaries, if we recognize the asset class
    if (acc.asset[holding.schemaLevel1]) {
      acc.asset[holding.schemaLevel1].totalValue += holding.marketValue;
      if (holding.portfolioNum === 1) {
        acc.asset[holding.schemaLevel1].principalTotalValue += holding.marketValue;
      } else {
        acc.asset[holding.schemaLevel1].incomeTotalValue += holding.marketValue;
      }
    }

    //sum holdings level totals
    acc.holdings.cost += holding.taxCost;
    acc.holdings.unrealizedGainLoss += holding.unrealizedGainLoss;
    acc.holdings.estAnnualIncome += holding.estAnnualIncome;

    //sum portfolio level totals
    acc.portfolio.totalMarketValue += holding.marketValue;
    if (holding.portfolioNum === 1) {
      acc.portfolio.principalMarketValue += holding.marketValue;
    } else {
      acc.portfolio.incomeMarketValue += holding.marketValue;
    }
    return acc;
  }, new HoldingsTotals());
  const allocationHoldings = holdings.map((holding) => {
    const allocation = holding.marketValue / totals.portfolio.totalMarketValue;

    // Updating to intraday prices if available
    const intradayHolding = { ...holding, todaysChangePercentage: 0, todaysChangeValue: 0 } as Holdings;
    const todaysPrice = getIntradayPriceIfAvailable(holding, intradayState?.intradayDictionary);
    const previousDayPrice = holding.currentPrice;
    if (todaysPrice !== null) {
      if(todaysPrice !== previousDayPrice) {
      const prevDayPrice = holding.currentPrice;
      const todaysMarketValue = todaysPrice * holding.qty;

      intradayHolding.currentPrice = todaysPrice;
      intradayHolding.marketValue = todaysMarketValue;
      intradayHolding.todaysChangePercentage = (todaysPrice - prevDayPrice) / prevDayPrice;
      intradayHolding.todaysChangeValue = todaysMarketValue - holding.marketValue;
      } 
      else {
        intradayHolding.displayDeltaValue = true;
      }
      
    }
    return Object.assign({}, intradayHolding, { allocation: allocation }) as Holdings;
  });

  //calculate summary values after intraday prices are mapped
  totals.holdings.todaysChangeInTotalMarketValue = allocationHoldings.reduce(
    (acc, holding) => acc + holding.todaysChangeValue,
    0
  );
  totals.portfolio.totalMarketValue += totals.holdings.todaysChangeInTotalMarketValue;

  //calculate allocation % per assetClass
  let positiveMarketValueSum = 0;
  Object.keys(totals.asset).forEach((assetClass) => {
    if (totals.asset[assetClass].totalValue > 0) {
      positiveMarketValueSum += totals.asset[assetClass].totalValue;
    }
  });
  Object.keys(totals.asset).forEach((assetClass) => {
    if (totals.asset[assetClass].totalValue <= 0) {
      totals.asset[assetClass].allocation = 0;
    } else {
      totals.asset[assetClass].allocation = totals.asset[assetClass].totalValue / positiveMarketValueSum;
    }
  });

  // Calculate Estimated Yield % (this is not a sumation of holding.estYield)
  if (totals.holdings.estAnnualIncome !== 0 && totals.portfolio.totalMarketValue !== 0) {
    totals.estimatedYield = GlobalFormatterService.percent(
      totals.holdings.estAnnualIncome / totals.portfolio.totalMarketValue
    );
  } else {
    totals.estimatedYield = '-';
  }

  return new IntradayHoldings(
    totals,
    allocationHoldings,
    intradayState?.status,
    intradayState?.latestUpdateTimestamp
  );
}

export function getIntradayPriceIfAvailable(holding: Holdings, intradayHoldingsDictionary: IntradayPricing) {
  const intradaySymbol = holdingToIntradaySymbol(holding)?.value;
  return intradayHoldingsDictionary?.[intradaySymbol] &&
    intradayHoldingsDictionary[intradaySymbol]?.currentPrice !== null
    ? intradayHoldingsDictionary[intradaySymbol].currentPrice
    : null;
}

export function holdingToIntradaySymbol(holding: Holdings) {
  if (holding?.ticker) {
    return { type: 'ticker', value: holding.ticker };
    // ensure isin_Id contains characters other than space
  } else if (holding?.isin_Id && /\S/.test(holding?.isin_Id)) {
    return { type: 'isin', value: holding.isin_Id };
  } else {
    return null;
  }
}
