import { Component, OnInit } from '@angular/core';
import { UserAgentState, UserAgentStateModel } from '@app/_state/userAgent.state';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { NotificationConfig } from '../_state/notifications.config';
import { NotificationState } from '../_state/notifications.state';
import { NotificationMessageComponent } from '../notification-message/notification-message.component';
import { NgIf, NgFor, AsyncPipe } from '@angular/common';

@Component({
  selector: 'app-notification-container',
  templateUrl: './notification-container.component.html',
  styleUrls: ['./notification-container.component.scss'],
  standalone: true,
  imports: [NgIf, NgFor, NotificationMessageComponent, AsyncPipe]
})
export class NotificationContainerComponent implements OnInit {
  @Select(NotificationState.activeNotificationConfigs) notifications$: Observable<NotificationConfig[]>;
  @Select(UserAgentState) userAgent$: Observable<UserAgentStateModel>;

  constructor(
    private store: Store
  ) {}

  ngOnInit(): void {}

  onMessageAction(action: new (...args: any[]) => any) {
    this.store.dispatch(new action());
  }
}
